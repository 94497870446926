import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export function OnConfirmComponent(props: {
  children: React.ReactNode;
  className: string;
  disabled?: boolean;
  onConfirm: () => void;
}) {
  const { children } = props;

  const [action, setAction] = useState(false);

  return (
    <>
      {!action ? (
        <div>
          <button
            disabled={props.disabled}
            onClick={() => setAction(true)}
            className={props.className}
          >
            {children}
          </button>
        </div>
      ) : (
        <div className="d-flex  gap-2">
          <button
            disabled={props.disabled}
            onClick={() => {
              props.onConfirm();
              setAction(false);
            }}
            className={"btn btn-sm btn-link text-success border border-success"}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button
            disabled={props.disabled}
            onClick={() => setAction(false)}
            className={"btn btn-sm btn-link text-danger border border-danger"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </>
  );
}
