import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { toMapAccum } from "../../utils";
import { EventCalendar } from "../CalendlyComponent/CalendlyComponent";
import SelectComponent from "../Shared/FieldComponents/SelectComponent";
import { TabData, TabsComponent } from "../Shared/TabsComponent/TabsComponent";
import { Appointment } from "../../types/Appointment";
import useTranslate from "../../hooks/useTranslate";


function AppointFilters({ date, setDate, dateMap }: 
{
    date: string,
    setDate: Dispatch<SetStateAction<string>>,
    dateMap: {[key: string]: EventCalendar<Appointment>[];}
}) {
    const { t } = useTranslate("calendar");
    const dateOptions = useMemo(() => {
        return Object.entries(dateMap).sort(([key], [key2]) => {
            return parseDate(key2).getTime() - parseDate(key).getTime()
        }).map(([key, value]) => ({
            value: key,
            label: key,
            data: {
                date: key,
                count: value.length
            }
        }));
    }, [dateMap]);

    return (
        <div className='appoint-upcoming-filters'>
            <div className='filter'>
                <p className='title'>{t('dateEvents')}</p>
                <div style={{ width: 180 }}>
                    <SelectComponent
                      id="dateEvents"
                      name={"select_" + "date"}
                      options={dateOptions}
                      sortOptions={false}
                      value={dateOptions.find((item) => item.value === date) || null}
                      placeholder={t('selectDate')}
                      onChange={(evt) => setDate(evt.value)}
                      formatOptionLabel={(option) => (
                        <div style={{ position: 'relative', width: 100, padding: '10px 0'}}>
                          <p className="m-0">{parseDate(option.label).toFormattedDate('yyyy-MM-dd')}</p>
                          <div className="m-0 circle-option-select">
                            {option.data.count}
                          </div>
                        </div>
                      )}
                    />
                </div>
            </div>
            <div className='filter'>
                <button 
                    onClick={() => {
                        setDate('');
                    }} 
                    className='clear'
                >
                    {t('clearFilter')}
                </button>
            </div>
        </div>
    )
}

function AppointListEvents({date, events, tabName }: {date: string, events: EventCalendar<Appointment>[], tabName: AppointListTabs }) {
    const { t } = useTranslate("calendar");
    const indicatorColor = useMemo(() => {
        switch(true) {
            case tabName === AppointListTabs.Today:
                return 'bg-success';
            case tabName === AppointListTabs.Upcoming:
                return 'bg-primary';
            default:
                return 'past';
        }
    }, [tabName]);

    return (
        <>
            <div className='appoint-upcoming-date'>
                <h3>{date ? parseDate(date).toFormattedDate("EEEE, MMMM dd, yyyy") : t("selectDate")}</h3>
            </div>
            <div className='appoint-upcoming-list'>
                {
                    events.map((event, index) => (
                        <div key={index} className='list-item'>
                            <div className="info">
                                {/* Time indicator */}
                                <div className="time-indicator">
                                    <div className={`indicator ${indicatorColor}`} />
                                    <div className="time">
                                        {event.startDate.toFormattedDate("hh:mm a")} - {event.endDate.toFormattedDate("hh:mm a")}
                                    </div>
                                </div>

                                {/* Event details */}
                                <div className="event-details">
                                    {/* <div className="person-name">
                                        <span className='text'>Jack Jackson</span>
                                        <span className='text2'> with you</span>
                                    </div> */}
                                    <div className="event-name">
                                        {event.title}
                                    </div>
                                </div>
                                
                                {
                                    event.description && (
                                        <div className="event-info">
                                            {event.description}
                                        </div>
                                    )
                                }

                                {
                                    event.data?.invitees && (
                                        <div className="event-info">
                                            {event.data.invitees}
                                        </div>
                                    )
                                }
                                

                                {/* <div className="host-info">
                                    1 hosts | 0 non-host
                                </div> */}
                            </div>
                            
                            {/* Right side info */}
                            <div className='details'>
                                <button className="btn-detail">
                                    <FontAwesomeIcon icon={faPlay} />
                                    Details
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

function AppointListData({ events, tabName }: { events: EventCalendar<Appointment>[], tabName: AppointListTabs }) {

    const [date, setDate] = useState<string>('');
    const dateMap = useMemo(() => toMapAccum(events, 'data.start_date'), [events]);
    const { t } = useTranslate("calendar");

    const eventsByDate:  [string, EventCalendar<Appointment>[]][] = useMemo(() => {
        if(date) {
            return Object.entries(dateMap).filter(([key]) => key === date);
        }
        return Object.entries(dateMap).sort(([key], [key2]) => {
            return parseDate(key2).getTime() - parseDate(key).getTime()
        });
    }, [date]);

    return (
        <div>
            <AppointFilters date={date} dateMap={dateMap} setDate={setDate} />
            {
                eventsByDate.map(([date1, events], index) => (
                    <AppointListEvents key={index} date={date1} tabName={tabName} events={events} />
                ))
            }
            <div className='appoint-upcoming-end-message'>
                <p>{t('reachEndList')}</p>
            </div>
        </div>
    )
}

enum AppointListTabs {
    Upcoming = 'Upcoming',
    Past = 'Past',
    Today = 'Today'
}

export function AppointmentListComponent({ events }: { events: EventCalendar<Appointment>[] }) {

    const dateMap = useMemo(() => toMapAccum(events, 'data.start_date'), [events]);
    const { t } = useTranslate("calendar");

    const eventsGroup = useMemo(() => {
        const todayEvents: EventCalendar<Appointment>[] = [];
        const upComingEvents: EventCalendar<Appointment>[] = [];
        const pastEvents: EventCalendar<Appointment>[] = [];
        Object.entries(dateMap).forEach(([date, value]) => {
            const dateParse = parseDate(date);
            switch (true) {
                case dateParse.equals(new Date()):
                    todayEvents.push(...value);
                    break;
                case dateParse.lessThan(new Date()):
                    pastEvents.push(...value);
                    break;
                default:
                    upComingEvents.push(...value);
                    break;
            }
        });

        return { todayEvents, upComingEvents, pastEvents };
    }, [dateMap]);
    

    const tabs = useMemo<TabData<string>[]>(() => {
        return [
            {
                title: t(AppointListTabs.Today),
                component: <AppointListData key={AppointListTabs.Today} tabName={AppointListTabs.Today} events={eventsGroup.todayEvents} />,
            },
            {
                title: t(AppointListTabs.Upcoming),
                component: <AppointListData key={AppointListTabs.Upcoming} tabName={AppointListTabs.Upcoming} events={eventsGroup.upComingEvents} />,
            },
            {
                title: t(AppointListTabs.Past),
                component: <AppointListData key={AppointListTabs.Past} tabName={AppointListTabs.Past} events={eventsGroup.pastEvents} />,
            },
        ];
    }, []);
    
  return (
    <div className='appoint-list-container card'>
        <TabsComponent tabs={tabs} />
    </div>
  )
}

function parseDate(dateString: string): Date {
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // Meses en `Date` van de 0-11
    const day = parseInt(dateString.substring(6, 8), 10);
  
    return new Date(year, month, day);
}