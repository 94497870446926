import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionsAuth } from "../store/Auth/Slice";
import { useRouter } from "next/router";
import { AuthStates } from "../store/Auth/Types";
import { authReducer } from "../store/Selectors";
import { START_PAGE_ON, START_PAGE_PASSWORD_EXPIRATION } from "../pages";
import session from "../types/Session";

const allowedRoutes = [
  "forgot-password",
  "change-password",
  "email-verification",
  "update-password",
  "/cms/",
  "external-auth",
];

const ignoreMe = ["task-view", "autologin", "/preview/", "external-auth"];

const AuthValidation = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { state, user, path } = useSelector(authReducer);

  const dispatch = useDispatch();
  const history = useRouter();

  const { locale } = useRouter();

  useEffect(() => {
    localStorage.setItem("crm_locale", locale ?? "en");
  }, [locale]);

  useEffect(() => {
    const ignore = !ignoreMe.some((e) => window.location.pathname.includes(e));

    switch (true) {
      // if the user is authenticated with login as, validate session and get user data
      case session.isLoginAs:
        dispatch(actionsAuth.login_as({ username: session.loginAs! }));
        break;
      // if the user is authenticated, validate session and get user data
      case session.exist && ignore:
        dispatch(actionsAuth.me());
        break;

      // if the user is not authenticated and the route is not allowed, it will be redirected to the login page
      case ignore &&
        !allowedRoutes.some((route) => history.pathname.includes(route)) &&
        !window.location.href.includes("?"):
        const isNotAuthHistory =
          history.pathname && !history.pathname.includes("auth");

        history.push(
          `/auth/login${
            isNotAuthHistory ? "?" + window.location.pathname : ""
          }`,
        );

        break;
    }
  }, [dispatch]);

  useEffect(() => {
    const ignore = !ignoreMe.some((e) => window.location.pathname.includes(e));
    if (ignore) {
      console.log("ignore me no aceptado");
      switch (state) {
        case AuthStates.MFA_VERIFICATION:
          history.push(`/auth/mfa/email-verification`);
          break;

        case AuthStates.USER_PASSWORD_EXPIRED:
          history.push(START_PAGE_PASSWORD_EXPIRATION);
          break;

        case AuthStates.AUTHENTICATED:
          if (
            allowedRoutes
              .concat("/login")
              .some((path) => history.pathname.includes(path))
          ) {
            if (path) {
              history.push(path);
              dispatch(actionsAuth.changePath(undefined));
            } else {
              history.push(
                user.config?.sbx_crm.startPage
                  ? user.config?.sbx_crm.startPage
                  : START_PAGE_ON,
              );
            }
          }
          break;
      }
    }
  }, [state]);

  return <>{children}</>;
};

export default AuthValidation;
