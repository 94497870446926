import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { Offcanvas, OffcanvasBody } from "reactstrap";
import useTranslate from "../../hooks/useTranslate";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ColumnsMetadata,
  Report,
  ReportFolder,
  ReportTypeChart,
  TruncateReport,
} from "../../types/Analytic";
import styled from "styled-components";
import {
  IsJsonString,
  removeDuplicateFromArrayObj,
  toast,
  uuidV4,
} from "../../utils";
import RadioButtonComponent from "../Shared/FieldComponents/RadioButtonComponent";
import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import useMemoAsync from "../../hooks/useMemoAsync";
import { SbxResponse } from "../../types/Sbx";
import {
  deleteSbxModelService,
  findByModel,
  insertSbxModelService,
  updateModelRow,
} from "../../services/backend/SbxService";
import { State } from "../../types/State";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../Shared/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cogoToast from "cogo-toast";
import { ReportContext } from "./NewReportGeneratorComponent";
import { useRouter } from "next/router";
import {
  getAnalyticQueryFlat,
  removeTemporalIdFromQuery,
} from "../../utils/analyticsUtils";
import NewTruncateReportComponent from "./NewTruncateReportComponent";
import { StringOption } from "../../types/Select";
import {
  getFoldersByReport,
  getReportFolders,
} from "../../services/backend/ReportServices";

type Props = {
  toggleCanvas: () => void;
};

export type ReportType = {
  name: string;
  query: string;
  context?: string | null;
  custom: boolean;
  parent_group?: string;
  order_group: number;
  type: ReportTypeChart | string;
  ncolumn: number;
  show_all_columns: boolean;
  dashboard_view: boolean;
  metadata: string;
  sort?: string | null;
  remove_empty_columns: boolean;
  tags?: string[] | string | null;
  columns_metadata: string | ColumnsMetadata;
  auto_execute?: boolean;
  folder?: string[];
  skip_execution_on_open?: boolean;
  truncate?: TruncateReport | string | null;
  newValuesByField?: { [key: string]: any[] };
};

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

type Input = {
  id: string;
  label?: string;
  name: string;
  type: string;
  multi?: boolean;
  placeholder?: string;
  noOptionMessage?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
  options?: { label: string; value: string }[];
};

const cacheReports: Report[] = [];
const cacheFolders: ReportFolder[] = [];

const defaultTruncate = {
  model: "",
  field: "",
  format: "",
  type: "date",
  range: 0,
};

const checkedNullProps: (keyof ReportType)[] = ["tags"];

const SaveReportComponent = ({ toggleCanvas }: Props) => {
  const { t } = useTranslate("common");
  const [loading, setLoading] = useState(State.IDLE);
  const { query, report, state } = useContext(ReportContext);

  const { register, control, setValue, handleSubmit, watch } =
    useForm<ReportType>({
      defaultValues: {
        name: report?.name ?? "",
        query: "",
        context: report?.context ? report.context : "",
        custom: report ? report.custom : false,
        parent_group: report?.parent_group ?? "",
        metadata: (report?.metadata as string) ?? "",
        order_group: report?.order_group ?? 0,
        type: report?.type ?? "",
        dashboard_view: report ? report.dashboard_view : false,
        ncolumn: report?.ncolumn ?? 0,
        tags: report?.tags ? report.tags.split(",") : null,
        show_all_columns: report ? report.show_all_columns : false,
        remove_empty_columns: report ? report.remove_empty_columns : false,
        auto_execute: report ? report.auto_execute : undefined,
        skip_execution_on_open: report
          ? report.skip_execution_on_open
          : undefined,
        columns_metadata: report?.columns_metadata
          ? IsJsonString(report.columns_metadata as string)
            ? JSON.parse(report.columns_metadata as string)
            : {}
          : {},
        truncate: report?.truncate
          ? IsJsonString(report?.truncate as string)
            ? JSON.parse(report?.truncate as string)
            : defaultTruncate
          : defaultTruncate,
      },
    });
  const reports: Report[] = useMemoAsync(async () => {
    if (cacheReports.length === 0) {
      setLoading(State.PENDING);
      const resFind: SbxResponse<Report> = await findByModel({
        row_model: "sbx_crm_report",
      });
      if (resFind?.success && resFind.results) {
        setLoading(State.RESOLVED);
        cacheReports.push(...resFind.results);
        return resFind.results;
      }
      setLoading(State.RESOLVED);
    } else {
      return cacheReports;
    }

    return [];
  }, []);
  const folders: ReportFolder[] = useMemoAsync(async () => {
    setLoading(State.PENDING);
    const response = await getReportFolders();
    if (response?.success && response.results) {
      setLoading(State.RESOLVED);
      cacheFolders.push(...response.results);
      return response.results;
    }
    setLoading(State.RESOLVED);

    return [];
  }, []);

  const allColumns = useMemoAsync(async () => {
    if (state?.columns && state.columns.length > 0) {
      return state.columns[state.columns.length - 1].map((column) => ({
        label: column,
        value: column,
        data: null,
      }));
    }

    return [];
  }, [query]);

  const history = useRouter();
  const inputs = useMemo(
    () => [
      {
        id: uuidV4(),
        label: t("name"),
        name: "name",
        type: "text",
        required: true,
      },
      {
        id: uuidV4(),
        name: "order_group",
        type: "number",
        label: t("report:order_group"),
      },
      {
        id: uuidV4(),
        name: "ncolumn",
        type: "number",
        label: t("report:ncolumn"),
      },
      {
        id: uuidV4(),
        name: "parent_group",
        type: "select",
        label: t("report:parent_report"),
        options:
          reports?.map((report: Report) => ({
            label: report.name,
            value: report._KEY,
          })) ?? [],
      },
      {
        id: uuidV4(),
        name: "folder",
        type: "select",
        label: t("folder"),
        multi: true,
        options:
          folders?.map((folder) => ({
            label: folder.name,
            value: folder._KEY,
          })) ?? [],
      },
      {
        id: uuidV4(),
        name: "tags",
        type: "select",
        label: "Tags",
        multi: true,
        placeholder: t("report:add_tags"),
        noOptionMessage: t("report:type_to_add_new_tags"),
        options: reports
          ?.filter((report) => report.tags)
          .map((report) => report.tags)
          .join()
          .split(",")
          .map((tag) => ({
            label: tag,
            value: tag,
          })),
      },
      {
        id: uuidV4(),
        name: "show_all_columns",
        type: "radio",
        label: t("report:show_all_columns"),
      },
      {
        id: uuidV4(),
        name: "custom",
        type: "radio",
        label: t("report:custom"),
      },
      {
        id: uuidV4(),
        name: "dashboard_view",
        type: "radio",
        label: t("report:dashboard_view"),
      },
      {
        id: uuidV4(),
        name: "remove_empty_columns",
        type: "radio",
        label: t("report:remove_empty_columns"),
      },
      {
        id: uuidV4(),
        name: "auto_execute",
        type: "radio",
        label: t("report:auto_execute"),
      },
      {
        id: uuidV4(),
        name: "skip_execution_on_open",
        type: "radio",
        label: t("report:skip_execution_on_open"),
      },
      {
        id: uuidV4(),
        name: "columns_metadata.head_sub_head.index",
        type: "select",
        placeholder: t("report:head_sub_head_index"),
        label: t("report:head_sub_head_index"),
        options: allColumns,
      },
      {
        id: uuidV4(),
        name: "columns_metadata.head_sub_head.head_by",
        type: "select",
        placeholder: t("report:head_sub_head_head_by"),
        label: t("report:head_sub_head_head_by"),
        options: allColumns,
      },
      {
        id: uuidV4(),
        label: t("description"),
        name: "context",
        type: "textarea",
      },
    ],
    [reports, folders, allColumns],
  ) as Input[];

  const foldersByReport = useMemoAsync(async () => {
    if (report?._KEY) {
      setLoading(State.PENDING);
      const response = await getFoldersByReport(report._KEY);
      if (response?.results && response?.results.length > 0) {
        setLoading(State.RESOLVED);
        return response.results
          .filter((folder) => folder?.folder?._KEY)
          .map((folder) => ({
            name: folder.folder.name,
            data: { folderKey: folder.folder._KEY },
            value: folder._KEY,
          }));
      } else {
        setLoading(State.RESOLVED);
      }
    }
    return [];
  }, [report?._KEY]);

  React.useEffect(() => {
    if (foldersByReport && foldersByReport.length > 0) {
      setValue(
        "folder",
        foldersByReport.map((it: StringOption) => it.data.folderKey),
      );
    }
  }, [foldersByReport]);

  const getSelectValue = ({
    value,
    options,
    isMulti,
  }: {
    value: string | number | string[];
    options: StringOption[];
    isMulti?: boolean;
  }) => {
    if (isMulti) {
      return value
        ? (value as string[])?.map((val: string) => ({
            label: options.find((op) => op.value === val)?.label ?? val,
            value: val?.trim(),
          })) ?? null
        : null;
    }
    if (options.find((option) => option.value === value)) {
      const option = options.find((option) => option.value === value);
      return { label: option?.label, value: option?.value };
    }

    if (value) {
      return { label: value, value };
    }

    return null;
  };

  const getInput = (input: Input, loading: State) => {
    switch (input.type) {
      case "text":
        return (
          <input
            type={"text"}
            placeholder={input.label}
            disabled={input.disabled}
            className={"form-control"}
            {...register(input.name as keyof ReportType, {
              required: input.required,
            })}
          />
        );
      case "textarea":
        return (
          <textarea
            placeholder={input.label}
            disabled={input.disabled}
            className={"form-control "}
            {...register(input.name as keyof ReportType, {
              required: input.required,
            })}
          />
        );
      case "number":
        return (
          <input
            type={"number"}
            disabled={input.disabled}
            className={"form-control"}
            {...register(input.name as keyof ReportType, {
              required: input.required,
            })}
          />
        );
      case "radio":
        return (
          <Controller
            render={({ field }) => {
              return (
                <RadioButtonComponent
                  containerClassName="d-flex align-items-center"
                  disabled={State.PENDING === loading || input.disabled}
                  className="d-flex align-items-center me-2"
                  onChange={(event) => {
                    field.onChange(event?.value);
                  }}
                  id={`${input.name}.value`}
                  name={""}
                  value={{
                    label: field.value ? t("yes") : "no",
                    value: field.value,
                  }}
                  options={[
                    { label: t("yes"), value: true },
                    { label: "No", value: false },
                  ]}
                />
              );
            }}
            name={input.name as keyof ReportType}
            rules={{ required: input.required }}
            control={control}
          />
        );
      case "select":
        return (
          <Controller
            render={({ field }) => {
              return (
                <CreatableSelectComponent
                  noOptionsMessage={input.noOptionMessage ?? undefined}
                  placeholder={input.placeholder ?? undefined}
                  isMulti={input.multi}
                  onCreateOption={(value) => {
                    field.onChange(
                      input.multi
                        ? [...((field.value ?? []) as keyof ReportType), value]
                        : value,
                    );
                    setValue("newValuesByField", {
                      ...watch("newValuesByField"),
                      [input.name]: [
                        ...(watch("newValuesByField")?.[input.name] ?? []),
                        value,
                      ],
                    });
                  }}
                  value={getSelectValue({
                    value: (field.value as string) ?? "",
                    options: input.options ?? [],
                    isMulti: input.multi,
                  })}
                  disabled={State.PENDING === loading || input.disabled}
                  loading={State.PENDING === loading}
                  name={input.name}
                  options={
                    input.options
                      ? removeDuplicateFromArrayObj(input.options, "value")
                      : []
                  }
                  onChange={(evt) => {
                    field.onChange(
                      input.multi
                        ? evt.map((item: StringOption) => item.value)
                        : evt?.value,
                    );
                    if (input.onChange) {
                      input.onChange(evt);
                    }
                  }}
                />
              );
            }}
            name={input.name as keyof ReportType}
            rules={{ required: input.required }}
            control={control}
          />
        );
    }

    return <div></div>;
  };

  const onSubmit: SubmitHandler<ReportType> = async (data) => {
    const nQuery = { ...query };

    if (nQuery.hasOwnProperty("preview")) {
      delete nQuery.preview;
    }

    const queryTruncate = { ...(nQuery.truncate as TruncateReport) };

    delete nQuery.truncate;

    delete nQuery.truncate;

    let newQuery = {
      ...data,
      active: true,
      query: JSON.stringify(
        removeTemporalIdFromQuery(getAnalyticQueryFlat({ ...nQuery })),
      ),
    };

    const newValuesByField = newQuery.newValuesByField;
    delete newQuery.newValuesByField;
    let folder = newQuery.folder as string[];
    delete newQuery.folder;

    checkedNullProps.forEach((prop) => {
      if (!newQuery[prop]) {
        delete newQuery[prop];
      }
    });

    if (!newQuery.context) {
      delete newQuery.context;
    }

    if (queryTruncate && (queryTruncate?.model || queryTruncate?.field)) {
      newQuery.truncate = JSON.stringify(queryTruncate);
    } else {
      newQuery.truncate = null;
    }

    if (
      query.actions.flat().some((action) => action.type === "date_analysis") &&
      !newQuery.truncate
    ) {
      toast({
        type: "error",
        message: t("custom-message:range-limit-mandatory-for-date-analysis"),
      });
      return;
    }

    const dateAnalysisByMerge = query.actions
      .flat()
      .find(
        (action) =>
          action.type === "merge" &&
          action.actions &&
          action.actions
            .flat()
            .find((action) => action.type === "date_analysis"),
      );

    if (
      dateAnalysisByMerge?.source?.with &&
      (!dateAnalysisByMerge.truncate || dateAnalysisByMerge?.truncate === "{}")
    ) {
      toast({
        type: "error",
        message: `${t(
          "custom-message:range-limit-mandatory-for-date-analysis-on-merge-with",
        )} "${dateAnalysisByMerge.source.with}" Model`,
      });
      return;
    }

    if (
      Array.isArray(newQuery.tags) &&
      newQuery.tags &&
      newQuery.tags.length > 0
    ) {
      newQuery.tags = newQuery.tags?.filter((tag) => tag).join(",");
    }

    if (report) {
      if (!!newQuery.auto_execute === !!report.auto_execute) {
        delete newQuery.auto_execute;
      }

      if (
        !!newQuery.skip_execution_on_open === !!report.skip_execution_on_open
      ) {
        delete newQuery.skip_execution_on_open;
      }
    } else {
      if (!newQuery.auto_execute) {
        delete newQuery.auto_execute;
      }

      if (!newQuery.skip_execution_on_open) {
        delete newQuery.skip_execution_on_open;
      }
    }

    if (newQuery.columns_metadata) {
      const columnsMetadata = newQuery.columns_metadata as ColumnsMetadata;

      if (columnsMetadata?.head_sub_head.index) {
        columnsMetadata.head_sub_head.index = Array.isArray(
          columnsMetadata.head_sub_head.index,
        )
          ? columnsMetadata.head_sub_head.index
          : [columnsMetadata.head_sub_head.index as any as string];
      }

      newQuery = {
        ...newQuery,
        columns_metadata: JSON.stringify(columnsMetadata),
      };
    }

    setLoading(State.PENDING);
    let res: SbxResponse;
    let key = "";

    if (report && report._KEY) {
      const prevQuery = IsJsonString(report.query)
        ? JSON.parse(report.query)
        : null;
      if (prevQuery?.source.with !== query.source.with) {
        newQuery.sort = null;
      }

      res = await updateModelRow(
        [{ ...newQuery, _KEY: report._KEY }],
        "sbx_crm_report",
      );
      if (res.success && report._KEY) {
        key = report._KEY;
      } else {
        setLoading(State.REJECTED);
      }
    } else {
      if (report?.type) {
        newQuery.type = report.type;
      }
      if (report?.metadata) {
        newQuery.metadata = report.metadata as string;
      }

      setLoading(State.REJECTED);

      res = await insertSbxModelService({
        row_model: "sbx_crm_report",
        rows: [newQuery],
      });
      if (res.success && res.keys) {
        key = res.keys[0];
      } else {
        setLoading(State.REJECTED);
      }
    }

    let deleteRows: StringOption[] = [];

    if (folder && folder.length > 0) {
      if (newValuesByField && newValuesByField["folder"]) {
        const newFolders = newValuesByField["folder"];

        if (newFolders && newFolders.length > 0) {
          folder = folder.filter(
            (folderKey) =>
              !newFolders.some((folder: string) => folder === folderKey),
          );

          const response = await insertSbxModelService({
            row_model: "sbx_crm_analytic_folder",
            rows: newFolders.map((folder) => ({ name: folder })),
          });

          if (response?.success) {
            const newFoldersKeys = response.keys;
            if (newFoldersKeys && newFoldersKeys.length > 0) {
              folder = folder.concat(newFoldersKeys);
              console.log("folder", folder);
            }
          }
        }
      }

      let rows = (folder as string[]).map((folderKey) => ({
        folder: folderKey,
        report: key,
      }));

      if (foldersByReport && foldersByReport.length > 0) {
        deleteRows = foldersByReport.filter(
          (folderIt: StringOption) =>
            !folder.some((row) => row === folderIt.data?.folderKey),
        );
        if (deleteRows.length > 0) {
          await deleteSbxModelService({
            row_model: "sbx_crm_report_folder",
            keys: deleteRows.map((row) => row.value),
          });
        }
      }

      if (rows.length > 0) {
        if (foldersByReport && foldersByReport.length > 0) {
          rows = rows.filter(
            (row) =>
              !foldersByReport.some(
                (folder: StringOption) => folder.data?.folderKey === row.folder,
              ),
          );
        }

        if (rows.length > 0) {
          await insertSbxModelService({
            row_model: "sbx_crm_report_folder",
            rows,
          });
        }
      }
    } else {
      if (foldersByReport && foldersByReport.length > 0) {
        deleteRows = foldersByReport.filter(
          (folderIt: StringOption) =>
            !folder.some((row) => row === folderIt.data?.folderKey),
        );
        if (deleteRows.length > 0) {
          await deleteSbxModelService({
            row_model: "sbx_crm_report_folder",
            keys: deleteRows.map((row) => row.value),
          });
        }
      }
    }

    if (key) {
      await history.push(`/analytics/sbx-reports/${key}?from=report_generator`);
    }
  };

  const onError = (errors: any, e: any) => {
    // console.log("erros on", errors)
    const fields = inputs.filter((field) =>
      Object.keys(errors).includes(field.name),
    );

    if (fields && fields!?.length > 0) {
      cogoToast.error(
        <div>
          <b>{t("custom-message:field_required")}s:</b>
          <ul>
            {fields.map((field) => (
              <li key={field.id}>{field.label}</li>
            ))}
          </ul>
        </div>,
        { position: "bottom-left" },
      );
    } else {
      if (Object.keys(errors?.truncate).length > 0) {
        cogoToast.error(
          <div>
            <b>{t("custom-message:field_required")}s:</b>
            <ul>
              {Object.keys(errors.truncate).map((key) => (
                <li key={key}>{t(`${key}`)}</li>
              ))}
            </ul>
          </div>,
          { position: "bottom-left" },
        );
      } else {
        toast({
          type: "error",
          message: "Debe completar los campos faltantes.",
        });
      }
    }
  };

  return (
    <div>
      <Offcanvas
        style={{
          minWidth: "500px",
        }}
        direction="end"
        toggle={toggleCanvas}
        zIndex={2050}
        isOpen
      >
        <div className="d-flex align-items-center justify-content-between p-3">
          <h4 className="m-0">{t("report:save-report")}</h4>
          <div className="d-flex align-items-center gap-3">
            <ButtonComponent
              label={t("save")}
              icon={faSave}
              disabled={State.PENDING === loading}
              loading={State.PENDING === loading}
              onClick={() => handleSubmit(onSubmit, onError)()}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="pointer"
              size={"2x"}
              onClick={toggleCanvas}
            />
          </div>
        </div>

        <OffcanvasBody>
          <ContainerInputs>
            {inputs.map((input) => {
              return (
                <div
                  key={input.id}
                  className={`d-flex flex-column ${
                    input.type === "textarea" ? "grid-full-column" : ""
                  }`}
                >
                  <span>{input.label ?? input.name}</span>
                  {getInput(input, loading)}
                </div>
              );
            })}
          </ContainerInputs>

          <hr className="w-100" />

          {!(query.truncate as TruncateReport)?.model &&
            !(query.truncate as TruncateReport)?.field && (
              <NewTruncateReportComponent
                control={control}
                setValue={setValue}
                watch={watch}
                getInput={(input) => getInput(input, loading)}
                reports={reports}
              />
            )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default SaveReportComponent;
