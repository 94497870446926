import React, { createContext, FormEvent, useReducer, useState } from "react";
import reducer, { actions, initialState, IStateCalendar } from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {
  deleteAppointmentService,
  getAllAppointmentsService,
  saveAppointmentService,
  updateAppointmentService,
} from "../../services/backend/AppointmentService";
import { State } from "../../types/State";
import { Account, SalesAdresseeAccount } from "../../types/Account";
import { getListModelProcess } from "../../services/backend/ProcessService";
import { actionsPermissions } from "../../store/Permission/Slice";
import async from "async";
import { getSalesAddresseeByAccountService } from "../../services/backend/SalesAdresseService";
import { ProcessModel } from "../../types/models/processModel/Process";
import { Response } from "../../types/Response";
import { Permissions } from "../../types/Permissions";
import Permission from "../AuthorityPermission/Permission";
import { RootState } from "../../store/Reducers";
import CalendlyComponent, {
  EventCalendar,
} from "../CalendlyComponent/CalendlyComponent";
import { AppointmentListComponent } from "../AppointmentList/AppointmentListComponent";
import TabContents from "../Shared/TabContents";
import { Appointment } from "../../types/Appointment";
import { EventDetailsComponent, formatValue } from "./EventDetailsComponent";
import { getProviderById } from "../../services/backend/FormService";
import { findAllByModel } from "../../services/backend/SbxService";
import { getQuery } from "../ListProviderComponent/DetailComponent";
import useTranslate from "../../hooks/useTranslate";

const permission = Permissions.CALENDAR;

export const CalendarContext = createContext<{
  state: IStateCalendar;
  onChangeEvent: (field: string, value: any) => void;
  account?: Account;
}>({
  state: initialState,
  onChangeEvent: () => null,
});

const CalendarComponent = (props: {
  userKey?: string;
  account?: Account;
  email?: string;
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((root: RootState) => root.AuthReducer);
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const { event } = state;
  const { t } = useTranslate('calendar');

  const getAppointments = async () => {
    const res = await getAllAppointmentsService(
      props.userKey || "",
      props.email || "",
      props.account?._KEY,
    );
    if (res.success) {
      dispatchLocal(actions.updateEvents(res.items));
    }
  };

  async function getSalesAddresseeByAccount(accountKey: string) {
    if (accountKey) {
      const sales: Response<SalesAdresseeAccount> =
        await getSalesAddresseeByAccountService(accountKey);
      dispatchLocal(actions.setSales(sales.items ?? []));
    }
  }

  async function getListModelProcessData() {
    const process: Response<ProcessModel> = await getListModelProcess();
    const visible_process =
      user.config?.sbx_crm?.calendar?.visible_process ?? [];
    process.items = process.items?.filter(
      (item: ProcessModel) =>
        visible_process.includes(item.id!) || !visible_process.length,
    );
    dispatchLocal(actions.setProcessModels(process.items ?? []));
  }

  async function loadProviders() {
    const selectors = Object.entries(
      user.config?.sbx_crm?.calendar?.selectors || {},
    );

    let providers: Record<string, Record<"label" | "value", string>[]> = {};
    for await (const [key, value] of selectors) {
      if (value !== "hide") {
        const provider = await getProviderById(value.provider_id);

        switch (provider.item?.provider_type) {
          case "SBX":
            let queryString =
              provider.item?.query ?? provider.item.default_query ?? "{}";
            const query = getQuery(queryString);
            query.where = [];
            const queryResponse = await findAllByModel(query);

            providers[key] = queryResponse.items.map((item: any) => ({
              label: formatValue(value.format_label, item),
              value: formatValue(value.format_value, item),
            }));
            break;
        }
      }
    }
    dispatchLocal(actions.setProviders(providers));
  }

  useAsyncEffect(async () => {
    dispatch(actionsPermissions.getAllUsers());
    async.parallel([
      (cb) => getListModelProcessData().then((res) => cb(null, res)),
      (cb) => getAppointments().then((res) => cb(null, res)),
      (cb) => loadProviders().then((res) => cb(null, res)),
    ]);
  }, [dispatch]);

  useAsyncEffect(async () => {
    if (event?.data?.account_data?.data) {
      await getSalesAddresseeByAccount(event.data?.account_data.data._KEY);
    } else {
      dispatchLocal(actions.setSales([]));
    }
  }, [event?.data?.account_data]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatchLocal(actions.setState(State.PENDING));
    if (event && user.id) {
      let res;

      const sendEmailAppointment =
        user.config?.sbx_crm?.cloudscripts?.appointment_mail ?? "";

      if (event.data?._KEY) {
        res = await updateAppointmentService(event.data!, sendEmailAppointment);
      } else {
        const eventUser = {
          ...event.data!,
          crm_user: user._KEY || user.id.toString(),
        };
        res = await saveAppointmentService(eventUser, sendEmailAppointment);
      }

      if (res.success) {
        await getAppointments();
        dispatchLocal(actions.changeEvent(undefined));
      }
    }
  };

  async function deleteAppointment(e: EventCalendar<Appointment>) {
    dispatchLocal(actions.setState(State.PENDING));
    const res = await deleteAppointmentService(e.data!._KEY);
    if (res.success) {
      await getAppointments();
    }
  }

  function onChangeEvent(name: string, value: any) {
    if (event)
      dispatchLocal(
        actions.changeEvent({
          ...event,
          data: {
            ...event.data!,
            [name]: value,
          },
        }),
      );
  }

  const [date, setDate] = useState<Date>();

  return (
    <Permission permission={permission}>
      <CalendarContext.Provider value={{ onChangeEvent, state }}>
        <TabContents
          tabs={[
            {
              label: t('calendar'),
              component: (
                <div className="d-flex justify-content-center w-100 vh-70">
                  <CalendlyComponent
                    date={date}
                    disabledBeforeToday
                    onChangeDate={setDate}
                    daysOfWeekDisabled={[0, 6]}
                    startTime={"5:00"}
                    endTime={"18:30"}
                    events={state.events}
                    onChangeEvent={(e) => dispatchLocal(actions.changeEvent(e))}
                    currentEvent={event}
                  >
                    {(_props) => (
                      <EventDetailsComponent
                        {..._props}
                        account={props.account}
                        onSubmit={onSubmit}
                        onDelete={deleteAppointment}
                      />
                    )}
                  </CalendlyComponent>
                </div>
              ),
            },
            {
              label: t('allEvents'),
              component: <AppointmentListComponent events={state.events} />,
            },
          ]}
        />
      </CalendarContext.Provider>
    </Permission>
  );
};

export default CalendarComponent;
