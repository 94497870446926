import {Controller, useForm} from "react-hook-form";
import {Field, SelectSubType, StyleRulesDefinition,} from "../../../types/Field";
import {SubType} from "../../../types/FieldType";
import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import RadioButtonComponent from "../../Shared/FieldComponents/RadioButtonComponent";
import CheckboxComponent from "../../Shared/FieldComponents/CheckboxComponent";
import ToDoListComponent from "../../Shared/FieldComponents/ToDoListComponent";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import MultiSelectComponent from "../../Shared/FieldComponents/MultiSelectComponent";
import {NumberOption, StringOption} from "../../../types/Select";
import {State} from "../../../types/State";
import {
    debounceTime,
    getCompoundName,
    getDataFromKey,
    getDefaultVarsFromStr,
    getDynamicListProviderQuery,
    getObjValueInDeep,
    getProviderOptions,
    getSelectOptionByConfig,
    getSortedOptionsByRules,
    getUnderscoreColumn,
    getVariableDefaultValue,
    isDefaultVarExpression,
    isUUID,
    removeDuplicateFromArrayObj,
    safeStringify,
    uuidV4,
} from "../../../utils";
import CurrencyComponent from "../../Shared/FieldComponents/CurrencyComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import {ListProvider, ListProviderOption} from "../../../types/Task";
import {Condition, SbxResponse} from "../../../types/Sbx";
import {Response} from "../../../types/Response";
import {Find} from "sbxcorejs";
import {getSbxModelFields} from "../../../services/backend/SbxService";
import {CrmInputContext} from "./CrmRenderInput";
import {MenuProps, ValueType} from "react-select";
import SwitchComponent from "../../Shared/FieldComponents/SwitchComponent";
import {CSSObject} from "@emotion/serialize";
import useResponsive from "../../../hooks/useReponsive";
import {ProviderType} from "../../../types/ProviderType";

const requestRandom: string[] = [];

type Props = {
    field: Field;
    externalDefaultValue?: boolean;
    isInlineTable?: boolean;
    contextForm: any;
    selectOptions?: any[]
    contextInput?: any;
};


const CrmOptionsInputComponent = ({
                                      field,
                                      contextForm,
                                      externalDefaultValue, isInlineTable,
                                      contextInput, selectOptions
                                  }: Props) => {
    // this params are only to preview form
    const {control: controlP, register: registerP} = useForm();
    const searchRef = useRef("")
    const {isTabletOrMobile} = useResponsive();

    const {
        task,
        register,
        control,
        setError,
        setValue,
        clearErrors,
        watch,
        getFormValue, setFormKey,
        form
    }: any = useContext(contextForm);

    const {getDefaultValue}: any = useContext(contextInput ?? CrmInputContext);
    const {t} = useTranslate("common");

    const [isLoading, setIsLoading] = useState(State.IDLE);
    const [options, setOptions] = useState<any[]>([]);
    const [baseOptions, setBaseOptions] = useState<any[]>([]);
    const [selectValue, setSelectValue] = useState<{
        label: string;
        value: string | number;
    } | null>(null);
    const [defaultValue, setDefaultValue] = useState<string>("");
    const initial = useRef(false);
    const initial2 = useRef(false);


    function formatValues(field: Field, value: any, structure?: boolean) {

        if (structure) {
            if (field.sub_type === SubType.TO_DO_LIST) {
                return value ? JSON.stringify(value) : null;
            }
            if (field.single_value) {
                return value?.value ?? null;
            } else {
                return value?.map((opt: StringOption) => opt.value).join(",") ?? null;
            }
        } else {
            if (field.sub_type === SubType.TO_DO_LIST) {
                return value ? JSON.parse(value) : null;
            }
            if (field.single_value) {
                return (
                    removeDuplicateFromArrayObj(getSelectOptionByConfig(field, [...options, ...baseOptions]), 'value').find(
                        (opt) => opt.value === value,
                    ) ?? null
                );
            } else {


                if (!value) {
                    return null
                }

                return (
                    removeDuplicateFromArrayObj(getSelectOptionByConfig(field, [...options, ...baseOptions]), 'value').filter((opt) =>
                        (value ?? "").split(",").some((val: string) => val === opt.value),
                    ) ?? null
                );
            }
        }
    }

    const getSelectLabelValue = (
        obj: { [key: string]: string } | ListProviderOption,
    ) => {

        if (
            obj &&
            field.format_rules_definition?.columns_labels &&
            field.format_rules_definition?.columns_labels[0]
        ) {

            setSelectValue({
                label: getCompoundName({
                    columns: field.format_rules_definition.columns_labels,
                    item: obj,
                }),
                value: (obj as { [key: string]: string })._KEY ?? obj.value ?? "",
            });
        } else {
            if (obj?.label && obj.value) {

                setSelectValue({
                    label: obj.label,
                    value: obj.value,
                });
            }
        }
    };


    const getDefaultSelectValue = async (queryProvider?: string) => {
        if (!field.format_rules_definition?.dependencies) {
            // setValue(field.name, defaultValue);
            // const domain_id = field?.list_provider?.domain_id;
            if (field.read_only && field.list_provider?.default_query) {
                // When the field is read only and the item has property default_query, use the default query like the main query
                field.list_provider = {
                    ...field.list_provider,
                    query: field.list_provider?.default_query,
                };
            }

            // Check if list provider has vars
            if (
                field.format_rules_definition?.sub_type !== SelectSubType.SEARCHEABLE
            ) {
                if (field?.list_provider?.query && !queryProvider && !field.read_only) {
                    if (options.length === 0) {
                        const varList = getDefaultVarsFromStr(field?.list_provider?.query);
                        if (varList && varList.length > 0) {
                            return;
                        }
                    } else {
                        if (defaultValue) {
                            if (
                                field?.format_rules_definition?.dependencies &&
                                !field.read_only
                            ) {
                            } else {
                                const item = options.find(
                                    (option) =>
                                        option._KEY === defaultValue ||
                                        option.value === defaultValue,
                                );

                                if (item) {
                                    getSelectLabelValue(item);
                                }
                            }
                        }
                    }
                } else {
                    if (defaultValue && options.length > 0) {
                        const item = options.find(
                            (option) =>
                                option._KEY === defaultValue || option.value === defaultValue,
                        );

                        if (item) {
                            getSelectLabelValue(item);
                        }
                    }
                }
            }

            if (task) {
                let response: SbxResponse | Response | any | null = null;

                if (queryProvider && field.list_provider) {

                    response = await getProviderOptions({
                        list_provider: {
                            ...field.list_provider,
                            query: queryProvider,
                        },
                        getFormValue,
                        task
                    });
                } else {
                    let findUrl = "find_all"
                    const sbx_query =
                        field?.list_provider?.query &&
                        JSON.parse(field?.list_provider?.query);

                    if (sbx_query && (sbx_query["ROW_MODEL"] || sbx_query["row_model"])) {
                        const model_name = sbx_query["ROW_MODEL"] ?? sbx_query["row_model"];

                        let query = new Find(model_name, 0);
                        let defaultValueQuery =
                            getDefaultValue({...field}) ??
                            getDefaultValue(
                                {...field}.format_rules_definition as any as Field,
                            ) ??
                            {...field}.format_rules_definition?.default ??
                            "" ??
                            ""


                        if (!field.single_value && typeof defaultValueQuery === "string" && defaultValueQuery.includes(",")) {
                            defaultValueQuery = defaultValueQuery.split(",")
                            query.whereWithKeys(defaultValueQuery)
                            findUrl = "find"
                        } else {
                            query.andWhereIsEqualTo(
                                "_KEY", defaultValueQuery,
                            );
                        }

                        query = query.compile();

                        const fetch = sbx_query.FETCH ?? sbx_query.fetch ?? [];

                        const jsonQuery = JSON.stringify({
                            WHERE: (query as any).where,
                            FETCH: fetch,
                        });

                        response = await getSbxModelFields({
                            findUrl,
                            provider: {
                                name: model_name,
                                query: jsonQuery,
                            },
                        });

                        if (
                            response?.results?.length === 0 &&
                            sbx_query.row_model &&
                            JSON.stringify(sbx_query.where) !==
                            JSON.stringify((query as any).where)
                        ) {
                            sbx_query.where = sbx_query.where.map((where: Condition) => {
                                where.GROUP = where.GROUP.map((group) => {
                                    if (isDefaultVarExpression(group.VAL as string)) {
                                        let defaultValue = getDefaultValue(
                                            field.format_rules_definition as any as Field,
                                        );


                                        if (!defaultValue) {
                                            defaultValue = getDefaultValue({
                                                default_value: group.VAL,
                                            } as Field);
                                        }
                                        if (defaultValue) {
                                            group.VAL = defaultValue;
                                        }
                                    }

                                    return group;
                                });

                                return where;
                            });

                            const jsonQuery = JSON.stringify({
                                WHERE: (sbx_query as any).where,
                                FETCH: fetch,
                            });

                            response = await getSbxModelFields({
                                findUrl: "find_all",
                                provider: {
                                    name: model_name,
                                    query: jsonQuery,
                                },
                            });
                        }
                    }
                }

                if (
                    response &&
                    response.success &&
                    response.results &&
                    response.results[0]
                ) {
                    if ((!field.read_only || selectValue)) {
                        if (
                            field.format_rules_definition?.sub_type ===
                            SelectSubType.SEARCHEABLE && !initial2.current
                        ) {

                            if (!field.single_value) {
                                const nValue = getDefaultValue(field)
                                if (nValue && typeof nValue === "string" && response?.results && response.results.length > 0) {
                                    if (options.length === 0) {
                                        setOptions(sortItemsByConfig(response.results));
                                        setBaseOptions(response.results);
                                    }

                                }
                            } else {
                                const nValue = getDefaultValue(field)

                                if (nValue || selectValue) {
                                    getSelectLabelValue(response.results[0]);
                                    dispatchObjExtraColumns(response.results[0], false, true);
                                }

                            }


                            initial2.current = true;
                        }
                    } else {
                        getDefaultValuesByOrderCondition({
                            items: response.results,
                            formData: getTaskData(),
                        });
                        getSelectLabelValue(response.results[0]);
                    }
                } else {
                    if (selectValue) {
                        if (!externalDefaultValue) {

                            if ((field.list_provider as ListProvider)?.provider_type === ProviderType.DATABASE) {
                            } else {
                                setSelectValue(null);
                            }
                        }
                    }
                }
            }
        }
    };

    const getDisabledReferenceSelect = () => {
        if (field.format_rules_definition?.sub_type === SelectSubType.SEARCHEABLE) {
            return field.read_only;
        } else {
            return isLoading === State.PENDING || field.read_only;
        }
    };

    const getSearchableOptions = async ({search}: { search: string }) => {
        if (field.list_provider) {
            setIsLoading(State.PENDING);

            let formState = getFormValue ? getFormValue() : {};

            formState = {...formState, ...getTaskData()};

            let providerQuery: null | string = field.list_provider.query ?? "";
            let providerDefaultQuery: null | string =
                field.list_provider.default_query ?? "";


            const uuid = uuidV4();
            requestRandom.push(uuid);

            const response = await debounceTime(
                getProviderOptions,
                {
                    header: {
                        search_by: field.format_rules_definition?.search_by ?? [],
                        search_by_type: field.format_rules_definition?.search_by_type ?? {},
                        searchable_limit:
                            field.format_rules_definition.searchable_limit ?? false,
                    },
                    search,
                    list_provider: {
                        ...field.list_provider,
                        default_query: providerDefaultQuery,
                        query: providerQuery,
                    },
                    formState,
                },
                700,
            );


            if (requestRandom[requestRandom.length - 1] === uuid) {
                if (
                    response?.success &&
                    response?.items &&
                    response?.items.length > 0
                ) {

                    setOptions(sortItemsByConfig(response.items));
                    const item = response.items[0]
                    if (item["_KEY"]) {
                        setBaseOptions(prevState => removeDuplicateFromArrayObj([...prevState, ...response.items], '_KEY'));
                    } else if (item['value']) {
                        setBaseOptions(prevState => removeDuplicateFromArrayObj([...prevState, ...response.items], 'value'));
                    } else {
                        setBaseOptions(prevState => [...prevState, ...response.items]);
                    }

                    setIsLoading(State.RESOLVED);
                } else {
                    setOptions([]);
                    setIsLoading(State.REJECTED);
                }
            }
        }
    };
    // {
    //     valueContainer(base: CSSObject, props: ValueContainerProps<any, any, any>): CSSObject {
    //     return {
    //         ...base,
    //         backgroundColor: 'red',
    //         color: 'white 1important',
    //         margin: '5px'
    //     }
    // },
    //     option(base: CSSObject, props: OptionProps<any, any, any>): CSSObject {
    //     return {
    //         ...base,
    //         backgroundColor: 'red',
    //         color: 'white',
    //         ':hover': {
    //             backgroundColor: 'gray',
    //         },
    //     }
    // }
    // }

    let optionStyle = (value: string): { [key: string]: CSSProperties } => {

        let background = ""
        let textColor = ""

        const uuidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}_/;
        // Reemplaza el UUID si está presente, de lo contrario, devuelve el string original
        const fieldName = field.name.replace(uuidRegex, '');


        const colorByValue = field.format_rules_definition?.color_by_value ? field.format_rules_definition.color_by_value[fieldName] : {}


        if (colorByValue?.background && Object.keys(colorByValue.background).length > 0 && value && colorByValue.background[value]) {
            background = colorByValue.background[value]
        }

        if (colorByValue?.text_color && Object.keys(colorByValue.text_color).length > 0 && value && colorByValue.text_color[value]) {
            textColor = colorByValue.text_color[value]
        }

        if (background || textColor) {
            return {
                option: {
                    backgroundColor: background ?? 'white',
                    color: textColor ?? 'white',
                    ':hover': {
                        backgroundColor: 'gray',
                    },
                } as CSSProperties,
                value: {
                    backgroundColor: background ?? 'white',
                    color: textColor ?? 'white',
                }
            }
        }

        return {}


    }

    const resetFormValues = (option?: StringOption) => {
        if (field.format_rules_definition?.sub_type === SelectSubType.SEARCHEABLE) {
            if (option?.value) {
                setFormKey && setFormKey();
            }
        } else {
            if (field?.format_rules_definition?.dependencies) {
                setFormKey && setFormKey();
            }

        }
    }

    const getOptionInput = (field: Field) => {


        let mainInput = (
            <Controller
                control={control ?? controlP}
                name={field.name}
                // defaultValue={getValue(field, task)}
                rules={{
                    required:
                        (field.required && !field.read_only) ||
                        !!field?.format_rules_definition?.n_to_be_completed,
                }}
                render={({field: {onChange, value}}) => {
                    let Field: any;
                    switch (field.sub_type) {
                        case SubType.TOGGLE:
                            if (field.single_value) {
                                Field = RadioButtonComponent;
                            } else {
                                if (field?.format_rules_definition?.render_type === "switch") {
                                    Field = SwitchComponent;
                                } else {
                                    Field = CheckboxComponent;
                                }
                            }
                            break;
                        case SubType.TO_DO_LIST:
                            Field = ToDoListComponent;
                            break;
                        default:
                            if (field.single_value) {
                                Field = SelectComponent;
                            } else {
                                Field = MultiSelectComponent;
                            }
                            break;
                    }
                    return (
                        <Field

                            id={field.name + "_" + field.id}
                            name={field.name}
                            value={
                                field.single_value ? selectValue : formatValues(field, value)
                            }
                            placeholder={
                                field.format_rules_definition?.placeholder_input ??
                                field.format_rules_definition?.placeholder ?? t("select_placeholder")
                            }
                            onChange={(evt: StringOption | StringOption[]) => {
                                if (field.sub_type === SubType.TO_DO_LIST) {
                                    const number =
                                        field?.format_rules_definition?.n_to_be_completed;

                                    const count =
                                        number === "all" || !number ? options.length : number;
                                    if ((evt as any[])?.length < count) {
                                        console.log("Generating errors... ");
                                        setError(field.name, {
                                            types: {
                                                label: field.label,
                                                required: `Please select ${count} items to continue.`,
                                            },
                                        });
                                    } else {
                                        console.log("Removing errors... ");
                                        clearErrors([field.name]);
                                    }
                                    onChange(formatValues(field, evt, true));
                                } else if (Array.isArray(evt)) {
                                    onChange(formatValues(field, evt, true));
                                } else {


                                    setSelectValue(evt);
                                    handleChangeSelect(evt);
                                    onChange(evt?.value ?? null);


                                    if (field?.format_rules_definition?.clear_form_values && field?.format_rules_definition?.clear_form_values.length > 0) {
                                        field?.format_rules_definition?.clear_form_values.forEach((fieldToClear: string) => {
                                            if (setValue) {
                                                setValue(fieldToClear, null)

                                                if (form?.fields && Array.isArray(form.fields)) {
                                                    const field = form.fields.find((f: Field) => f.name === fieldToClear) as Field
                                                    if (field?.format_rules_definition?.columns_labels) {
                                                        const columns = field.format_rules_definition.columns_labels[0].value;

                                                        if (columns) {
                                                            columns.forEach((column: string) => {
                                                                setValue(getUnderscoreColumn(field.name, column), null)
                                                            });

                                                        }
                                                    }
                                                }
                                            }
                                        })

                                        setTimeout(() => {
                                            resetFormValues(evt)
                                        }, 10)
                                    } else {
                                        resetFormValues(evt)
                                    }


                                }
                            }}
                            filterOption={(
                                option: StringOption | NumberOption,
                                rawInput: string,
                            ) => {

                                function validateRuleProvider() {
                                    if (field.format_rules_definition?.supplier_filtering_rules) {
                                        let rule =
                                            field.format_rules_definition.supplier_filtering_rules;

                                        const obj = {
                                            ...(task?.process_data ?? {}),
                                            ...(getFormValue?.() ?? {}),
                                        };

                                        rule.split("${").forEach((word) => {
                                            const key = word.split("}")[0];
                                            if (obj[key] && !key.includes("opt.")) {
                                                const value = getObjValueInDeep(obj, key);
                                                rule = rule.replace("${" + key + "}", `"${value}"`);
                                            } else if (key.includes("opt.")) {
                                                const keyOption = key.replace("opt.", "");
                                                const data = option.data?.data ?? option.data ?? {};
                                                const value = getDataFromKey(data, keyOption);
                                                rule = rule.replace(
                                                    "${opt." + keyOption + "}",
                                                    `"${value}"`,
                                                );
                                            }
                                        });

                                        try {
                                            return eval(rule);
                                        } catch (e) {
                                            return false;
                                        }
                                    }
                                    return true;
                                }

                                if (
                                    rawInput &&
                                    field.format_rules_definition?.sub_type ===
                                    SelectSubType.SEARCHEABLE &&
                                    field.format_rules_definition?.search_by
                                ) {

                                    return (
                                        validateRuleProvider() &&
                                        rawInput
                                            .split(" ")
                                            .some((word) =>
                                                safeStringify(option)
                                                    .toLowerCase()
                                                    .includes(word.toLowerCase()),
                                            )
                                    );
                                }

                                if (rawInput) {
                                    return (
                                        option.label
                                            .toLowerCase()
                                            .includes(rawInput.toLowerCase()) &&
                                        validateRuleProvider()
                                    );
                                }


                                return validateRuleProvider();
                            }}
                            styles={{
                                option: (base: CSSObject, props: any) => {

                                    return {
                                        ...base,
                                        ...(optionStyle(props.value)['option'] ?? {})
                                    }
                                },
                                valueContainer: (base: CSSObject, props: any) => {
                                    return {
                                        ...base,
                                        ...(optionStyle(props?.getValue()[0]?.value ?? "")['value'] ?? {})
                                    }
                                },
                                menu(
                                    base: CSSObject,
                                    props: MenuProps<any, any, any>,
                                ): CSSObject {
                                    return {
                                        ...base,
                                        zIndex: 999,
                                    };
                                },
                            }}
                            noOptionsMessage={
                                searchRef.current ?
                                    field.format_rules_definition?.placeholder_not_found ??
                                    field.format_rules_definition?.sub_type ===
                                    SelectSubType.SEARCHEABLE
                                        ? `${t("search_not_found")}`
                                        : "" : field.format_rules_definition?.placeholder
                            }
                            sortOptions={
                                (!field.format_rules_definition?.condition_order_by || field.format_rules_definition?.condition_order_by.length === 0) &&
                                !(
                                    field.format_rules_definition?.sub_type ===
                                    SelectSubType.SEARCHEABLE &&
                                    field.format_rules_definition?.search_by &&
                                    field.format_rules_definition?.search_by_type
                                )
                            }
                            menuPosition={isTabletOrMobile ? "absolute" : "fixed"}
                            // inputValue={ inputSearch ?? ""}
                            onInputChange={(search: string) => {
                                if (
                                    field.format_rules_definition?.sub_type ===
                                    SelectSubType.SEARCHEABLE &&
                                    field.format_rules_definition?.search_by
                                ) {

                                    if (search.length > 0) {
                                        getSearchableOptions({search});
                                    }

                                }
                                searchRef.current = search
                                // setInputSearch(search)
                            }}
                            isClearable
                            format_rules_definition={field.format_rules_definition}
                            containerClassName={
                                (field.style_rules_definition as StyleRulesDefinition)
                                    ?.alignment === "h"
                                    ? `d-flex align-items-center gap-3 ${field.sub_type === SubType.TOGGLE && !field.single_value ? "flex-wrap" : ""}`
                                    : ""
                            }
                            loading={isLoading === State.PENDING}
                            disabled={getDisabledReferenceSelect()}
                            options={getSelectOptionByConfig(field, options)}
                        />
                    );
                }}
            />
        );

        if (field.format_rules_definition?.render_type) {
            switch (field.format_rules_definition?.render_type) {
                case "field":
                    const subType =
                        field.format_rules_definition?.sub_type?.toUpperCase();
                    switch (subType) {
                        case SubType.CURRENCY:
                            return (
                                <div className="input-group mb-3">
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        defaultValue={defaultValue}
                                        rules={{
                                            required: field.required && !field.read_only
                                        }}
                                        render={({field: {onChange, name, value}}) => (
                                            <CurrencyComponent
                                                id={field.name + "_" + field.id}
                                                value={value}
                                                disabled={field.read_only}
                                                defaultValue={
                                                    defaultValue ?? getDefaultValue(field) ?? ""
                                                }
                                                onChange={onChange}
                                                placeholder={field.placeholder ?? ""}
                                                name={name}
                                            />
                                        )}
                                    />
                                </div>
                            );
                        default:
                            return (
                                <div className="input-group mb-3">
                                    <input
                                        className="form-control"
                                        id={field.name + "_" + field.id}
                                        defaultValue={defaultValue}
                                        disabled={field.read_only}
                                        type={field.format_rules_definition?.sub_type}
                                        {...(register ?? registerP)(field.name, {
                                            required: field.required && !field.read_only,
                                        })}
                                    />
                                    {isLoading === State.PENDING && (
                                        <span className="input-group-text bg-white">
                      <FontAwesomeIcon icon={faSpinner} pulse/>
                    </span>
                                    )}
                                </div>
                            );
                    }

                default:
                    return mainInput;
            }
        }

        return mainInput;

        // return null;
    };

    const dispatchObjExtraColumns = (
        obj: {
            [key: string]: string;
        },
        isDependency = false,
        setDependencyValues = false,
    ) => {
        // if field has format rules definition is to add aditional properties for the form with field name like the base.
        // EX: Columns: ["_KEY", "company.company_name"]
        /* Result:sbx_sbx_crm_sales_order_requested_by: ...
        object_sbx_sbx_crm_sales_order_requested_by__KEY: ...
        object_sbx_sbx_crm_sales_order_requested_by_company.company_name ...
         */
        if (
            field.format_rules_definition.columns_labels &&
            field.format_rules_definition.columns_labels.length > 0
        ) {
            const columns = field.format_rules_definition.columns_labels[0].value;

            if (columns) {
                columns.forEach((column: string) => {
                    if (isDependency && setValue) {
                        if (!!obj && !!column) {
                            const value = getObjValueInDeep(obj, column);
                            const name = getUnderscoreColumn(field.name, column);
                            if (name && value) {
                                setValue(name, value);
                            }
                        }
                    } else {
                        if (setValue) {
                            setValue(
                                getUnderscoreColumn(field.name, column),
                                obj[column] ?? getObjValueInDeep(obj, column) ?? "",
                            );
                        }
                    }
                });

                // This validation is when a user launch a process and set by default some values,
                // check if the field has a dependency and set the value to fire the fields that depends on this field
                if (setDependencyValues && getFormValue(field.name) === obj._KEY) {
                    setValue(field.name, getFormValue(field.name));
                }
            }
        }
    };

    const dispatchClearObjExtraColumns = (isDependency = false) => {
        // if field has format rules definition is to add aditional properties for the form with field name like the base.
        // EX: Columns: ["_KEY", "company.company_name"]
        /* Result:sbx_sbx_crm_sales_order_requested_by: ...
        object_sbx_sbx_crm_sales_order_requested_by__KEY: ...
        object_sbx_sbx_crm_sales_order_requested_by_company.company_name ...
         */
        if (
            field.format_rules_definition.columns_labels &&
            field.format_rules_definition.columns_labels.length > 0
        ) {
            const columns = field.format_rules_definition.columns_labels[0].value;

            if (columns) {
                columns.forEach((column: string) => {
                    if (isDependency && setValue) {
                        if (!!column) {
                            const name = getUnderscoreColumn(field.name, column);
                            setValue(name, undefined);
                        }
                    } else {
                        if (setValue) {
                            setValue(getUnderscoreColumn(field.name, column), undefined);
                        }
                    }
                });
            }
        }
    };

    const handleChangeSelect = (
        evt: ValueType<StringOption, boolean>,
        optionsArr?: any[],
    ) => {
        if (field.format_rules_definition) {
            const list = optionsArr ?? options;

            const obj = list.find(
                (option) =>
                    option["_KEY"] === (evt as StringOption)?.value ||
                    option["value"] === (evt as StringOption)?.value,
            );

            if (obj) {
                dispatchObjExtraColumns(obj);
            }
        }
    };

    useEffect(() => {
        if (
            field?.format_rules_definition?.columns_labels &&
            options.length &&
            !initial.current
        ) {
            const val = field?.format_rules_definition?.columns_labels[0];
            if (val?.value?.length && task?.process_data) {
                const obj = task?.process_data[field.name]?.value ? options.find(
                    (option) =>
                        option["_KEY"] === task?.process_data[field.name]?.value ||
                        option["value"] === task?.process_data[field.name]?.value,
                ) : null;
                if (obj && defaultValue) {
                    initial.current = true;
                    dispatchObjExtraColumns(obj);
                }
            }
        }
    }, [options, defaultValue, task]);

    React.useEffect(() => {
        if (selectValue?.value && setValue && getFormValue) {
            setValue("field_label", {
                ...getFormValue("field_label"),
                [field.name]: selectValue?.label,
            });
        }
    }, [selectValue]);

    const getDefaultValuesByOrderCondition = ({
                                                  items,
                                                  formData,
                                              }: {
        items: any[];
        formData: { [key: string]: any };
    }) => {
        if (field.format_rules_definition?.default_value) {
            if (field.format_rules_definition.default_value_conditions) {
                const newConditions: string[] = [];
                const conditions =
                    field.format_rules_definition.default_value_conditions;

                const sbxItem = items.find((resItem) => {
                    for (let condition of conditions) {
                        const defaultValueVars = getDefaultVarsFromStr(condition);
                        if (defaultValueVars && defaultValueVars.length > 0) {
                            for (const defaultVar of defaultValueVars) {
                                const defaultValue = getVariableDefaultValue(defaultVar);
                                if (getFormValue && getFormValue(defaultValue)) {
                                    const value = getFormValue(defaultValue);
                                    condition = condition?.replace(defaultVar, `"${value}"`);
                                    continue;
                                }

                                if (formData[defaultValue]) {
                                    condition = condition?.replace(
                                        defaultVar,
                                        `"${formData[defaultValue]}"`,
                                    );
                                    continue;
                                }

                                if (
                                    getObjValueInDeep(resItem, defaultValue).length > 0 ||
                                    resItem[defaultValue]
                                ) {
                                    condition = condition?.replace(
                                        defaultVar,
                                        `"${
                                            getObjValueInDeep(resItem, defaultValue) ??
                                            resItem[defaultValue]
                                        }"`,
                                    );
                                }
                            }
                        }

                        if (!isDefaultVarExpression(condition)) {
                            newConditions.push(condition);
                        }
                    }

                    return newConditions.some((condition) => {
                        return eval(condition) ?? false;
                    });
                });

                if (field?.format_rules_definition?.default_value) {
                    let value = null;
                    const defaultValue1 = getVariableDefaultValue(
                        field?.format_rules_definition?.default_value,
                    );

                    if (sbxItem) {
                        //Check if nobody condition was true

                        if (sbxItem.hasOwnProperty(defaultValue1)) {
                            value = sbxItem[defaultValue1];
                        } else {
                            value = sbxItem["_KEY"];
                        }
                        dispatchObjExtraColumns(sbxItem);
                    }
                    if (value || value === 0) {
                        setDefaultValue(value);
                        if (setValue) {
                            setValue(field.name, value);
                        }
                    }
                }
            }
        }
    };

    const getDependencyOption = async () => {
        for (const dependency of field.format_rules_definition?.dependencies!) {
            if (field.list_provider) {
                const varList = getDefaultVarsFromStr(field.list_provider.query);

                let formData: { [key: string]: any } = {};

                if (task?.process_data) {
                    formData = {...task.process_data, ...formData};
                }

                if (
                    getFormValue &&
                    getFormValue(dependency) &&
                    field.list_provider &&
                    ((varList && varList.length > 0) ||
                        !field.list_provider.query?.includes("${"))
                ) {
                    const listProvider = getDynamicListProviderQuery({
                        list_provider: {...field.list_provider},
                        getFormValues: getFormValue,
                        formState: formData,
                    });

                    if (listProvider?.query) {
                        setIsLoading(State.PENDING);
                        let response: SbxResponse | null = null;
                        // fetchingData.current = true
                        response = await getProviderOptions({
                            list_provider: listProvider,
                            formState: formData, task, getFormValue
                        }).then((res) => res as SbxResponse);
                        // fetchingData.current = false

                        if (response?.items && setValue) {
                            let items = response.items;

                            if (field.format_rules_definition?.default_value) {
                                const item = items[0];

                                if (item) {
                                    if (field.format_rules_definition.default_value_conditions) {
                                        getDefaultValuesByOrderCondition({items, formData});
                                    } else {
                                        if (field.format_rules_definition?.condition_order_by) {
                                            const order_by =
                                                field.format_rules_definition.condition_order_by[0];
                                            items = items.sort((a: any, b: any) =>
                                                getObjValueInDeep(a, order_by) >
                                                getObjValueInDeep(b, order_by)
                                                    ? -1
                                                    : 1,
                                            );

                                            const defaultValue = getVariableDefaultValue(
                                                field.format_rules_definition.default_value,
                                            );
                                            const item = items[0];

                                            dispatchObjExtraColumns(item, true);

                                            if (item?.hasOwnProperty(defaultValue)) {
                                                setValue(field.name, item[defaultValue]);
                                            } else {
                                                if (
                                                    field.format_rules_definition?.default ||
                                                    field.format_rules_definition?.default === 0
                                                ) {
                                                    setValue(
                                                        field.name,
                                                        field.format_rules_definition?.default,
                                                    );
                                                }
                                            }
                                        } else {
                                            const defaultValue = getVariableDefaultValue(
                                                field.format_rules_definition.default_value,
                                            );


                                            const list =
                                                options.length > 0 ? options : response.results;

                                            const item = list?.find((option) =>
                                                safeStringify(option).includes(formData[defaultValue]),
                                            );

                                            if (item) {
                                                getSelectLabelValue(item);
                                                dispatchObjExtraColumns(item, true);
                                            } else {
                                                const item = list?.find(it => it?._KEY === defaultValue)
                                                if (item) {
                                                    getSelectLabelValue(item);
                                                    dispatchObjExtraColumns(item, true);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (
                                        field.format_rules_definition?.default ||
                                        field.format_rules_definition?.default === 0
                                    ) {
                                        setValue(
                                            field.name,
                                            field.format_rules_definition?.default,
                                        );
                                    } else {
                                        setDefaultValue("");

                                        setSelectValue(null);
                                        dispatchClearObjExtraColumns(true);
                                        setValue(field.name, undefined);
                                    }
                                }
                            } else {
                                if (items.length > 0) {

                                    const option = items.find(
                                        (item) => item._KEY === selectValue?.value,
                                    );
                                    const fieldName = getUnderscoreColumn(field.name, "_KEY");
                                    if (fieldName && formData[fieldName]?.value && !selectValue) {
                                        const option = items.find(
                                            (item) => item._KEY === formData[fieldName]?.value,
                                        );
                                        if (option) {
                                            getSelectLabelValue(option);
                                            dispatchObjExtraColumns(option, true);
                                        }
                                    } else {
                                        // If the option is not found, set the first option as default
                                        if (!option && items.length === 1 && items[0]) {
                                            // If the current field is a dependency of other field, not auto select
                                            if (
                                                form?.fields &&
                                                form.fields.some(
                                                    (nField: Field) =>
                                                        (nField.format_rules_definition &&
                                                            nField.format_rules_definition?.default_value?.includes(
                                                                field.name,
                                                            )) ||
                                                        nField.default_value?.includes(field.name),
                                                )
                                            ) {
                                            } else {
                                                getSelectLabelValue(items[0]);
                                                setValue(field.name, items[0]._KEY);
                                                dispatchObjExtraColumns(items[0], true);
                                            }
                                        }
                                    }
                                } else {
                                    setDefaultValue("");

                                    setSelectValue(null);
                                    dispatchClearObjExtraColumns(true);
                                    setValue(field.name, undefined);
                                }
                            }

                            if (items.length) {
                                setOptions(sortItemsByConfig(items));
                            } else {
                                setOptions([]);
                            }
                            setIsLoading(State.RESOLVED);
                        } else {
                            setDefaultValue("");
                            setSelectValue(null);
                            setValue(field.name, undefined);
                            setIsLoading(State.REJECTED);
                        }
                    } else {
                        setDefaultValue("");
                        setSelectValue(null);
                        setValue(field.name, undefined);
                        setIsLoading(State.REJECTED);
                    }
                }
            }
        }
    };

    useEffect(() => {
        let subscription: any | null = null;

        if (watch) {
            subscription = watch((value: any, {name, type}: any) => {
                if (
                    field.format_rules_definition &&
                    field.format_rules_definition?.dependencies &&
                    field.format_rules_definition?.dependencies.includes(name as string)
                ) {
                    if (field.format_rules_definition?.dependencies && task) {
                        getDependencyOption();
                    }
                }
            });

            if (
                field.format_rules_definition?.dependencies &&
                task &&
                options.length === 0
            ) {
                getDependencyOption();
            }
        }
        return () => subscription?.unsubscribe();
    }, [field, task]);

    const getTaskData = () => {
        if (!!task?.process_data && Object.keys(task.process_data).length > 0) {
            const obj: any = {};
            Object.keys(task.process_data).forEach((key) => {
                if (task.process_data[key]?.value) {
                    obj[key] = task.process_data[key].value;
                } else {
                    if (
                        task.process_data[key] &&
                        typeof task.process_data[key] !== "undefined"
                    ) {
                        obj[key] = task.process_data[key];
                    }
                }
            });

            return obj;
        }

        return {};
    };

    useEffect(() => {

        if (defaultValue && options.length > 0 && !selectValue?.value &&
            field.format_rules_definition?.dependencies && field.format_rules_definition?.sub_type !==
            SelectSubType.SEARCHEABLE) {
            const item = options.find(option => option["_KEY"] && option["_KEY"] === defaultValue);
            if (item) {
                getSelectLabelValue(item);
            }
        }

    }, [selectValue, options, field, defaultValue]);

    const sortItemsByConfig = (items: any[]) => {

        let nItems = [...items]

        if (field.format_rules_definition?.condition_order_by) {
            return getSortedOptionsByRules({
                options: nItems,
                condition_order_by:
                    field.format_rules_definition?.condition_order_by ?? [],
                sort_type: field.format_rules_definition?.sort_type,
            })
        }


        // if (nItems.every(item => containsNumbers(item.value) && !containsCharacter(item.value))){
        //     nItems = nItems.sort((a, b) => field.format_rules_definition?.sort_type === SortType.DESC ? parseFloat(b.value) - parseFloat(a.value) : parseFloat(a.value) - parseFloat(b.value))
        // }

        return nItems;
    }

    React.useEffect(() => {
        const getFieldOptions = async () => {


            if (
                !field.format_rules_definition?.dependencies &&
                (field.format_rules_definition?.sub_type !==
                    SelectSubType.SEARCHEABLE ||
                    !field.format_rules_definition.search_by)
            ) {
                if (field.list_provider) {
                    let list_provider = field.list_provider;
                    setIsLoading(State.PENDING);

                    let formState = getFormValue ? getFormValue() : {};

                    formState = {...formState, ...getTaskData()};

                    if (isUUID(field.name.split("_")[0])) {
                        formState = JSON.parse(
                            JSON.stringify(formState).replaceAll(
                                field.name.split("_")[0] + "_",
                                "",
                            ),
                        );

                    }


                    const response = await getProviderOptions({
                        list_provider,
                        task,
                        formState,
                    });

                    if (response?.success && response?.items) {
                        if (
                            response.items.length === 1 &&
                            field.sub_type === SubType.SELECT
                        ) {
                            if (response.items[0]) {
                                handleChangeSelect(
                                    {
                                        label: "",
                                        value: (response.items[0] as ListProviderOption)._KEY ?? "",
                                    },
                                    response.items,
                                );

                                if (setValue) {
                                    setValue(
                                        field.name,
                                        (response.items[0] as ListProviderOption)._KEY ??
                                        (response.items[0] as ListProviderOption).value ??
                                        "",
                                    );
                                }
                            }

                            getSelectLabelValue(response.items[0] as ListProviderOption);
                        }

                        getDefaultValuesByOrderCondition({
                            items: response.items,
                            formData: formState,
                        });


                        setOptions(sortItemsByConfig(response.items));
                        setIsLoading(State.RESOLVED);
                    } else {
                        setOptions([]);
                        setIsLoading(State.REJECTED);
                    }
                } else {
                    setOptions([{label: t("yes"), value: "true"}]);
                }
            }
        };

        let value = getDefaultValue(field) || "";

        if (!value && externalDefaultValue) {
            value = field.default_value;
        }

        if ((field.list_provider as ListProvider)?.provider_type === ProviderType.DATABASE && !value && field.format_rules_definition?.default) {
            value = field.format_rules_definition.default
        }


        if (value) {
            if (getFormValue && setValue && !getFormValue(value.toString())) {
                setValue(field.name, value);
            }

            setDefaultValue(value);
        }

        if (field?.format_rules_definition?.columns_labels) {
            if ((!field.read_only || !field.single_value) || field.sub_type === SubType.TOGGLE) {

                // This select options was created to prevent multi loading when is used on TableTaskComponent.tsx
                if (!selectOptions) {
                    getFieldOptions();
                } else {
                    setOptions(sortItemsByConfig(selectOptions));
                }
            }

            getDefaultSelectValue();
        } else {
            getFieldOptions();
        }

        return () => {
        };
    }, [field, externalDefaultValue]);

    // useEffect(() => {
    //
    //     // This useEffect is only to use the selector inside a inline table
    //
    //     if (field.list_provider && isInlineTable && selectOptions && selectOptions.length === 0) {
    //
    //         const data = externalOptionsQuery.data
    //         if (data?.results){
    //             setOptions(sortItemsByConfig(data?.results));
    //         }
    //     }
    //
    // }, [externalOptionsQuery.data, isInlineTable, selectOptions]);

    React.useEffect(() => {
        if (defaultValue) {
            if (options.length > 0) {
                getDefaultSelectValue();
            } else {
            }
        }
    }, [options, defaultValue, externalDefaultValue]);

    React.useEffect(() => {
        if (
            options.length === 0 &&
            selectValue &&
            !field.read_only &&
            field.format_rules_definition.sub_type !== SelectSubType.SEARCHEABLE
        ) {

            setSelectValue(null);
        }
    }, [options, selectValue, field]);

    const getField = () => {
        const {full_container = true} = field.format_rules_definition ?? {};

        const input: { [key: string]: JSX.Element | null } = {
            [SubType.TO_DO_LIST]: (
                <div
                    className={full_container ? "grid-full-column" : "d-flex flex-column"}
                >
                    {getOptionInput(field)}
                </div>
            ),
            [SubType.TOGGLE]: !field.single_value && field.format_rules_definition?.full_container ?
                <div
                    className={"grid-full-column"}
                >
                    {getOptionInput(field)}
                </div>
                : null

        };

        const defaultInput = getOptionInput(field);


        return field.sub_type
            ? input[field.sub_type] ?? defaultInput
            : defaultInput;
    };
    return getField();
};

export default CrmOptionsInputComponent;
