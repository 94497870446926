import * as React from 'react';
import {useContext, useState} from 'react';
import {Field} from "../../../types/Field";
import {Control, useFieldArray, useForm, UseFormGetValues, UseFormSetValue, UseFormWatch} from "react-hook-form";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import styled from "styled-components";
import {CSSObject} from "@emotion/serialize";
import {MenuProps} from "react-select";
import {FieldType} from "../../../types/FieldType";
import CrmSmallTextComponent from "./CrmSmallTextComponent";
import CrmOptionsInputComponent from "./CrmOptionsInputComponent";
import {Task} from "../../../types/Task";
import {LabelField} from "./CrmRenderInput";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {TaskComponentContext} from "../../../context/TaskContext";
import {StringOption} from "../../../types/Select";
import {getProviderOptions, IsJsonString} from "../../../utils";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {getProviderById} from "../../../services/backend/DataProviderService";
import {State} from "../../../types/State";

type Props = {
    field: Field,
    control?: Control<any, object>
};

const Container = styled.div<{ length: number }>`
    display: grid;
    grid-template-columns: ${props => `repeat(auto-fit, ${props.length <= 1 ? "265px" : "minmax(265px, 1fr)"}) `};
    align-items: start;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
`

type Form = {
    inputs: any[]
}

type DynamicFieldProps = {
    task?: Task
    watch?: UseFormWatch<any>
    control?: Control<any, object>
    setValue?: UseFormSetValue<any>,
    getFormValue?: UseFormGetValues<any>
    getDefaultValue: (field: Field) => any
}

export const DynamicFieldContext = React.createContext<DynamicFieldProps>({
    getDefaultValue: (field: Field) => {}
});

const CrmDynamicInputComponent = ({field}: Props) => {


    const [options, setOptions] = useState<StringOption[]>([])
    const [loading, setLoading] = useState(State.IDLE)
    const {setValue: setValueTask, unregister: unregisterTask, getFormValue, task} = useContext(TaskComponentContext)
    const {control: controlDForm, register, getValues, setValue, watch, unregister} = useForm<Form>({
        defaultValues: {
            inputs: []
        }
    })
    const {append, fields, remove} = useFieldArray({
        control: controlDForm,
        name: "inputs"
    })

    useAsyncEffect(async () => {
        if (field.list_provider) {
            setLoading(State.PENDING)
            const response = await getProviderOptions({
                list_provider: {
                    ...field.list_provider,
                }
            });

            if (response?.success && response?.items) {
                const newOptions: StringOption[] = []

                for await (const item of response.items) {
                    if (item.value && IsJsonString(item.value)) {

                        let column = JSON.parse(item.value)

                        if (column.list_provider_id) {
                            const provider = await getProviderById(column.list_provider_id)
                            if (provider?.success && provider.item?.query) {
                                column = {...column, list_provider: provider.item}
                            }
                        }

                        newOptions.push({label: column.label, value: column.name, data: column})
                    }
                }

                setLoading(State.RESOLVED)

                setOptions(newOptions)

            }else{
                setLoading(State.REJECTED)
            }

        }


    }, [field]);

    const handleOption = (event: { label: string, value: string, data: any }) => {
        if (event?.data && event.data?.name && event.data?.field_type) {

            if (!fields.some(field => field.name === event.data.name)) {
                append({
                    ...event.data
                })
            }

        }
    }

    const getDefaultValue = (field: Field) => {
        return field.name ? getValues(field.name as keyof Form) ?? "" : ""
    }

    const renderField = (fieldInternal: Field) => {
        return {
            [FieldType.SMALL_TEXT]: <CrmSmallTextComponent register={register} field={fieldInternal}
                                                           inputContext={DynamicFieldContext}
                                                           control={controlDForm}/>,
            [FieldType.OPTIONS]: <CrmOptionsInputComponent field={fieldInternal} contextInput={DynamicFieldContext} contextForm={DynamicFieldContext}/>,
        } as { [key: string]: JSX.Element }

    }

    React.useEffect(() => {
        if (getFormValue) {

            const taskProcessData = task?.process_data
            const form = {...getFormValue(), ...taskProcessData}

            if (options.length > 0 ) {

                let formParsed: {
                    [key: string]: {
                        name: string, value: any
                    }
                } = {}

                if (form[field.name]?.value && Object.keys(form[field.name]?.value).length > 0){
                    formParsed = JSON.parse(form[field.name]?.value)
                }else{
                    if (form[field.name] && typeof form[field.name] === "string" && IsJsonString(form[field.name])){
                        const fieldJsonData = JSON.parse(form[field.name])

                        if (Object.keys(fieldJsonData).length > 0 && Object.keys(fieldJsonData).some(key => fieldJsonData[key].value)){
                            formParsed = fieldJsonData
                        }
                    }
                }

                if (Object.keys(formParsed).length > 0){

                    Object.keys(formParsed).forEach(key => {
                        const option = options.find((option) => option.value === key)
                        setValue(key as keyof Form, formParsed[key]?.value)
                        if (option && !fields.some(field => field.name === option?.data.name)) {
                            append({
                                ...option.data
                            })
                        }
                    })
                }





            }
        }
    }, [task, field, options]);

    React.useEffect(() => {
        const subscription = watch((form) => {
                if (setValueTask && Object.keys(form).length > 0) {
                    let newForm: { [key: string]: string | number | any } = {...form}
                    delete newForm['inputs']
                    Object.keys(newForm).forEach((key) => {
                        // console.log('key', key)
                        if (unregisterTask) {
                            unregisterTask(key as keyof Task)
                        }
                    })
                    // console.log('newForm', newForm)

                    Object.keys(newForm).forEach((key) => {
                        const fieldForm = options.find(option => option.value === key)
                        const params: {
                            [key: string]: string
                        } = {value: (newForm[key as keyof typeof newForm] ?? "") as string}

                        if (fieldForm?.data?.id) {
                            params.id = fieldForm.data.id
                        }
                        newForm[key] = {...params}
                    })


                    setValueTask(field.name, JSON.stringify(newForm))
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watch, options])


    return (
        <Container className="grid-full-column" length={fields.length}>
            <DynamicFieldContext.Provider
                value={{setValue, watch, getFormValue: getValues, control: controlDForm, getDefaultValue}}>
                {
                    fields.map((fieldInternal, index) => {
                        return <div key={fieldInternal.id}
                                    className={`d-flex flex-column ${fieldInternal.format_rules_definition?.hide ? 'd-none' : ''}`}>
                            {<div className="d-flex align-items-center ">
                                <div onClick={() => {
                                    remove(index)

                                    unregister(fieldInternal.name as keyof Form)
                                    const newForm: Partial<Form> = {...getValues()}
                                    if (setValueTask) {
                                        delete newForm['inputs']
                                        delete newForm[fieldInternal.name as keyof Form]
                                        setValueTask(field.name, JSON.stringify(newForm))
                                    }
                                }}>
                                    <LabelField field={fieldInternal} icon={faTimesCircle} classNameIcon={"pointer"}
                                                colorIcon={'red'}/>
                                </div>
                            </div>}
                            {renderField(fieldInternal)[fieldInternal.field_type] ?? null}
                        </div>
                    })
                }
            </DynamicFieldContext.Provider>
            <div>
                <label className="mb-2">{field.label}</label>
                <SelectComponent disabled={loading === State.PENDING}
                                 loading={loading === State.PENDING} sortOptions styles={{
                    menu(base: CSSObject, props: MenuProps<any, any, any>): CSSObject {
                        return {
                            ...base,
                            zIndex: 999
                        }
                    }
                }} id={"dynamic_Select"} name={""} onChange={handleOption} options={options}/>
            </div>


        </Container>
    );
};

export default CrmDynamicInputComponent