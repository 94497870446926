function encrypt_data(str: string) {
    str = unescape(encodeURIComponent(str));
    let newString = '',
        char,
        nextChar,
        combinedCharCode;
    for (let i = 0; i < str.length; i += 2) {
        char = str.charCodeAt(i);

        if (i + 1 < str.length) {
            nextChar = str.charCodeAt(i + 1) - 31;

            combinedCharCode =
                char +
                '' +
                nextChar.toLocaleString('en', {
                    minimumIntegerDigits: 2,
                });

            newString += String.fromCharCode(parseInt(combinedCharCode, 10));
        } else {
            newString += str.charAt(i);
        }
    }
    return newString.split('').reduce((hex, c) => (hex += c.charCodeAt(0).toString(16).padStart(4, '0')), '');
}

function decrypt_data(str: string) {
    let newString = '',
        char,
        codeStr,
        firstCharCode,
        lastCharCode;
    str = str.match(/.{1,4}/g)?.reduce((acc, char) => acc + String.fromCharCode(parseInt(char, 16)), '') ?? '';
    for (let i = 0; i < str.length; i++) {
        char = str.charCodeAt(i);
        if (char > 132) {
            codeStr = char.toString(10);

            firstCharCode = parseInt(codeStr.substring(0, codeStr.length - 2), 10);

            lastCharCode = parseInt(codeStr.substring(codeStr.length - 2, codeStr.length), 10) + 31;

            newString += String.fromCharCode(firstCharCode) + String.fromCharCode(lastCharCode);
        } else {
            newString += str.charAt(i);
        }
    }
    return newString;
}

export class Encryption {
    private text: string = '';

    constructor(text: string) {
        this.text = text;
    }

    decrypt() {
        this.text = decrypt_data(this.text);
        return this;
    }

    encrypt() {
        this.text = encrypt_data(this.text);
        return this;
    }

    toString() {
        return JSON.stringify(this.text);
    }

    getText() {
        return this.text;
    }

    toObject<T>() {
        return this.text ? JSON.parse(this.text) as T : {} as T;
    }
}
