import React, {useState} from "react";
import AutoSuggestAccounts from "./AutoSuggestAccounts";
type Props = {
    backgroundColor?: string
}
const SearchBox = ({backgroundColor} : Props) => {

  const [show, setShow] = useState(false)

  return (
    <div className={"search-wrapper " + (show ? "active" : "")}>
      <div className="input-holder">
        <AutoSuggestAccounts/>
        <button onClick={() => setShow(true)} className="search-icon" style={backgroundColor ? {backgroundColor} : {}}>
          <span/>
        </button>
      </div>
      <button onClick={() => setShow(false)} className="btn-close"/>
    </div>
  )
}

export default SearchBox;
