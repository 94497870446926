import { useState } from "react";

export interface TabData<Data> {
    title: Data;
    component: JSX.Element;
}

export function TabsComponent<Data>({ tabs }: {tabs: TabData<Data>[]} ) {
    const [selectedTab, setSelectedTab] = useState<TabData<Data>>(tabs[0]);

    return (
            <div className='tabs'>
                <div className='tab-options'>
                {
                    tabs.map((tab: TabData<Data>) => (
                        <button 
                        onClick={() => setSelectedTab(tab)} 
                        className={`tab ${selectedTab.title === tab.title ? ' selected ' : ''}`}
                        >{tab.title as string}</button>
                    ))
                }
                </div>

                <div className='tab-content'>
                    {selectedTab.component}
                </div>
            </div>
    )
}