import * as React from 'react';
import {useContext, useEffect} from 'react';
import {Field} from "../../../types/Field";
import {FieldType, SubType} from "../../../types/FieldType";
import CrmDateInputComponent from "./CrmDateInputComponent";
import CrmBoolInputComponent from "./CrmBoolInputComponent";
import CrmSmallTextComponent from "./CrmSmallTextComponent";
import CrmLargeTextComponent from "./CrmLargeTextComponent";
import CrmFileInputComponent from "./CrmFileInputComponent";
import TitleComponent from "../../Shared/FieldComponents/TitleComponent";
import CrmOptionsInputComponent from "./CrmOptionsInputComponent";
import CrmAppointmentPickerComponent from "./CrmAppointmentPickerComponent";
import LabelComponent from "../../Shared/FieldComponents/LabelComponent";
import PopoverComponent from "../../Shared/PopoverComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import {
    containsCharacter,
    containsNumbers,
    convertDateToNumberDate,
    evalConditionExpression,
    evalExpression,
    getDefaultVarsFromStr,
    getInterpretVar,
    getNextBusinessDay,
    getObjValueInDeep,
    getVariableDefaultValue,
    isDefaultVarExpression,
    IsJsonString,
    isTableOperation,
    isUUID,
    isVarExpression,
    tableOperation,
    tableOperationAction
} from "../../../utils";
import {useSelector} from "react-redux";
import {authReducer, configReducer} from "../../../store/Selectors";
import {TaskComponentContext} from "../../../context/TaskContext";
import {useForm} from "react-hook-form";
import {TableTaskComponent} from "../TableForm/TableTaskComponent";
import useTranslate from "../../../hooks/useTranslate";
import CrmDynamicInputComponent from "./CrmDynamicInputComponent";
import useResponsive from "../../../hooks/useReponsive";

type Props = {
    field: Field;
    preview?: boolean;
};
const fieldNameDefaultValue: { [key: string]: string } = {};

type InputContextProps = {
    getDefaultValue: (field: Field) => string,
    field?: Field
}

export const CrmInputContext = React.createContext<InputContextProps>({getDefaultValue: () => ""});

const stringOperations = ["CONCAT"]

export function isDateValid(dateStr: string) {
    return !isNaN(Date.parse(dateStr));
}

export const dateDefault = [
    "${now}",
    "${now_on_saved}",
    "${now_on_created}",
    "${tomorrow}",
    "${now}",
    "${new Date()}"
]
const defaultVars: any = {}
const defaultVars2: any = {}
const breakDown: any = {}
const CrmRenderInput = ({field, preview}: Props) => {

    // this params are only to preview form
    const {control: controlP, register: registerP} = useForm()

    const {
        control,
        register,
        task,
        getFormValue,
        watch,
        setValue,
        form
    } = useContext(TaskComponentContext);
    const {t} = useTranslate('common');
    const {businessDays} = useSelector(configReducer);
    const {user} = useSelector(authReducer);

    const getValueFieldValidation = () => field.field_type !== FieldType.FILE && field.field_type !== FieldType.TABLE && field.field_type !== FieldType.OPTIONS && field.field_type !== FieldType.DATE;

    const getVarExpression = (defaultValue: string) => {
        const form = getFormValue ? getFormValue() : {}
        const varList = getDefaultVarsFromStr(field.default_value);

        // let newValue = defaultValue

        const newDefaultValue = evalConditionExpression({
                expression: defaultValue,
                form: {...(task?.process_data ?? {}), ...form, ...user, currentUser: user},
                getFormValue,
                fields: []
            }
        );

        const firstEval = evalExpression(newDefaultValue);

        if (typeof firstEval === "boolean") {
            if (field.format_rules_definition?.custom_value && field.format_rules_definition?.custom_value[firstEval.toString()]) {
                // console.log('ield.format_rules_definition?.custom_value[value.toString()]', field.format_rules_definition?.custom_value[value.toString()])
                return field.format_rules_definition?.custom_value[firstEval.toString()]
            }

            return firstEval ? t("yes") : "No"
        } else {
            if (firstEval === true) {
                defaultValue = newDefaultValue
            } else {


                // THis for full expresion
                if (varList && varList.length > 1) {
                    varList.forEach((defaultVar) => {
                        const nameVar = getVariableDefaultValue(defaultVar);
                        if (getFormValue && (getFormValue(nameVar) || getFormValue(nameVar) === 0)) {
                            const regex = new RegExp("\\b" + nameVar + "\\b", "g"); // Creating a regex pattern with word boundary and global flag
                            if (defaultValue.includes(nameVar)) {
                                defaultValue = defaultValue.replace(regex, getFormValue(nameVar));
                            }
                        }
                    });
                }

                if (form.hasOwnProperty(defaultValue) && form[defaultValue]) {
                    defaultValue = form[defaultValue];
                } else {
                    Object.keys(form).forEach(key => {
                        if ((form[key] || form[key] === 0 || form[key] === null || form[key] === undefined)) {
                            const isStringValue = ((containsCharacter(form[key]) && !containsNumbers(form[key])) || (containsCharacter(form[key]) && containsNumbers(form[key])))
                            const regex = new RegExp("\\b" + key + "\\b", "g"); // Creating a regex pattern with word boundary and global flag
                            if (defaultValue.includes("object_")) {
                                if (key.includes("object_") && defaultValue?.includes(key)) {
                                    defaultValue = defaultValue.replace(regex, isStringValue ? `'${form[key]}'` : form[key]);
                                }
                            } else {
                                if (defaultValue?.includes(key)) {
                                    defaultValue = defaultValue.replace(regex, typeof form[key] === "string" ? ((isUUID(form[key]) || isStringValue) ? `'${form[key]}'` : form[key]) : form[key]);
                                }
                            }
                        }
                    });
                }

                if (task?.process_data) {
                    Object.keys(task.process_data).forEach(key => {

                        if (task.process_data[key]?.value) {
                            const regex = new RegExp("\\b" + key + "\\b", "g"); // Creating a regex pattern with word boundary and global flag


                            if (defaultValue?.includes(key)) {
                                defaultValue = defaultValue.replace(regex, task.process_data[key]?.value);
                            }
                        }
                    });
                }


                if (defaultValue && typeof defaultValue === 'string' && defaultValue?.includes('currentUser.')) {

                    const varList = defaultValue.replaceAll(" ", "").trim().split(/(!==|===|>|<|>=|<=|==|&|\|)/g);

                    try {

                        varList.forEach((varItem) => {


                            // console.log("varItem", varItem)
                            if (varItem.includes('currentUser.')) {
                                const userVar = getInterpretVar({strVar: `${`$\{${varItem}}`}`, item: user});

                                // console.log("userVar", userVar)
                                if (userVar) {
                                    defaultValue = defaultValue.replaceAll(varItem, `'${userVar}'`);
                                }
                            }
                        })


                    } catch (e) {
                    }

                }

            }


            // Check missing vars

            const varList2 = defaultValue.replaceAll(" ", "").trim().split(/(!==|:|===|>|<|>=|\?|\(|\)|<=|==|&|\|)/gi);
            varList2.forEach((varItem) => {
                if (defaultValue.includes(varItem)
                    && varItem !== "null" && varItem !== "undefined" && varItem.includes("_")) {
                    defaultValue = defaultValue.replaceAll(varItem, "''")
                }
            })


            const value = evalExpression(defaultValue);

            if (typeof value === "boolean") {
                if (field.format_rules_definition?.custom_value && field.format_rules_definition?.custom_value[value.toString()]) {
                    // console.log('ield.format_rules_definition?.custom_value[value.toString()]', field.format_rules_definition?.custom_value[value.toString()])
                    return field.format_rules_definition?.custom_value[value.toString()]
                }

                return value ? t("yes") : "No"
            } else {

                return value
            }
        }
    }

    const transformDateValue = ({value, varName, newValue}: { newValue: string, varName: string, value: string }) => {
        if (isDateValid(value)) {
            if (field.default_value.includes(`NumberDate(${varName})`)) {
                return newValue.replaceAll(`NumberDate(${varName})`, convertDateToNumberDate(new Date(value)));
            } else {
                return newValue.replaceAll(varName, new Date(value).toISOString());
            }
        }

        return newValue
    }

    const getDefaultValue = (field: Field) => {
        if (field.default_value) {

            // The idea of this code is create a default value for a field given multiple fields, for example:
            // field.default_value = "CONCAT(${field1} + ${field2})"
            // This code will get the value of field1 and field2 and concatenate them
            // This is useful for fields that need to be calculated based on other fields

            if (stringOperations.some((operation: string) => field.default_value.includes(operation))) {

                const operation = stringOperations.find((operation: string) => field.default_value.includes(operation))

                const varList = getDefaultVarsFromStr(field.default_value);

                let value = ""

                if (operation) {
                    switch (operation) {
                        case "CONCAT":
                            if (varList && varList.length > 0) {
                                let defaultValue = field.default_value
                                varList.forEach((defaultVar) => {
                                    const nameVar = getVariableDefaultValue(defaultVar);
                                    if (task?.process_data[nameVar]?.value) {
                                        const processValue = task?.process_data[nameVar]?.value
                                        if (isDateValid(processValue)) {
                                            defaultValue = transformDateValue({
                                                newValue: defaultValue,
                                                value: processValue,
                                                varName: defaultVar
                                            }) as string
                                        } else {

                                            defaultValue = defaultValue.replaceAll(defaultVar, task?.process_data[nameVar]?.value);
                                        }
                                    }

                                    if (task?.process_data && task?.process_data[nameVar] && !task.process_data[nameVar]?.name) {
                                        const processValue = task?.process_data[nameVar]

                                        if (isDateValid(processValue as any)) {
                                            defaultValue = transformDateValue({
                                                newValue: defaultValue,
                                                value: processValue as any,
                                                varName: defaultVar
                                            }) as string
                                        } else {
                                            defaultValue = defaultValue.replaceAll(defaultVar, task?.process_data![nameVar] as any as string ?? "");
                                        }
                                    }

                                    if (getFormValue && getFormValue(nameVar)) {
                                        if (isDateValid(getFormValue(nameVar))) {
                                            defaultValue = transformDateValue({
                                                newValue: defaultValue,
                                                value: getFormValue(nameVar),
                                                varName: defaultVar
                                            }) as string
                                        } else {
                                            defaultValue = defaultValue.replaceAll(defaultVar, getFormValue(nameVar));
                                        }


                                    }
                                });

                                if (defaultValue && !defaultValue.includes('${')){
                                    const match = defaultValue.match(/^CONCAT\((.*)\)$/);

                                    // Extraer el contenido dentro de CONCAT()
                                    if (match) {
                                        const innerExpression = match[1];
                                        // Remplazar los operadores de concatenación y limpiar espacios extra
                                        value = innerExpression
                                            .replace(/\s*\+\s*/g, '')  // Remueve el operador `+` y espacios alrededor
                                            .replace(/"\s+"/g, ' ');   // Remueve comillas de concatenaciones de strings con espacios
                                    }


                                }
                            }


                            // console.log('defaultValue', defaultValue)
                            break
                        default:
                            break
                    }

                }

                return value

            } else {
                let defaultValue = getVariableDefaultValue(field.default_value);
                // console.log("defaultValue", defaultValue)
                fieldNameDefaultValue[defaultValue] = field.name;
                if (isVarExpression(field.default_value)) {
                    return getVarExpression(defaultValue)
                } else {


                    // Check is default values try to get a current user property
                    if (field.default_value.includes('currentUser.') && !field.default_value.includes("==") && !field.default_value.includes("!=")) {

                        return getInterpretVar({strVar: field.default_value, item: user});
                    } else {
                        switch (field.default_value) {
                            case '${now}': {
                                return new Date().toISOString();
                            }
                            case "${now_on_saved}": {
                                return new Date().toISOString()
                            }
                            case "${now_on_created}": {
                                return task?.created ?? ""
                            }
                            case '${tomorrow}': {
                                const today = new Date();
                                today.setDate(today.getDate() + 1);
                                return today.toISOString();
                            }
                            case '${next_business_day}': {
                                return getNextBusinessDay(businessDays)
                            }
                            case '${currentUser}':
                                return user?.email ?? '';

                            default:

                                if (isDefaultVarExpression(field.default_value)) {

                                    if (isTableOperation(field.default_value)) {
                                        const values = defaultValue.split(/\(|\)|,/g)
                                        const operation = values[0]
                                        const field = values[1]
                                        const column = values[2]?.trim()


                                        if (operation && field && column && getFormValue && getFormValue(field)) {

                                            let list = []

                                            if (IsJsonString(getFormValue(field))) {
                                                list = JSON.parse(getFormValue(field))
                                            } else {
                                                list = getFormValue(field)
                                            }

                                            return list.reduce((total: number, item: {
                                                [key: string]: number | string
                                            }) => {
                                                if (item.hasOwnProperty(column)) {
                                                    total += tableOperationAction({
                                                        action: operation as tableOperation,
                                                        value: parseFloat(item[column].toString())
                                                    })
                                                }
                                                return total;
                                            }, 0.0)
                                        }

                                    } else {


                                        if (getFormValue && (getFormValue(defaultValue) || getFormValue(defaultValue) === "" || getFormValue(defaultValue) === 0)) {

                                            return getFormValue(defaultValue);
                                        }


                                        const value = getVarExpression(defaultValue)


                                        if (value || value === 0) {
                                            return value
                                        }
                                    }


                                }

                                if (defaultVars[field.name]) {
                                    if (getFormValue && (getFormValue(defaultValue) || getFormValue(defaultValue) === "" || getFormValue(defaultValue) === 0)) {
                                        return getFormValue(defaultValue);
                                    }

                                    if (task?.process_data?.[defaultValue]?.value) {
                                        return task?.process_data[defaultValue]?.value ?? '';
                                    }

                                    if (task?.process_data?.[defaultValue]) {
                                        return task?.process_data[defaultValue] ?? '';
                                    }


                                    return ""
                                } else {
                                    if (isDefaultVarExpression(field.default_value)) {
                                        defaultVars[field.name] = true
                                    }

                                    if (getFormValue && (getFormValue(defaultValue) || getFormValue(defaultValue) === "" || getFormValue(defaultValue) === 0)) {

                                        return getFormValue(defaultValue);
                                    }


                                    if (task?.process_data && task?.process_data?.[defaultValue]?.value) {
                                        return task?.process_data[defaultValue]?.value ?? '';
                                    }

                                    if (!!task?.process_data && task?.process_data?.[field.name]?.value) {

                                        return task?.process_data[field.name]?.value;
                                    }


                                    if (!!task?.process_data && task?.process_data?.[defaultValue] && !task.process_data?.[defaultValue]?.value && !task.process_data?.[defaultValue].hasOwnProperty("value")) {


                                        // Some tasks has value NULL and it's a object with field type currency so we need to return empty string
                                        if (field.sub_type === SubType.CURRENCY && typeof task?.process_data?.[defaultValue] === "object") {
                                            return ""
                                        }

                                        return task?.process_data[defaultValue]
                                    }


                                    return field.default_value ? isTableOperation(field.default_value) ? "" : isDefaultVarExpression(field.default_value) ? "" : field.default_value ?? '' : ''
                                }


                        }
                    }

                }
            }


        } else {

            if (getFormValue && (getFormValue(field.name) || getFormValue(field.name) === "" || getFormValue(field.name) === 0)) {
                return getFormValue(field.name);
            }


            if (!!task?.process_data && task?.process_data?.[field.name]?.value) {
                if (field.field_type === FieldType.TABLE) {
                    fieldNameDefaultValue[field.name] = field.name;
                }

                return task?.process_data[field.name]?.value;
            }


        }
        return null;
    };

    const evalFieldRequired = () => {
        // let formData = {...(taskState?.form ?? {})}

        let formData = getFormValue ? getFormValue() : {}

        if (task?.process_data) {
            formData = {...task.process_data, ...formData}
        }

        if (field.format_rules_definition?.required) {
            const required = evalConditionExpression({
                expression: field.format_rules_definition.required,
                form: formData,
                getFormValue,
                fields: form?.fields?.map(nField => nField.name) ?? []
            })
            field.required = !!required
        }
    }

    useEffect(() => {
        let subscription: any = null
        if (watch) {
            subscription = watch((obj, {name, type}) => {
                if (name) {
                    evalFieldRequired()

                    if (setValue) {

                        if (field.format_rules_definition?.dependencies && field.format_rules_definition?.dependencies.includes(name) && field.field_type !== FieldType.OPTIONS) {

                            setValue(field.name, getDefaultValue(field) || '');
                        } else {

                            if (field.default_value && !dateDefault.includes(field.default_value)) {


                                let default_value = getDefaultValue(field) ?? ""
                                const formValues = getFormValue ? getFormValue() : {}

                                if ((!default_value && default_value !== 0) || (getObjValueInDeep(formValues, getVariableDefaultValue(field.default_value))
                                    && default_value !== getObjValueInDeep(formValues, getVariableDefaultValue(field.default_value)))) {
                                    default_value = getObjValueInDeep(formValues, getVariableDefaultValue(field.default_value))
                                }


                                if (!breakDown[field.name] || breakDown[field.name] < 10) {
                                    if ((default_value || default_value === 0) && getFormValue) {

                                        if ((!getFormValue(field.name) || (getFormValue(field.name) && getFormValue(field.name) !== default_value))) {
                                            breakDown[field.name] = (breakDown[field.name] || 0) + 1

                                            // This condition is some values are 0 by default and we need to set it
                                            if (default_value !== 0) {
                                                if (defaultVars2[field.name]) {
                                                    if (getObjValueInDeep(formValues, getVariableDefaultValue(field.default_value)) === "") {
                                                        default_value = ""
                                                    }
                                                } else {
                                                    if (isDefaultVarExpression(field.default_value)) {
                                                        defaultVars2[field.name] = true
                                                    }
                                                }
                                            }

                                            setValue(field.name, (default_value || default_value === 0) ? default_value : '');
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
        return () => subscription?.unsubscribe();
    }, [watch, field]);

    React.useEffect(() => {
        if (!!task?.process_data && setValue) {
            Object.keys(task.process_data).forEach(key => {
                if (fieldNameDefaultValue[key] && task.process_data[key]?.value) {
                    // check if it's a table type, table is a string then convert -> array
                    if (key === field.name && field.field_type === FieldType.TABLE) {
                        setValue(fieldNameDefaultValue[key], JSON.parse(task.process_data[key]?.value) ?? task.process_data[key]?.value)
                    } else {
                        setValue(fieldNameDefaultValue[key], task.process_data[key]?.value)
                    }
                }
            });
        }

    }, [fieldNameDefaultValue, task]);

    const isEntireComponentWidth = () => {
        const type = [FieldType.TABLE, FieldType.LABEL]

        const subType = [SubType.DYNAMIC]

        if (type.includes(field.field_type) || (field.sub_type && subType.includes(field.sub_type))) {
            return true
        }

        return !!(field.sub_type === SubType.TO_DO_LIST || (!field.single_value && field.sub_type === SubType.TOGGLE) && field.format_rules_definition?.full_container);


    }

    const getField = () => {
        // Only get default value of inputs except type table and file.
        if (task && setValue && getValueFieldValidation()) {
            let value = getDefaultValue(field)
            // console.log('field.name', field.name)
            // console.log('value', value)

            let defaultValueVar = field.default_value ? getVariableDefaultValue(field.default_value) : "";
            const processDataValue = task?.process_data ? task?.process_data![field.name] ?? value ? task?.process_data[defaultValueVar] : undefined : undefined

            if (processDataValue?.type && processDataValue?.type === "STRING" && value
                && !value.hasOwnProperty("value")
                && !value.hasOwnProperty("name")
            ) {
                value = value.toString()
            }

            if ((getFormValue?.(field.name) || getFormValue?.(field.name) === 0 || getFormValue?.(field.name) === "") && !value?.name) {
                value = getFormValue?.(field.name)
            }


            setValue(field.name, (value?.name ? value.value : (value || value === 0) ? value : "") ?? "");
        }


        const input: { [key: string]: JSX.Element } = {
            [FieldType.DATE_RANGE]: <CrmDateInputComponent field={field} control={control ?? controlP}/>,
            [FieldType.DATE]: <CrmDateInputComponent field={field} control={control ?? controlP}/>,
            [FieldType.BOOLEAN]: <CrmBoolInputComponent field={field} control={control ?? controlP}/>,
            [FieldType.SMALL_TEXT]: <CrmSmallTextComponent register={register ?? registerP} field={field}
                                                           control={control ?? controlP}/>,
            [FieldType.LARGE_TEXT]: <CrmLargeTextComponent field={field} register={register ?? registerP}/>,
            [FieldType.FILE]: <CrmFileInputComponent field={field}/>,
            [FieldType.LABEL]: <div className="grid-full-column">
                <TitleComponent id={field.id?.toString() ?? field.sort_index?.toString() + "_" + field.name}
                                defaultValue={field.default_value}/>
            </div>,
            [FieldType.OPTIONS]: field.sub_type === SubType.DYNAMIC ?
                <CrmDynamicInputComponent field={field} control={control ?? controlP}/> :
                <CrmOptionsInputComponent field={field} contextForm={TaskComponentContext}/>,
            [FieldType.APPOINTMENT_PICKER]: <CrmAppointmentPickerComponent preview={preview} field={field}
                                                                           control={control ?? controlP}
                                                                           task={task}/>,
            [FieldType.TABLE]: <TableTaskComponent task={task}
                                                   getValue={getFormValue}
                                                   watchTask={watch}
                                                   defaultValue={() => getDefaultValue(field)}
                                                   setValueInput={setValue}
                                                   key={field.id}
                                                   field={field}/>
        }


        if (register && !input[field.field_type]) {
            return <input
                className="form-control"
                id={field.name + '_' + field.id}
                disabled={field.read_only}
                type="text"
                defaultValue={getDefaultValue(field) || ""}
                {...register(field.name, {required: field.required && !field.read_only})}
            />
        }

        // Show entire components width
        if (isEntireComponentWidth() && input[field.field_type]) {
            return input[field.field_type]
        }

        return <div key={field.id}
                    className={` flex-column ${field.format_rules_definition?.hide ? 'd-none' : 'd-flex'}`}>
            {<LabelField field={field}/>}
            {input[field.field_type]}
        </div>


        // return null


    }

    return <CrmInputContext.Provider value={{getDefaultValue, field}}>
        {getField()}
    </CrmInputContext.Provider>
};

export default CrmRenderInput


export const LabelField = ({field, icon, colorIcon, classNameIcon}: {
    field: Field,
    icon?: any,
    colorIcon?: string,
    classNameIcon?: string
}) => {
    const {isTabletOrMobile} = useResponsive()
    return (
        <LabelComponent
            className={`${isTabletOrMobile ? "fw-bold" : ""}`}
            icon={field.hint ? <PopoverComponent
                label={<FontAwesomeIcon icon={faInfoCircle}/>}
                id={`pop_${field.id}`}
                trigger="hover"
                placement="bottom">
            <span>
              {field.hint}
            </span>
            </PopoverComponent> : (
                <Button
                    type="button"
                    color={'link'}
                    className={`${'ms-2 mb-2'}`}
                    size="sm">
                    <br/>
                </Button>
            )}>
            {icon && <FontAwesomeIcon icon={icon} color={colorIcon} className={`me-1 ${classNameIcon ?? ""}`}/>}
            {field.label} {field.required ? "*" : ""}
        </LabelComponent>
    );
};