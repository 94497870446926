import { Cookies } from "react-cookie";
import { setHeaderToken } from "../network";
import { UserData } from "./User";
import { Encryption } from "../utils/Encryption";
import { ConfigData } from "./ConfigData"; /*
 * @Author: Frank Carpio
 */

/*
 * @Author: Frank Carpio
 */

export type SessionData = {
  email: string;
  id: string;
  isAdmin: boolean;
  _KEY: string;
  config?: ConfigData["config"];
};

class Session {
  private keySession = "crm_token";
  private keySessionLoginAs = "login_as";
  private keyMainSession = "main_session";
  private keyPermissionsMapper = "permissionsMapper";
  private keyConfigAccounts = "crm_config_accounts";
  private userDataKey = "user_session";
  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  // check if user is login as another user
  public get isLoginAs() {
    return !!this.loginAs && !!this.mainSession;
  }

  // get main session from local storage
  get mainSession() {
    return localStorage.getItem(this.keyMainSession);
  }

  // get token from local storage
  get token() {
    return localStorage.getItem(this.keySession);
  }

  // get login as from local storage this is an email of user
  get loginAs() {
    return localStorage.getItem(this.keySessionLoginAs);
  }

  // exist session
  public get exist() {
    return !!this.token;
  }

  public set userData(data: UserData) {
    const newData: SessionData = {
      email: data.email || "",
      id: data.id?.toString() || "",
      isAdmin: data.isAdmin,
      _KEY: data._KEY || "",
    };

    const json = JSON.stringify(newData);
    const encrypted = new Encryption(json).encrypt().getText();
    this.cookies.set(this.userDataKey, encrypted, { path: "/" });
  }

  // set main session from local storage
  public setMainSession(session: string) {
    localStorage.setItem(this.keyMainSession, session);
  }

  // set token from local storage
  public setToken(token: string) {
    localStorage.setItem(this.keySession, token);
    this.cookies.remove(this.keySession);
    this.cookies.set(this.keySession, `Bearer ${token}`, { path: "/" });
    setHeaderToken(`${token}`);
  }

  // set login as from local storage this is an email of user
  public setLoginAs(email: string) {
    localStorage.setItem(this.keySessionLoginAs, email);
  }

  // clear all local storage and cookies
  public clear() {
    localStorage.clear();
    this.cookies.remove(this.keySession);
    this.cookies.remove(this.keyMainSession);
    this.cookies.remove(this.keySessionLoginAs);
    this.cookies.remove(this.keyPermissionsMapper);
    this.cookies.remove(this.keyConfigAccounts);
    this.cookies.remove(this.userDataKey);
  }

  // clear all local storage and cookies
  public clearAndReload(to?: string) {
    this.clear();
    if (to) {
      this.clearAndRedirectTo(to);
    } else {
      this.reload();
    }
  }

  // reload page
  public reload() {
    window.location.reload();
  }

  public redirectTo(to: string) {
    window.location.href = to;
  }

  public clearAndRedirectTo(to: string) {
    this.clear();
    this.redirectTo(to);
  }
}

export default new Session();
