import React, { useEffect, useReducer, useRef, useState } from "react";
import CustomTableComponent, {
  Action,
  Column,
} from "../Shared/CustomTableComponent/CustomTableComponent";
import { useDispatch, useSelector } from "react-redux";
import { authReducer } from "../../store/Selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEdit,
  faEye,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../hooks/useTranslate";
import { State } from "../../types/State";
import {
  Andor,
  Condition,
  GroupCondition,
  Model,
  SbxResponse,
} from "../../types/Sbx";
import {
  deleteSbxModelService,
  getSbxModelFields,
  insertSbxModelService,
} from "../../services/backend/SbxService";
import {
  BaseAccountData,
  capitalize,
  downloadKeyFile,
  evalExpression,
  getAllDataByProvider,
  getDefaultVarsFromStr,
  getFilterVarsByAccount,
  getObjValueInDeep,
  getVariableDefaultValue,
  removeDuplicateFromArray,
  StaticConstVar,
  toast,
  transformWhereQueryData,
} from "../../utils";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import { DataProviderStates } from "../../store/DataProvider/Types";
import { Col } from "reactstrap";
import { useRouter } from "next/router";
import { TableFormColumn } from "../TaskComponent/TableForm/TableTaskComponent";
import {SbxCrmDataColumn, SbxCrmDataInfo, TabsDetailConfig} from "../../types/User";
import DataFilterComponent from "./DataFilterComponent";
import { FileTypeIcon } from "../Shared/ContentEngine";
import SpinnerComponent from "../Shared/SpinnerComponent";
import { IPropsTableEditModal } from "../Shared/Modal/TableEditModal/TableEditModal";
import { ListProvider } from "../../types/Task";
import { Response } from "../../types/Response";
import { TabsNames } from "../Layouts/AdminLayout/SideBarContent";
import { routerActions } from "../../store/RouterReducer";
import DropzoneComponent from "../Shared/DropzoneComponent/DropzoneComponent";
import { uploadFile } from "../../services/backend/ContentService";
import { AnyData } from "../../types/AnyData";
import ButtonComponent from "../Shared/ButtonComponent";
import {getFilesDataByKeys} from "../../services/UtilsService";
import { Content } from "../../types/Folder/Content";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { Permissions } from "../../types/Permissions";
import Permission from "../AuthorityPermission/Permission";
import SbxModelComponent from "../SbxModelComponent";
import {sendSbxEvent} from "../../utils/analyticsUtils";
import DataBulkUpdate from "../../pages/bulk/[bulkName]/[modelName]/[cs]";
import {Find} from "sbxcorejs";

type Props = {
  dataModelName?: string;
  keyFilter?: string;
  filterObj?: { [key: string]: any } | string; // "account._KEY"
  isDetailView?: boolean;
  dataProcess?: SbxCrmDataInfo;
  dataType?: "data" | "file" | "table";
  mainModel?: string;
  configName: TabsNames;
  baseActionAccountData?: BaseAccountData;
};

enum Types {
  SET_STATE,
  SET_MULTI_STATE,
}

interface ModelState {
  process_name: string;
  columns: Column[];
  default_values: any;
  data: any[];
  copyData: any[];
  model: Model[];
  editTableHeaders: TableFormColumn[];
  process?: SbxCrmDataInfo;
  isLoading: State;
  processActions: Action[];
}

const initialState: ModelState = {
  process_name: "",
  columns: [],
  default_values: {},
  data: [],
  processActions: [],
  copyData: [],
  model: [],
  editTableHeaders: [],
  isLoading: State.IDLE,
};

function reducer(
  state: ModelState,
  {
    type,
    payload,
  }: {
    type: Types;
    payload: { name: string; value?: any } | { name: string; value: any }[];
  },
) {
  switch (type) {
    case Types.SET_STATE:
      return {
        ...state,
        [(payload as { name: string; value: any }).name]: (
          payload as { name: string; value: any }
        ).value,
      };
    case Types.SET_MULTI_STATE:
      (payload as { name: keyof ModelState; value: any }[]).forEach((data) => {
        state[data.name] = data.value;
      });
      return { ...state };
    default:
      throw new Error();
  }
}

const DataComponentList = ({
  dataModelName,
  isDetailView,
  filterObj,
  dataProcess,
  mainModel,
  dataType,
  configName,
  keyFilter,
  baseActionAccountData,
}: Props) => {
  const [localState, dispatchLocal] = useReducer(reducer, initialState);
  const { user } = useSelector(authReducer);
  const state = useSelector((state: any) => state.ModalReducer);
  const history = useRouter();
  const { t } = useTranslate("common");
  const onMount = useRef(false);

  const dispatch = useDispatch();

  const [modelName, setModelName] = useState(dataModelName ?? "");

  useEffect(() => {
    if (!isDetailView) {
      setModelName(history.query.modelName + "");
    }
  }, [history.query.modelName, isDetailView]);

  React.useEffect(() => {
    if (dataModelName) {
      setModelName(dataModelName);
    }
  }, [dataModelName]);

  const dispatchForm = ({
    name,
    value,
  }: {
    name: keyof ModelState;
    value: any;
  }) => {
    dispatchLocal({ type: Types.SET_STATE, payload: { value, name } });
  };

  const dispatchMultiForm = (
    forms: { name: keyof ModelState; value: any }[],
  ) => {
    dispatchLocal({ type: Types.SET_MULTI_STATE, payload: forms });
  };

  const getModelData = async ({
    fetchModels,
    listProvider,
    where, related_object_columns,
    fromHelper,
  }: {
    fetchModels?: string[];
    where?: Condition[];
    related_object_columns?: (SbxCrmDataColumn | TableFormColumn)[];
    listProvider?: (SbxCrmDataColumn | TableFormColumn)[];
    fromHelper?: boolean;
  }) => {
    let queryWhere = where ?? [];
    dispatchForm({ name: "isLoading", value: State.PENDING });

    let providers: (Response<ListProvider> | undefined)[] = [];
    if (listProvider) {
      const promisesListProvider = listProvider.map((provider) =>
        getAllDataByProvider({
          provider_id: (provider as TableFormColumn)?.list_provider ?? "",
        }),
      );
      providers = await Promise.all(promisesListProvider).then(
        (res) => res as ListProvider[],
      );
    }

    if (baseActionAccountData) {
      const group: GroupCondition[] = [];
      if (where) {
        if (
          baseActionAccountData.account &&
          baseActionAccountData.account_filter_column
        ) {
          group.push({
            ANDOR: "AND",
            FIELD: baseActionAccountData.account_filter_column,
            VAL: getObjValueInDeep(
              baseActionAccountData.account,
              baseActionAccountData.account_filter_column,
            ),
            OP: "=",
          });
        }

        if (
          baseActionAccountData.extra_query_data &&
          baseActionAccountData.extra_query_data.length > 0
        ) {
          baseActionAccountData.extra_query_data
            .filter((item) => {
              if (item.extra_query_apply) {
                const pathname =
                  window.location.pathname + window.location.search;
                if (
                  item.extra_query_apply.length === 1 &&
                  item.extra_query_apply[0] === "account"
                ) {
                  if (pathname.includes("addressee")) {
                    return false;
                  }
                }
                return item.extra_query_apply.some((route) =>
                  pathname.includes(route),
                );
              }

              return true;
            })
            .forEach((item) => {
              let value = "";

              if (
                item.data_filter_column.includes("sales_addressee.") &&
                baseActionAccountData.salesAddresee
              ) {
                const newVar = item.data_filter_column.replaceAll(
                  "sales_addressee.",
                  "",
                );
                value = getObjValueInDeep(
                  baseActionAccountData.salesAddresee,
                  newVar,
                );
              } else if (
                item.data_filter_column.includes("account.") &&
                baseActionAccountData.account
              ) {
                const newVar = item.data_filter_column.replaceAll(
                  "account.",
                  "",
                );
                value = getObjValueInDeep(
                  baseActionAccountData.account,
                  newVar,
                );
              } else if (
                item.data_filter_column.includes("row.") &&
                baseActionAccountData.data
              ) {
                const newVar = item.data_filter_column.replaceAll("row.", "");
                value = getObjValueInDeep(baseActionAccountData.data, newVar);
              } else {
                if (
                  baseActionAccountData.account &&
                  getObjValueInDeep(
                    baseActionAccountData.account,
                    item.data_filter_column,
                  )
                ) {
                  value = getObjValueInDeep(
                    baseActionAccountData.account,
                    item.data_filter_column,
                  );
                }
              }

              group.push({
                ANDOR: "AND",
                FIELD: item.account_filter_column,
                VAL: value,
                OP: "=",
              });
            });
        }

        where.push({
          ANDOR: Andor.AND,
          GROUP: group,
        });
      }
    }

    if (
      user?.config?.sbx_crm &&
      user.config.sbx_crm[configName][modelName] &&
      user.config.sbx_crm[configName][modelName]?.filter_list_conditions &&
      user.config.sbx_crm[configName][modelName]?.filter_list_conditions!
        .length > 0
    ) {
      const filterListConditions = user.config.sbx_crm[configName][modelName]
        ?.filter_list_conditions as Condition[];

      queryWhere = queryWhere.concat(filterListConditions);
    }


    let response: SbxResponse = await getSbxModelFields({
      provider: {
        name: modelName as string,
        query: JSON.stringify({
          WHERE: transformWhereQueryData({ where: queryWhere, user }),
          FETCH: fetchModels ? removeDuplicateFromArray(fetchModels) ?? [] : [],
        }),
      },
    });

    if (response?.success) {
      response.results?.forEach((item) => {
        if (item.meta_data && JSON.parse(item.meta_data)) {
          const meta = JSON.parse(item.meta_data);
          Object.keys(meta).forEach((key) => {
            if (providers.length > 0 && listProvider) {
              // search provider by column
              const provider = listProvider.find(
                (provider) => provider.column === key,
              );
              if (provider) {
                // get provider id
                const provider_id = parseInt(
                  (provider as TableFormColumn)?.list_provider ?? "",
                );
                // get provider response by the service and get it to use the options to get the default label based on value
                const providerResponse = providers
                  .filter((provider) => provider?.success)
                  .find((provider) => provider?.item?.id === provider_id);
                if (
                  providerResponse &&
                  providerResponse.item?.options &&
                  providerResponse.item.options.length > 0
                ) {
                  const default_value = providerResponse.item.options.find(
                    (item) => item.value === meta[key],
                  );
                  if (default_value) {
                    item[key] = default_value;
                  }
                } else {
                  item[key] = meta[key];
                }
              } else {
                // if there isn´t a provider set default value from sbx or database
                item[key] = meta[key];
              }
            } else {
              item[key] = meta[key];
            }
          });
        } else {
          if (providers.length > 0 && listProvider) {
            Object.keys(item).forEach((key) => {
              if (
                item[key] &&
                (typeof item[key] === "string" ||
                  (item[key]._KEY && typeof item[key]._KEY === "string"))
              ) {
                const provider = listProvider.find(
                  (provider) =>
                    provider.column === key ||
                    (provider as any).reference === key,
                );
                if (provider) {
                  const providerResponse = providers.find(
                    (nProvider) =>
                      nProvider?.row_model &&
                      (nProvider?.row_model === provider.column ||
                        nProvider?.row_model === (provider as any).reference),
                  );
                  if (providerResponse && providerResponse.items) {
                    const nItem = providerResponse.items.find(
                      (nItem: AnyData) =>
                        nItem._KEY === item[key] ||
                        nItem._KEY === item[key]._KEY,
                    );

                    if (nItem) {
                      item[key] = nItem;
                    }
                  }
                }
              }
            });
          }
        }
      });

      //Filter results by parent key

      if (typeof filterObj === "string") {

        response.results = response.results?.filter(
          (data) => getObjValueInDeep(data, filterObj) === keyFilter,
        );
      } else {
        if (filterObj && Object.keys(filterObj).length > 0) {
          const addreseeConfig = user?.config?.sbx_crm?.addressee;
          const accountConfig = user?.config?.sbx_crm?.accounts;

          let filter = "account._KEY === row.account._KEY";
          if (
            accountConfig?.tabs_detail_config &&
            (accountConfig.tabs_detail_config[configName] as TabsDetailConfig)[modelName]?.filter
          ) {
            filter =
              ((accountConfig.tabs_detail_config["data"] as TabsDetailConfig)[modelName]
                ?.filter as string) ?? "";
          }

          if (
            filterObj["sales_addressee"] &&
            filterObj["sales_addressee"]?._KEY &&
            addreseeConfig?.tabs_detail_config &&
            (addreseeConfig.tabs_detail_config[configName] as TabsDetailConfig)[modelName]?.filter
          ) {
            filter =
              ((addreseeConfig.tabs_detail_config[configName] as TabsDetailConfig)[modelName]
                ?.filter as string) ?? "";
          }

          response.results = response.results?.filter((data) => {
            const condition = getFilterVarsByAccount({
              account: filterObj.account,
              salesAddresee: filterObj.sales_addressee,
              data: data,
              query: filter,
            });
            return evalExpression(condition);
          });
        }
      }

      const resModel = await getSbxModelFields({
        provider: {
          size: 1,
          name: modelName as string,
          query: JSON.stringify({
            WHERE: where,
            FETCH: fetchModels
              ? removeDuplicateFromArray(fetchModels) ?? []
              : [],
          }),
        },
      });

      if (fromHelper) {
        onMount.current = false;
      }

      if (response?.results && response.results.length > 0 && related_object_columns){
        const keys = response.results.map((item) => item._KEY);
        // console.log('keys', keys)

        let queries: any[] = []

        for await (const related_object of related_object_columns) {
          if (related_object?.related_object && related_object.related_object_column){
            const query = new Find(related_object.related_object, 0)
            query.andWhereIsIn(related_object.related_object_column, keys)

            const result = getFetchedColumns([], related_object)
            query.fetchModels(result ?? [])
            const newQuery = query.compile()
            queries.push(newQuery)
          }

        }

        const responseAll = await Promise.all(queries.map(query => {
          return getSbxModelFields({
            provider: {
              name: query.row_model,
              query: JSON.stringify({
                WHERE: query.where ?? [],
                FETCH: query.fetch ?? [],
              }),
            },
          })
        }))

        if (responseAll?.length > 0 && response?.results) {
          response.results = response.results.map(result => {
            related_object_columns.forEach(related_object => {

              const itemResponse = responseAll.find(response => {
                return response?.results?.find((item: any) => related_object.related_object_column && item[related_object.related_object_column] === result._KEY)
              })

              if (related_object.reference && related_object.column_reference) {

                const item = itemResponse?.results?.find((item: any) => related_object.related_object_column && item[related_object.related_object_column] === result._KEY)
                if (item && related_object.column) {
                  const value = item[related_object.column] ? item[related_object.column] ?? "" : ""
                  result = {
                    ...result,
                    [related_object.column]: value ?? ""
                  }
                }
              }
            })

            return result
          })
        }
      }

      dispatchMultiForm([
        { name: "data", value: response.results },
        { name: "copyData", value: response.results },
        { name: "isLoading", value: State.RESOLVED },
        { name: "model", value: resModel?.model ?? [] },
      ]);
    } else {
      dispatchForm({ name: "isLoading", value: State.REJECTED });
    }
  };

  useEffect(() => {
    if (
      configName &&
      user.config?.sbx_crm[configName] &&
      modelName &&
      user.config.sbx_crm[configName][modelName]
    ) {
      dispatch(
        routerActions.changeBreadcrumb([
          {
            label: capitalize(configName),
          },
          {
            label: t(user.config.sbx_crm[configName][modelName].label),
            active: true,
          },
        ]),
      );
    }
  }, [configName, user, modelName]);

  const getFetchedColumns = (array: string[], column: SbxCrmDataColumn | TableFormColumn) => {

    const arr = [...array]
    if (
        column.column_reference &&
        column.column_reference.compound_name
    ) {
      const varList = getDefaultVarsFromStr(
          column.column_reference.compound_name,
      );
      if (varList && varList.length > 0) {
        varList.forEach((strVar) => {
          const nameVar = getVariableDefaultValue(strVar);
          if (column.column_reference) {
            const sub_references = nameVar.split(".");
            if (sub_references.length > 1) {
              arr.push(`${column.column}.${sub_references[0]}`);
            } else {
              if (column.column) {
                arr.push(column.column);
              }
            }
          }
        });
      }
    } else {
      if (column.column_reference) {
        const sub_references =
            column.column_reference.column.split(".");
        if (sub_references.length > 0) {
          arr.push(
              `${column.column}${
                  sub_references.length > 1 ? "." + sub_references[0] : ""
              }`,
          );
        } else {
          if (column.column) {
            arr.push(column.column);
          }
        }
      }
    }


    return arr
  }

  const getTableData = (fromHelper = false) => {
    if (
      user.config &&
      Object.keys(user.config?.sbx_crm[configName] ?? {}).length > 0
    ) {
      let process =
        dataProcess ?? user.config.sbx_crm[configName][modelName as string];
      const jsonColumns = process.columns
        .filter((column) => !column.hidden && column.type === "json")
        .reduce((arr: TableFormColumn[], column) => {
          column.data
            ?.filter((columnItem) => !columnItem.hidden)
            .forEach((columnItem) => {
              arr.push({
                label: columnItem.label,
                name: columnItem.column, // value: column?.column_reference?.column ?? '',
                sub_type: capitalize(columnItem.type),
                parent: column.column,
                read_only: column.read_only ?? false,
                sub_columns:
                  columnItem.type === "reference"
                    ? [
                        {
                          compound_name:
                            (columnItem as SbxCrmDataColumn)?.column_reference
                              ?.compound_name ?? "",
                          name:
                            (columnItem as SbxCrmDataColumn)?.column_reference
                              ?.column ?? "",
                          label: (columnItem as SbxCrmDataColumn).column,
                        },
                      ]
                    : null,
                ...(columnItem as any),
              });
            });
          return arr;
        }, []);

      const availableColumns = [...process.columns].filter((column) => {
        return !column.hidden && column.type !== "json";
      });

      dispatchMultiForm([
        { name: "process", value: process },
        {
          name: "processActions",
          value:
            process.actions?.map((action) => ({
              label: action.label,
              title: "",
              type: "info",
              onAction: (row: any) => {
                history.push(
                  history.asPath + "/" + row._KEY + "/" + action.action,
                );
              },
            })) ?? [],
        },
        { name: "process_name", value: process.label },
        {
          name: "editTableHeaders",
          value: [
            ...availableColumns.map((column) => {
              return {
                name: (column as SbxCrmDataColumn).column,
                read_only: (column as SbxCrmDataColumn).read_only ?? false,
                sub_type: capitalize((column as SbxCrmDataColumn).type),
                sub_columns:
                  column.type === "reference"
                    ? [
                        {
                          compound_name:
                            (column as SbxCrmDataColumn)?.column_reference
                              ?.compound_name ?? "",
                          name:
                            (column as SbxCrmDataColumn)?.column_reference
                              ?.column ?? "",
                          label: (column as SbxCrmDataColumn).column,
                        },
                      ]
                    : null,
                ...column,
              };
            }),
            ...jsonColumns,
          ],
        },
        {
          name: "columns",
          value: [
            ...availableColumns
              .filter((column) => column.list)
              .map((column) => ({
                ...column,
                header: column.label,
                name: column.column,
                value: column?.column_reference?.column ?? "",
                type: capitalize(column.table_column_type || column.type),
                style: { minWidth: `${column.label.length}em` },
              })),
            ...jsonColumns.map((jsonColumn) => ({
              header: jsonColumn.label,
              name: jsonColumn.name, // value: column?.column_reference?.column ?? '',
              type: capitalize(jsonColumn.sub_type) as any,
              parent: jsonColumn.name,
              style: { minWidth: `${jsonColumn.label.length}em` },
            })),
          ],
        },
        {
          name: "default_values",
          value: process.columns
            .filter((column) => {
              return (
                column.default &&
                !column.calculated &&
                !StaticConstVar.includes(column.default)
              );
            })
            .reduce((obj, column) => {
              obj[column.column] = column.default;
              return obj;
            }, {} as any),
        },
      ]);

      const fetchModels = [
        ...process.columns.filter(
          (column) => (column.type === "reference" || column.reference) && !column.related_object,
        ),
        ...jsonColumns.filter((column) => column.sub_type === "Reference"),
      ];

      const listProvider = [
        ...process.columns.filter((column) => column.type === "list_provider"),
        ...jsonColumns.filter((column) => column.sub_type === "List_provider"),
      ];

      let where: any[] = [];
      if (process.filter && Object.keys(process.filter).length > 0) {
        where.push({
          ANDOR: "AND",
          GROUP: Object.keys(process.filter).map((it) => {
            return {
              ANDOR: "AND",
              FIELD: it,
              VAL: process.filter[it as string],
              OP: "=",
            };
          }),
        });
      }

      getModelData({
        related_object_columns:  [...process.columns].filter(
            (column) => column.related_object,
        ),
        fetchModels: fetchModels
          .filter((column) => column.column_reference)
          .reduce((arr: string[], column) => {

            const result = getFetchedColumns(arr, column)

            return arr.concat(result);
          }, []),
        where,
        listProvider,
        fromHelper,
      });
    }
  };

  React.useEffect(() => {
    if (
      (!state[ModalTypes.TABLE_EDIT_MODAL]?.open || dataProcess) &&
      modelName !== ""
    ) {
      // getTableData();
    }
  }, [
    modelName,
    user.config,
    state[ModalTypes.TABLE_EDIT_MODAL]?.open,
    dataProcess,
  ]);

  React.useEffect(() => {
    if (modelName !== "") {
      getTableData();
    }
  }, [modelName]);


  const sendDataEvent = (name: string, params: any) => {
    const eventParams = {
      ...params,
    }

    if (mainModel) {
      eventParams['related_object_model'] = mainModel
      if (keyFilter) {
        eventParams['related_object__KEY'] = keyFilter
      }
    }


    sendSbxEvent({
      props: {
        ...eventParams
      },
      name
    })
  }

  const handleProduct = (insert = false) => {
    if (modelName) {
      const payload = {
        modal_key: modelName,
        type: ModalTypes.TABLE_EDIT_MODAL,
        row_model: modelName as string,
        insert,
        model: localState.model ?? [],
        headers: localState.editTableHeaders,
        defaultValues: localState.default_values,
        toggleHelper: () => {
          getTableData(true);
        },
        onSuccess: (params: any, response: any) => {
          if  (insert){
            sendDataEvent("sbx_crm_insert_data_row", params)
          }
        },
        pre_data: dataProcess ? state[ModalTypes.TABLE_EDIT_MODAL].data : null,
        // headers: localState.model?.map(model => ({name: model.name, label: model.name, sub_columns: model.reference_type_name ? })) ?? []
      };

      if (mainModel) {
        payload.defaultValues = {
          ...payload.defaultValues,
          [mainModel]: keyFilter,
        };
      }

      if (localState.data.some((item) => item.account)) {
        const isAccount = localState.data.every(
          (item) =>
            item?.account?._KEY === localState.data[0]?.account?._KEY ||
            item.account === localState.data[0].account,
        );
        if (isAccount) {
          const accountKey =
            localState.data[0]?.account?._KEY ??
            localState.data[0]?.account ??
            "";
          payload.defaultValues = {
            ...payload.defaultValues,
            account: accountKey,
          };
        }
      }


      dispatch(actionsModal.openModal(payload as IPropsTableEditModal));
    }
  };

  const actions: Action[] = [
    {
      label: (
        <div className="d-flex align-items-center">
          <span className="me-1">{`${t("view")} ${t("detail")}`}</span>
          <FontAwesomeIcon icon={faEye} />
        </div>
      ),
      title: "",
      type: "primary",
      onAction: (row) => {
        dispatch(
          actionsModal.openModal({
            type: ModalTypes.TABLE_EDIT_MODAL,
            row_model: modelName as string,
            configName,
            modal_key: modelName,
            model: localState.model ?? [],
            process: localState.process,
            isDetailView: true,
            headers: localState.editTableHeaders.map((header) => ({
              ...header,
              read_only: true,
            })),
            defaultValues: localState.default_values,
            item: row,
            pre_data: dataProcess
              ? state[ModalTypes.TABLE_EDIT_MODAL].data
              : null,
          }),
        );
      },
    },
    {
      label: (
        <div className="d-flex align-items-center">
          <span className="me-1">{`${t("update")}`}</span>
          <FontAwesomeIcon icon={faEdit} />
        </div>
      ),
      title: "",
      type: "primary",
      permission: Permissions.DATA_MANAGER_EDIT,
      visible: baseActionAccountData?.allow
        ? baseActionAccountData.allow.includes("edit")
        : true,
      onAction: (row) => {
        dispatch(
          actionsModal.openModal({
            type: ModalTypes.TABLE_EDIT_MODAL,
            configName,
            row_model: modelName as string,
            model: localState.model ?? [],
            toggleHelper: () => {
              // onMount.current = false
              getTableData(true);
            },
            onSuccess: (params: any, response: any) => {
              sendDataEvent("sbx_crm_update_data_row", params)
            },
            headers: localState.editTableHeaders.map((header) => ({
              ...header,
              read_only: header.unique ?? header.read_only,
            })) as TableFormColumn[],
            defaultValues: localState.default_values,
            item: row,
            pre_data: dataProcess
              ? state[ModalTypes.TABLE_EDIT_MODAL].data
              : null,
          }),
        );
      },
    },
    {
      label: (
        <div className="d-flex align-items-center">
          <span className="me-1">{`${t("delete")}`}</span>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      ),
      title: "",
      permission: Permissions.DATA_MANAGER_DELETE,
      visible: baseActionAccountData?.allow
        ? baseActionAccountData.allow.includes("delete")
        : true,
      type: "danger",
      onAction: (row) => {
        dispatch(
          actionsModal.openModal({
            type: ModalTypes.CONFIRM,
            onConfirm: async () => {
              dispatch(actionsModal.closeModal({ type: ModalTypes.CONFIRM }));
              dispatchForm({ name: "isLoading", value: State.PENDING });
              const response = await deleteSbxModelService({
                row_model: modelName as string,
                keys: [row._KEY],
              });
              if (response?.success) {

                sendDataEvent("sbx_crm_delete_data_row",  {
                  row_model: modelName as string,
                  keys: [row._KEY],
                })
                dispatchForm({ name: "isLoading", value: State.RESOLVED });
                toast({ message: t("success_delete_message") });
              } else {
                dispatchForm({ name: "isLoading", value: State.REJECTED });
                toast({ message: t("rejected_message"), type: "error" });
              }
              getTableData();
            },
            message: <p>¿{`${t("custom-message:warn-delete")}`}?</p>,
            title: (
              <span>
                <FontAwesomeIcon className="me-2" icon={faTrash} />
                {t("delete")}
              </span>
            ),
            state: DataProviderStates.OPTION_PENDING,
          }),
        );
      },
    },
    ...localState.processActions,
  ];

  const tableActions: Action[] = [
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faEye} /> Ver datos
        </span>
      ),
      type: "primary",
      onAction: (row) => {
        setModelName(row.name);
      },
    },
  ];

  const handleData = (data: any[]) => {
    dispatchForm({ name: "data", value: data });
  };

  const exportButtons = user.config?.sbx_crm[configName]
    ? user.config?.sbx_crm[configName][modelName]?.export
    : [];

  const onFileSubmit = async (files: File[]) => {
    const uploadFiles: any[] = [];
    dispatchForm({ name: "isLoading", value: State.PENDING });
    if (files.length > 0) {
      for (const file of files) {
        const newFile = {
          file_name: file.name,
          path: `/chldemo/sbx_crm/test/files_data/${file.name}`,
          file: file,
        };

        const response: any = await uploadFile({ data: newFile });
        if (response?.success) {
          uploadFiles.push({
            name: file.name,
            key: response.item.key_id,
            [mainModel as string]: keyFilter,
          });
        }
      }
    }

    const res = await insertSbxModelService({
      row_model: dataModelName ?? "",
      rows: uploadFiles,
    });
    if (res?.success) {
      dispatchForm({ name: "isLoading", value: State.RESOLVED });
      getTableData();
    } else {
      dispatchForm({ name: "isLoading", value: State.REJECTED });
    }
  };

  useAsyncEffect(async () => {
    const newData = [...localState.data];
    if (
      localState.columns.length > 0 &&
      localState.data.length > 0 &&
      !onMount.current && localState.columns.some(column => column.type === "Document")
    ) {
      onMount.current = true;

      const docColumns = localState.columns.filter(column => column.type === "Document");

      const documentKeys = localState.data.map(item => {
        return docColumns.map(column => item[column.name])
      }).flat().filter(key => key)

      const documents = await getFilesDataByKeys(documentKeys);

      if  (documents?.item?.contents){
        for await (const column of localState.columns) {
          if (column.type === "Document") {
            localState.data.forEach((item) => {
              if (item[column.name]) {
                const document = documents.item.contents.find(
                    (content: Content) => content.key === item[column.name],
                );
                if (document) {
                  const index = newData.findIndex(
                      (data: any) => data._KEY === item._KEY,
                  );

                  if (index !== -1) {
                    newData[index] = {
                      ...newData[index],
                      [column.name]: JSON.stringify([document]),
                    };
                  }
                }
              }
            });
          }
        }

        dispatchForm({ name: "data", value: newData });
      }




    }
  }, [localState]);

  const getDataByType = (data: any[]) => {
    if (dataType === "file") {
      return localState.isLoading === State.PENDING ? (
        <div className="d-flex align-items-center justify-content-center">
          <SpinnerComponent />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="row mb-2">
            {localState.data.map((row) => {
              return (
                <Col
                  lg={2}
                  md={3}
                  sm={6}
                  key={row._KEY}
                  className=" text-center mt-2"
                >
                  <div
                    title={"Double click to download file"}
                    onClick={async () => {
                      dispatchForm({ name: "isLoading", value: State.PENDING });
                      // await downloadFileService(row.url, row.name);
                      await downloadKeyFile(row.key);
                      dispatchForm({
                        name: "isLoading",
                        value: State.RESOLVED,
                      });
                    }}
                    className="pointer shadow--hover text-center p-2"
                  >
                    <FileTypeIcon name={row.name} />
                  </div>
                  <span title={row.name} className="text-blue pointer">
                    {row.name}
                  </span>
                </Col>
              );
            })}
          </div>
          <DropzoneComponent onSubmit={onFileSubmit} />
        </div>
      );
    }

    if (dataType === "table") {
      return null;
    }

    return (
      <CustomTableComponent
        exportButtons={exportButtons}
        useLocalPage
        actions={actions}
        columns={localState.columns}
        data={data}
        accountData={baseActionAccountData}
        loading={localState.isLoading === State.PENDING}
      />
    );
  };

  return (
    <div className="d-flex flex-column">
      {user?.config &&
        user?.config?.sbx_crm &&
        user?.config?.sbx_crm[configName] &&
        !modelName && (
          <CustomTableComponent
            useLocalPage
            actions={tableActions}
            columns={[{ name: "label", header: "Modelo" }]}
            data={
              user?.config
                ? Object.keys(user.config.sbx_crm[configName]).reduce(
                    (object: any[], key) => {
                      const data = user?.config?.sbx_crm[configName];
                      if (data && !Array.isArray(data[key])) {
                        object.push({
                          ...data[key],
                          name: key,
                        });
                      }
                      return object;
                    },
                    [],
                  )
                : []
            }
          />
        )}

      {localState.isLoading !== State.PENDING && modelName && (
        <div>
          <>
            {((baseActionAccountData &&
              baseActionAccountData.allow?.includes("add")) ||
              !baseActionAccountData) && (
              <Permission permission={Permissions.DATA_MANAGER_CREATE}>
                <div className="mb-2 d-flex justify-content-end">
                  <ButtonComponent
                    onClick={() => handleProduct(true)}
                    label={t("add")}
                    icon={faPlusCircle}
                    color={"primary"}
                  />
                    <ButtonComponent
                        className="ms-2"
                        onClick={() => {
                            const config = user.config?.sbx_crm?.data[modelName];
                          if (config) {
                            dispatch(
                                actionsModal.openModal({
                                  type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
                                  noFooter: true,
                                  title: config.import?.params.name ?? config.import?.params.label ?? undefined,
                                  size: "xl",

                                  renderComponent: () => {

                                    if (config?.import) {
                                      if (config?.import.type === "bulk" && config.import.params) {
                                        return <DataBulkUpdate bulkUpdatesProps={[config.import.params]}
                                                               modelNameProps={config.import.params.name ?? config.import.params.label ?? ""}
                                                               csProps={config?.import.params.cs}/>
                                      }
                                    }

                                    return <SbxModelComponent
                                        config={config}
                                        sbxModel={modelName}
                                        onSuccess={(params, response) => {
                                          sendDataEvent("sbx_crm_insert_data_row", {
                                            ...params,
                                            csv_file: true
                                          })
                                        }}
                                    />
                                  }
                                }),
                            );
                          }
                        }}
                        label={t("import")}
                        icon={faPlusCircle}
                    />
                </div>
              </Permission>
            )}

            <DataFilterComponent
              configName={configName}
              model={modelName}
              process={localState.process}
              handleData={handleData}
              modelData={localState.copyData ?? []}
            />
          </>

          {isDetailView && (
            <div>
              <ButtonComponent
                onClick={() => {
                  setModelName("");
                }}
                label={t("back")}
                icon={faArrowLeft}
              />
            </div>
          )}
        </div>
      )}

      {modelName && getDataByType(localState.data)}
    </div>
  );
};

export default DataComponentList;
