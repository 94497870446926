import { useSelector } from "react-redux";
import { RootState } from "../../store/Reducers";
import React, {
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { State } from "../../types/State";
import useTranslate from "../../hooks/useTranslate";
import { capitalize, subtractHours } from "../../utils";
import { plainToClass } from "class-transformer";
import { Appointment } from "../../types/Appointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faEdit,
  faPen,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Col, Row } from "reactstrap";
import SelectComponent from "../Shared/FieldComponents/SelectComponent";
import AccountSelectComponent from "../Shared/AccountSelectComponent";
import InviteSelectorComponent from "../Shared/InviteSelectorComponent";
import LargeTextComponent from "../Shared/FieldComponents/LargeTextComponent";
import Permission from "../AuthorityPermission/Permission";
import { Permissions } from "../../types/Permissions";
import { CalendarContext } from "./CalendarComponent";
import {
  DatesWrapperProps,
  EventCalendar,
} from "../CalendlyComponent/CalendlyComponent";
import { OnConfirmComponent } from "../Shared/OnConfirmComponent";
import { Account } from "../../types/Account";

type DetailProps = DatesWrapperProps<Appointment> &
  Record<"onSubmit", (e: FormEvent<HTMLFormElement>) => void> &
  Record<"onDelete", (event: EventCalendar<Appointment>) => void> &
  Record<"account", Account | undefined>;

export function formatValue(value: string, data: Record<string, any>) {
  const regExp = new RegExp(/\${(.*?)}/, "g");
  let label = value;
  const matches = label.match(regExp) || [];

  matches.forEach((match) => {
    const key = match.replace("${", "").replace("}", "");
    label = label.replace(match, data[key] || "");
  });

  return label;
}

export function EventDetailsComponent(props: DetailProps) {
  const { currentEvent, onChangeEvent, date, onSubmit } = props;

  const {
    PermissionReducer: { users },
    AuthReducer: { user },
  } = useSelector((root: RootState) => root);

  const {
    state: { sales, process, state, providers },
  } = useContext(CalendarContext);

  const loading = state === State.PENDING;
  const { t } = useTranslate("calendar");

  const [edit, setEdit] = useState(false);
  const time = useMemo(() => {
    const { hours, minutes } = subtractHours(
      currentEvent?.startDate.toFormattedDate("HH:mm")!,
      currentEvent?.endDate.toFormattedDate("HH:mm")!,
    );

    if (hours < 1) return `${minutes} minutes`;
    if (minutes < 1) return `${hours} hours`;
    return `${hours} hours and ${minutes} minutes`;
  }, [currentEvent]);

  const newEvent = () => {
    const startDate = new Date(date);
    const endDate = new Date(date);
    endDate.setMinutes(endDate.getMinutes() + 30);
    const title = `Event ${startDate.toFormattedDate("yyyy MMM dd")}`;

    const appointment = plainToClass(Appointment, {
      summary: title,
      description: "",
      start_date: startDate.toFormattedDate("yyyyMMdd").toNumber(),
      start_time: startDate.toFormattedDate("HH:mm"),
      due_date: endDate.toFormattedDate("yyyyMMdd").toNumber(),
      end_time: endDate.toFormattedDate("HH:mm"),
      account_data: props.account
        ? {
            value: props.account?._KEY,
            label: props.account?.company.company_name || "No name",
            data: props.account,
          }
        : undefined,
    });

    onChangeEvent?.({
      title,
      description: "",
      startDate,
      endDate,
      location: "",
      data: appointment,
    });
    setEdit(true);
  };

  // process options
  const options = process.map((process) => ({
    label: capitalize(process.name),
    value: process.id,
  }));

  // sales options
  const salesOptions = useMemo(
    () =>
      sales.map((sale) => {
        switch (true) {
          case !!user.config?.sbx_crm.calendar?.options_addressee_rules?.label:
            let label = formatValue(
              user.config?.sbx_crm.calendar?.options_addressee_rules?.label!,
              sale,
            );

            return {
              label: label,
              value: sale._KEY,
            };

          default:
            return {
              label: sale.code + " - " + capitalize(sale.name),
              value: sale._KEY,
            };
        }
      }),
    [sales],
  );

  const userOptions = users.map((user) => ({
    label: capitalize(user.full_name),
    value: user.email,
  }));

  useEffect(() => {
    if (currentEvent) {
      const event = Object.assign({}, currentEvent);
      const newEndDate = new Date(date);
      newEndDate.setMinutes(newEndDate.getMinutes() + 30);

      let title = currentEvent.data?.summary ?? "";

      if (title.match(/Event \d{4} \w{3} \d{2}/)) {
        title = `Event ${date.toFormattedDate("yyyy MMM dd")}`;
      }

      onChangeEvent?.({
        ...event,
        title,
        startDate: date,
        endDate: newEndDate,
        data: {
          ...event.data!,
          summary: title,
          start_date: date.toFormattedDate("yyyyMMdd").toNumber(),
          start_time: date.toFormattedDate("HH:mm"),
          due_date: newEndDate.toFormattedDate("yyyyMMdd").toNumber(),
          end_time: newEndDate.toFormattedDate("HH:mm"),
        },
      });
    }
  }, [date]);

  function onChangeEventValue(key: keyof Appointment, value: any) {
    onChangeEvent?.({
      ...currentEvent!,
      data: {
        ...currentEvent!.data!,
        [key]: value,
      },
    });
  }

  const getValueAddressee = (key: string) =>
    salesOptions.find((opt) => opt.value === key) ?? null;
  const getValueProcess = (id: number) =>
    options.find((opt) => opt.value === id) ?? null;

  return (
    <div className="event-detail overflow-scroll h-100">
      {!currentEvent ? (
        <>
          <h3>
            {t('selectEvent')} {t('common:or')} <br /> {t('createNew')}
          </h3>
          <p
            onClick={newEvent}
            className="text-small text-link underline pointer"
          >
            {t('createHere')}
          </p>
        </>
      ) : (
        <>
          {!!currentEvent && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setEdit(false);
                onChangeEvent?.(undefined);
              }}
              style={{
                height: "40px",
                width: "40px",
              }}
              className=" text-left pl-0 underline rounded-circle my-2 pointer border d-flex justify-content-center align-items-center mb-5"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
          )}

          {edit ? (
            <div className="position-relative">
              <label htmlFor=""> {t("common:issue")}</label>
              <input
                type="text"
                value={currentEvent.data?.summary}
                name=""
                onChange={(e) => onChangeEventValue("summary", e.target.value)}
                className="form-search w-100 border-bottom p-2 border-gray font-size-lg mb-2"
                id=""
              />
              <FontAwesomeIcon
                icon={faPen}
                className="position-absolute right"
              />
            </div>
          ) : (
            <h5>{currentEvent.title}</h5>
          )}
          {!edit && (
            <>
              <p className="text-gray">
                {(currentEvent.description || "no description").capitalize()}
              </p>

              {!!currentEvent.data?.account_data && (
                <>
                  <p className="text-dark">
                    {currentEvent.data.account_data.label.popOfSplit("-")}
                  </p>
                </>
              )}

              {!!currentEvent.data?.invitees && (
                <>
                  <p className="text-dark">
                    <b>Invitees</b>
                  </p>
                  <p className="text-gray">
                    {currentEvent.data.invitees
                      .split(",")
                      .map(
                        (email) =>
                          userOptions.find((opt) => opt.value === email)
                            ?.label || email,
                      )
                      .join(", ")}
                  </p>
                </>
              )}
            </>
          )}
          <div className="text-dark d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faClock} />
            {time}
          </div>
          <div className="text-dark">
            <p>
              {currentEvent.startDate.toFormattedDate("HH:mm a")} {" - "}
              {currentEvent.endDate.toFormattedDate("HH:mm a")}
            </p>
          </div>
          {edit && !!currentEvent.data ? (
            <div>
              <div className="mb-2">
                <form onSubmit={onSubmit}>
                  <Row className="mb-2">
                    {user.config?.sbx_crm.calendar?.selectors?.workflow_id !==
                      "hide" && (
                      <Col lg={12}>
                        <div className="form-group mb-3">
                          <label htmlFor="">{t("common:process")}</label>
                          <SelectComponent
                            name={"workflow_id"}
                            options={options}
                            id={"workflow_id"}
                            disabled={loading}
                            onChange={(e) =>
                              onChangeEventValue("workflow_id", e.value)
                            }
                            value={getValueProcess(
                              currentEvent.data.workflow_id,
                            )}
                          />
                        </div>
                      </Col>
                    )}

                    {Object.entries(
                      user.config?.sbx_crm.calendar?.selectors ?? {},
                    ).map(([k, v]) => {
                      if (v === "hide") return null;

                      const optionsSelector = providers[k] || [];
                      return (
                        <Col lg={12}>
                          <div className="form-group mb-3">
                            <label htmlFor="">{t(`common:${v.label}`)}</label>
                            <SelectComponent
                              name={v.field}
                              options={optionsSelector}
                              id={v.field}
                              disabled={loading}
                              onChange={(e) =>
                                onChangeEventValue(v.field, e.value)
                              }
                              value={optionsSelector.find(
                                (opt) =>
                                  opt.value ===
                                  (currentEvent.data as any)?.[
                                    v.field
                                  ]?.toString(),
                              )}
                            />
                          </div>
                        </Col>
                      );
                    })}

                    <Col lg={12}>
                      <div className="form-group mb-3">
                        <label htmlFor="">{t("common:client")}</label>
                        <AccountSelectComponent
                          id="client"
                          name="client"
                          disabled={loading || !!props.account}
                          placeholder={t("common:search") + "..."}
                          value={currentEvent.data.account_data}
                          onChange={(e) =>
                            onChangeEventValue("account_data", e)
                          }
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="form-group mb-3">
                        <label htmlFor="">{`${t("common:addressee")} (${t(
                          "common:optional",
                        )})`}</label>
                        <SelectComponent
                          disabled={loading}
                          name={"sales_addressee"}
                          options={salesOptions}
                          id={"sales_addressee"}
                          onChange={(e) => {
                            onChangeEventValue("sales_addressee", e.value);
                          }}
                          value={getValueAddressee(
                            currentEvent.data.sales_addressee,
                          )}
                        />
                      </div>
                    </Col>

                    {getValueAddressee(currentEvent?.data?.sales_addressee) && (
                      <Col lg={12}>
                        <div className="form-group mb-3">
                          <label htmlFor="">{t("common:invitees")}</label>
                          <InviteSelectorComponent
                            id="invitees"
                            name="invitees"
                            disabled={loading}
                            value={userOptions.filter(
                              (opt) =>
                                currentEvent?.data?.invitees
                                  ?.split(",")
                                  .some((email) => email === opt.value),
                            )}
                            onChange={(e) => {
                              onChangeEventValue(
                                "invitees",
                                e.map((opt: any) => opt.value).join(","),
                              );
                            }}
                          />
                        </div>
                      </Col>
                    )}

                    <Col lg={12}>
                      <div className="form-group mb-3">
                        <label htmlFor="">{t("common:description")}</label>
                        <LargeTextComponent
                          disabled={loading}
                          id={"description"}
                          name={"description"}
                          onChange={(e) => onChangeEventValue("description", e)}
                          value={currentEvent.data.description ?? ""}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="form-group mb-3 d-flex gap-2 justify-content-end">
                    <Permission
                      permission={[
                        Permissions.ACCOUNT_EDIT,
                        Permissions.CALENDAR_CREATE,
                      ]}
                    >
                      <button
                        disabled={loading}
                        className="btn btn-primary w-100 "
                      >
                        <FontAwesomeIcon
                          icon={loading ? faSpinner : faCheck}
                          spin={loading}
                        />
                      </button>
                    </Permission>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex gap-2">
                <span
                  onClick={(e) => {
                    setEdit(true);
                    e.stopPropagation();
                  }}
                  className="text-link text-left pl-0 underline my-2 pointer"
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit event
                </span>
              </div>
            </>
          )}
        </>
      )}
      {!currentEvent && <EventsForEventSelectedComponent {...props} />}
    </div>
  );
}

function EventsForEventSelectedComponent(props: DetailProps) {
  const { onChangeEvent, date, events, onChangeDate } = props;

  const {
    state: { state },
  } = useContext(CalendarContext);

  const loading = state === State.PENDING;

  const [slice, setSlice] = useState(3);
  const { t } = useTranslate("calendar");

  const eventsAvailableForCurrentEvent = events?.filter((event) =>
    event.startDate.equals(date),
  );

  // for each change of date, reset the slice
  useEffect(() => setSlice(3), [date]);

  return (
    <div className="my-3 overflow-scroll" style={{ maxHeight: "60%" }}>
      <small>
        {eventsAvailableForCurrentEvent?.length} {t('events')} {t('common:for')}{" "}
        {date.toFormattedDate("MMM dd yyyy")}
      </small>
      {eventsAvailableForCurrentEvent?.slice(0, slice)?.map((event, i) => (
        <div
          className="text-small   pointer my-2 d-flex align-items-center justify-content-between"
          key={i}
        >
          <span
            className="text-gray"
            onClick={() => {
              onChangeEvent?.(event);
              onChangeDate?.(event.startDate);
            }}
          >
            <b className={"text-link underline"}>{event.title}</b>
            <br />
            {event.startDate.toFormattedDate("HH:mm a")} {" - "}
            {event.endDate.toFormattedDate("HH:mm a")}{" "}
          </span>
          <OnConfirmComponent
            onConfirm={() => props.onDelete(event)}
            className="btn btn-link"
          >
            <FontAwesomeIcon
              spin={loading}
              className="text-danger ms-1"
              icon={loading ? faSpinner : faTrash}
            />
          </OnConfirmComponent>
        </div>
      ))}

      {!!eventsAvailableForCurrentEvent?.length &&
        eventsAvailableForCurrentEvent.length > slice && (
          <span
            onClick={() => setSlice(eventsAvailableForCurrentEvent.length)}
            className="text-primary underline pointer text-center w-100"
          >
            View more <FontAwesomeIcon icon={faChevronDown} />
          </span>
        )}
    </div>
  );
}
