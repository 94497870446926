import React from "react";
import { MenuItem } from "./types/SideBar";
import Link from "next/link";
import routes from "../../routes/routes";
import { useRouter } from "next/router";
import SubMenuIitemsComponent from "./SubMenuItemsComponent";
import {sendSbxEvent} from "../../utils/analyticsUtils";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";

type Props = {
  menu: MenuItem;
  menuActive: string;
  setMenuActive: (menuName: string) => void;
  subMenu: string;
  setSubMenu: (menuName: string) => void;
};

const MenuItemsComponent = ({
  menu,
  menuActive,
  setMenuActive,
  setSubMenu,
  subMenu,
}: Props) => {
  const history = useRouter();
  const {user} = useSelector(authReducer)
  React.useEffect(() => {
    const current = routes.find((r) => {
      if (r.views) {
        r.views.find((v) => {
          const path = `${v.root}${v.path !== "/" ? v.path : ""}`;
          return history.pathname.split("?").shift() === path;
        });
      } else {
        return history.pathname.split("?").shift() === r.path;
      }
    });
    setMenuActive(current?.name ?? routes[0].name);
  }, []);

  const isMenu = !!menu?.subItems.length;

  const getPath = (path: string) => {

    if (path.includes("{origin}")) {
      return ""
    }

    return path
  }

  return (
    <div>
      <li
        key={menu.label}
        className={menuActive === menu.name ? "mm-show mm-active" : ""}
      >
        {!isMenu && (
          <Link href={menu.path ?? ""}>
            <span className={menu.active ? "mm-active" : ""}>
              <i className={"metismenu-icon " + menu.icon} />
              {menu.label}
            </span>
          </Link>
        )}
        {isMenu && (
          <a
            onClick={() =>
              setMenuActive(menu.name !== menuActive ? menu.name : "")
            }
          >
            <i className={"metismenu-icon " + menu.icon} />
            {menu.label}
            <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
          </a>
        )}
        {isMenu && (
          <ul
            className={`collapse-list ${
              menuActive === menu.name ? "mm-show menu-item-active" : ""
            }`}
            style={{ maxHeight: menuActive === menu.name ? "500px" : 0 }}
          >
            {menu.subItems.map((subItem) => {
              if (subItem.subItems?.length) {
                return (
                  <SubMenuIitemsComponent
                    key={subItem.name}
                    menu={subItem}
                    menuActive={subMenu}
                    setMenuActive={setSubMenu}
                  />
                );
              }

              return (
                <li
                  key={subItem.label}
                  onClick={() => {

                    sendSbxEvent({
                      name: "sbx_crm_click_menu",
                      props: {
                        menu: {
                          name: menu.name ?? "", label: menu.label ?? ""
                        },
                        menu_item: {
                          name: subItem.name ?? "",
                          label: subItem.label ?? "",
                        },
                        user:{
                          id: user.user_id?? user.id,
                          email: user.email
                        }
                      }
                    })
                    setMenuActive(menu.name)
                  }}
                >
                  {!subItem.path.includes("web-designer") || subItem.path.includes("{origin}") ? (
                    <Link title={subItem.path} href={getPath(subItem.path) ?? null} onClick={() => {
                      if (subItem.path.includes("{origin}")) {
                        const origin = window.location.origin
                        window.location.replace(subItem.path.replace("/{origin}", origin))
                      }
                    }}>
                      <span className={subItem.active ? "mm-active" : ""}>
                        <i className="metismenu-icon" />
                        {subItem.label}
                      </span>
                    </Link>
                  ) : (
                    <a href={subItem.path} title={subItem.path}>
                      <span className={subItem.active ? "mm-active" : ""}>
                        <i className="metismenu-icon" />
                        {subItem.label}
                      </span>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </div>
  );
};

export default MenuItemsComponent;
