import { DatesWrapperProps } from "./CalendlyComponent";
import React, { useEffect, useMemo } from "react";

const hoursByDate = () => {
  const date = new Date();
  const currentDay = date.getDate();

  const datesWithTimes: Date[] = [];
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  while (date.getDate() === currentDay) {
    datesWithTimes.push(new Date(date));
    date.setMinutes(date.getMinutes() + 30);
  }

  return datesWithTimes;
};

const validateTime = (time: Date, startTime = "0:0", endTime = "23:30") => {
  const startHour = startTime.shiftOfSplit(":");
  const endHour = endTime.shiftOfSplit(":");
  const startMinute = startTime.popOfSplit(":");
  const endMinute = endTime.popOfSplit(":");

  const temporallyTimeStart = new Date();
  const temporallyTimeEnd = new Date();
  temporallyTimeStart.setHours(parseInt(startHour));
  temporallyTimeStart.setMinutes(parseInt(startMinute));

  temporallyTimeEnd.setHours(parseInt(endHour));
  temporallyTimeEnd.setMinutes(parseInt(endMinute));

  return time
    .toFormattedDate("HHmm")
    .toNumber()
    .between(
      temporallyTimeStart.toFormattedDate("HHmm").toNumber(),
      temporallyTimeEnd.toFormattedDate("HHmm").toNumber(),
    );
};

function getNewTimesForDate(d: Date) {
  const date = new Date(d);
  const isToday = date.equals(new Date());

  if (isToday) {
    while (
      date
        .toFormattedDate("HHmm")
        .toNumber()
        .lessThan(new Date().toFormattedDate("HHmm").toNumber())
    ) {
      date.setMinutes(date.getMinutes() + 30);
    }
  }

  return date;
}

export function EventTimeComponent<Data>({
  date,
  onChangeDate,
  endTime,
  startTime,
  month,
  onChangeMonth,
  currentEvent,
  events,
}: DatesWrapperProps<Data>) {
  //generate times of the day
  const times = useMemo(hoursByDate, []);

  const [dateForTimes, setDateForTimes] = React.useState(date);

  const setTime = (time: Date) => {
    const d = new Date(dateForTimes);
    d.setHours(time.getHours());
    d.setMinutes(time.getMinutes());
    d.setSeconds(0);
    onChangeDate?.(d);
  };

  useEffect(() => {
    const d = getNewTimesForDate(date);
    setDateForTimes(d);
    document.getElementById(d.toFormattedDate("hh:mm a"))?.scrollIntoView();
  }, [date]);

  function validateAvailableTime(time: Date) {
    const currentDate = new Date();

    const isToday = date.equals(currentDate);

    if (isToday) {
      return time
        .toFormattedDate("HHmm")
        .toNumber()
        .lessThanOrEqual(currentDate.toFormattedDate("HHmm").toNumber());
    }

    return isToday;
  }

  const notAvailableTimes = useMemo(() => {
    return (
      events?.reduce((acc: Record<string, boolean>, event) => {
        if (date.equals(event.startDate)) {
          times.forEach((time) => {
            const isBetween = time
              .toFormattedDate("HHmm")
              .toNumber()
              .between(
                event.startDate.toFormattedDate("HHmm").toNumber(),
                event.endDate.toFormattedDate("HHmm").toNumber(),
              );

            if (isBetween) {
              acc[time.toFormattedDate("hh:mm a")] = true;
            }
          });
        }

        return acc;
      }, {}) ?? {}
    );
  }, [events, date, times]);

  return (
    <div style={{ height: "80%" }}>
      <div
        className="d-flex justify-content-center align-items-start"
        style={{ height: "74px" }}
      >
        {!month.equals(new Date()) && (
          <button
            onClick={() => {
              onChangeMonth?.(new Date());
            }}
            className="btn btn-primary"
          >
            Today
          </button>
        )}
      </div>

      <div className="event-container">
        {times
          .filter((time) => validateTime(time, startTime, endTime))
          .map((time, i) => (
            <button
              disabled={notAvailableTimes[time.toFormattedDate("hh:mm a")]}
              aria-hidden={validateAvailableTime(time)}
              id={time.toFormattedDate("hh:mm a")}
              onClick={() => setTime(time)}
              key={i}
              aria-selected={dateForTimes
                .toFormattedDate("HH:mm")
                .equals(time.toFormattedDate("HH:mm"))}
              className="event-btn"
            >
              <p>{time.toFormattedDate("hh:mm a")}</p>
            </button>
          ))}
      </div>
    </div>
  );
}
