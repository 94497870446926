import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import useTranslate from "../../../../hooks/useTranslate";
import TransformDateCalculator from "./TransformDateCalculator";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {ReportContext} from "../../NewReportGeneratorComponent";
import SuggestedTransformInput
    from "../../../ReportGeneratorComponent/ActionsComponent/TransformComponent/SuggestedTransformInput";
import TransformStringCalculator from "./TransformStringCalculator";
import TransformMathOperations from "./TransformMathOperations";
import TransformTotalizeCalculator from "./TransformTotalizeCalculator";

type Props = {
    color: string
    action: AnalyticQueryAction,
    index: number
    value: string
    onChange: (value: string) => void
};

type Operation = "date_operations" | "custom" | "string_operations" | "arithmetic_operations" | "totalize_operations"

const transformModel: {[key: string]: string[]}  = {
    date_operations: ["@add", "@sub", "@diff"],
    string_operations: ["@case", "@replace", "@concat"],
    arithmetic_operations: ["+", "-", "*", "/"],
    totalize_operations: ["@summarize", "@percentage", "@differential"]
}

const possibleCustom = ["datetime", "date_time", '" - "', "@formateddate_to_date", "@date_to_formateddate", "astype", ".str", ".assign"]

const TransformDataOperationsWrapper = ({color, index, action, onChange, value}: Props) => {

    const {t} = useTranslate('report')
    const onMount  = useRef(false)
    const [operation, setOperation] = useState<Operation | undefined>(undefined)

    const {getActionColumns} = useContext(ReportContext)

    React.useEffect(() => {


        if (!onMount.current) {
            if (value) {
                let foundMode = false

                Object.keys(transformModel).forEach((key) => {
                    if (transformModel[key]?.some(op => {
                        return (value ?? "").toString().includes(op)
                    })) {
                        if ((key !== "arithmetic_operations")
                            || (key === "arithmetic_operations" && !possibleCustom.some(op => value.includes(op)))) {
                            setOperation(key as Operation)
                            foundMode = true
                        }

                    }
                })

                if (!foundMode) {
                    setOperation("custom")
                }

            }

            onMount.current = true
        }


    }, [value]);

    const handleOperation = (operation: Operation | undefined) => {
        switch (operation) {
            case "date_operations":
                return <TransformDateCalculator onChange={onChange} color={color} index={index} value={value}
                                                columns={getActionColumns(action, index)}/>
            case "string_operations":
                return <TransformStringCalculator onChange={onChange} color={color} value={value} columns={getActionColumns(action, index)}/>
            case "arithmetic_operations":
                return <TransformMathOperations onChange={onChange} index={index} color={color} value={value} columns={getActionColumns(action, index)}/>

            case "totalize_operations":
                return <TransformTotalizeCalculator onChange={onChange} index={index} color={color} value={value} columns={getActionColumns(action, index)}/>
            case "custom":
                return <div className="flex-grow-1"> <SuggestedTransformInput transformation={value}
                                                setTransformation={onChange}
                                                columns={getActionColumns(action, index)} />
                </div>

            default:
                return null
        }
    }

    return (
        <div className="d-flex gap-3 py-2">
            <ActionDropdownColumns color={color} columns={[
                'date_operations',
                'string_operations',
                'arithmetic_operations',
                'totalize_operations',
                'custom'
            ]} onChange={value => {
                setOperation(value as Operation)
            }} label={operation ? t(operation) : `${t('select')} ${t('operation')}`}/>
            {handleOperation(operation)}
        </div>
    );
};

export default TransformDataOperationsWrapper