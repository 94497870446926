import React, { useEffect } from 'react';
import { Permissions } from "../../types/Permissions";
import { useDispatch } from "react-redux";
import { actionHistory } from "../../store/SearchReducer";
import { ProfileDesign } from "../../types/User";

export interface Tab {
  key?: string
  label: React.ReactNode;
  icon?: React.ReactNode;
  permission?: Permissions | Permissions[];
  visible?: boolean;
  component: React.ReactNode;
  disabled?: boolean;
  loaded?: () => void;
}

interface IProps {
  tabs: Tab[];
  pills?: boolean;
  tap?: string;
  getCurrentTab?: (tab: string) => void;
  reloadInfo?: boolean;
  direction?: "end" | "start" | "center";
  actions?: (JSX.Element | JSX.Element[])[];
  isEditMode?: boolean;
  colors?: ProfileDesign;
}

function TabContents({
                       tabs: list,
                       pills = true,
                       getCurrentTab,
                       reloadInfo = true,
                       actions,
                       direction = "start",
                       tap,
                       isEditMode = false,
                       colors
                     }: IProps) {
  const [hTabsIcons, setHTabsIcons] = React.useState('0');
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCurrentTab) {
      getCurrentTab(hTabsIcons);
    }
    dispatch(actionHistory.changeText(""));
  }, [hTabsIcons, dispatch]);

  useEffect(() => {
    if (tap && tap !== hTabsIcons) {
      setHTabsIcons(tap);
    }
  }, [tap])

  const LoadedComponent = (props: { loaded?: () => void }) => {
    useEffect(() => {
      if (props.loaded) {
        props.loaded()
      }
    }, []);
    return null;
  }

  const tabs = list.filter(({visible = true}) => visible);

  const resetPagitation = () => {
    dispatch(actionHistory.changePage(1));
  }

  return (
    <>
      <div className="d-flex flex-column z-index-0">
        {isEditMode && colors ? <style>
          {`
          .tabs-animated .nav-link::before {
            background: ${colors.tabs.tab} !important; /* Cambia el color azul por el que desees */
          }
          .tabs-animated .nav-link.active::before {
            background: ${colors.tabs.tab} !important; /* Color diferente cuando está activo */
          }
        `}
        </style> : ''}
        <ul className={`body-tabs body-tabs-layout tabs-animated body-tabs-animated nav justify-content-${direction}`}>
          {tabs.map(({label, icon, disabled = false}, index) => <li className="nav-item" key={index}>
            <a onClick={(e) => {
              e.preventDefault();
              setHTabsIcons(index.toString());
              resetPagitation();
            }} role="tab" className={'nav-link ' + ((hTabsIcons === index.toString() ? 'active' : ''))}
               id={'tab-' + index} data-bs-toggle="tab" href="#crm">
              <span>{icon && icon} {label} </span>
            </a>
          </li>)}
        </ul>

        {actions && actions.map(action => action)}
      </div>
      <div className="tab-content z-index-0">
        {tabs.map(({component, disabled, loaded}, index) =>
          <div
            className={'tab-pane tabs-animation fade ' + (hTabsIcons === index.toString() ? ' show active' : '')}
            key={index} role="tabpanel">
            {(!disabled && hTabsIcons === index.toString() || !reloadInfo) && (
              <>
                <div style={{
                  pointerEvents: isEditMode ? 'none' : 'auto',
                }}>
                  {component}
                </div>
                <LoadedComponent loaded={loaded}/>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default TabContents;
