import React, {useEffect, useMemo, useState} from "react";
import {RootState} from "../../../store/Reducers";
import {useSelector} from "react-redux";
import LogoutCard from "../../Shared/LogoutCard";
import SearchBox from "../../Shared/SearchBox";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import LanguageMenuComponent from "../../Shared/LanguageMenuComponent/LanguageMenuComponent";
import styled from "styled-components";


type Props = {
    sidebarOpen: boolean;
    setSidebarOpen: (open: (prevState: boolean) => boolean) => void;
}

const HamburgerInner = styled.span<{backgroundColor?: string}>`
    background: ${props => props.backgroundColor ?? ""} !important;
    
    ::before, ::after {
        background: ${props => props.backgroundColor ?? ""} !important;
    }
    
    // .is-active {
    //     background: ${props => props.backgroundColor ?? ""} !important;
    // }
`

const NavBarComponent = ({sidebarOpen, setSidebarOpen}: Props) => {

    const {user: {full_name, email, login_as_id, config}} = useSelector((state: RootState) => state.AuthReducer);


    const [mobileOpen, setMobileOpen] = useState(false);
    const [timeout, setTimeOut] = useState<any>(null);
    const {isMobile} = useDeviceDetect();

    const navBarStyles = useMemo(() => {

        if (config?.sbx_crm?.design_layout?.navbar?.design) {
            return config.sbx_crm.design_layout.navbar?.design;
        }

        return null
    }, [config])

    useEffect(() => {
        if (sidebarOpen) {
            if (timeout) {
                clearTimeout(timeout);
                setTimeOut(null);
                return;
            }
            const time = setTimeout(() => {
                setSidebarOpen(prevState => false);
                setTimeOut(null);
            }, 5000);
            setTimeOut(time);
        }
    }, [sidebarOpen])

    const logo = useMemo(() => {
        return <img
            onClick={() => setSidebarOpen(prevState => !prevState)}
            style={{height: "40px"}}
            // src="https://gaspais.com.co/wp-content/uploads/2020/08/LOGO-GAS-PAIS.png"
            src={config?.sbx_crm?.sbx_crm_image ?? "" ?? "../../../public/assets/images/logo.png"}
            alt=""/>

    }, [setSidebarOpen, config])

    return (
        <div className="app-header header-shadow" style={navBarStyles?.background?.styles ?? {}}>
            <div className="app-header__logo">
                <div
                    style={{background: `url("${config?.sbx_crm?.sbx_crm_image ?? "../../../public/assets/images/logo.png"}") no-repeat center`}}
                    onClick={() => setSidebarOpen(prevState => !prevState)}
                    className={`logo-src`}/>

                <div className="header__pane ms-auto">
                    <div>
                        <button
                            onClick={() => setSidebarOpen(prevState => !prevState)}
                            className={`hamburger close-sidebar-btn hamburger--elastic`}>
              <span className="hamburger-box">

                  <HamburgerInner className="hamburger-inner" backgroundColor={navBarStyles?.background?.styles?.color?? ""} />
                {/*<span className="hamburger-inner" style={navBarStyles?.background?.styles?.color ? {background: `${navBarStyles?.background?.styles.color}`} : {}}/>*/}
              </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button onClick={() => setSidebarOpen(prevState => !prevState)}
                            className={`hamburger hamburger--elastic mobile-toggle-nav ${sidebarOpen ? "is-active" : ""}`} style={{color: navBarStyles?.background?.styles?.color?? ""}}>
            <span className="hamburger-box">
              <HamburgerInner className="hamburger-inner" backgroundColor={navBarStyles?.background?.styles?.color?? ""} />
            </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
        <span>
          <button
              onClick={() => setMobileOpen(!mobileOpen)}
              type="button"
              className={"btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav " + (mobileOpen ? "active" : "")}>
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"/>
            </span>
          </button>
        </span>
            </div>
            <div className={"app-header__content " + (mobileOpen ? "header-mobile-open" : "")}>
                <div className="app-header-left">
                    <SearchBox backgroundColor={navBarStyles?.background?.styles?.color}/>
                    {!isMobile && !sidebarOpen && (!navBarStyles?.logo?.position || navBarStyles?.logo?.position === "left") && (
                        <div className="ms-3 d-lg-block d-none">
                            {logo}
                        </div>
                    )}
                </div>
                {
                    navBarStyles?.logo?.position === "center" && <div className="ms-3 d-flex justify-content-center flex-grow-1">
                        {logo}
                        </div>
                }
                <div className="app-header-right">

                    {navBarStyles?.logo?.position === "right" &&
                        logo
                    }

                    <LanguageMenuComponent backgroundColor={navBarStyles?.background?.styles?.color}/>
                    <LogoutCard fullName={`${login_as_id ? "Logged-in as: " : ""}` + full_name} email={email}/>
                </div>
            </div>
        </div>
    )
}

export default NavBarComponent;
