import { FieldType, SubType } from "./FieldType";
import { ListProvider } from "./Task";
import { SortType } from "../utils";

export enum SelectSubType {
  "SEARCHEABLE" = "SEARCHEABLE",
}

export class Field {
  id!: number;
  label!: string;
  name!: string;
  hint!: null | string;
  required!: boolean;
  single_value!: boolean;
  default_value!: string;
  form_id!: number;
  size!: number;
  read_only!: boolean;
  max_length!: number;
  linked_to!: null | string;
  placeholder!: null | string;
  detail_form_id!: null | number;
  visible_when!: null | string;
  format_rules_definition!: FieldFormatRules;
  style_rules_definition!: null | string | StyleRulesDefinition;
  list_provider!: null | ListProvider;
  field_type!: FieldType;
  sub_type?: SubType;
  list_provider_id!: null | number;
  sort_index!: number;
  parent!: string;
}

export interface StyleRulesDefinition {
  alignment: "h" | "v";
}

export interface ColumnsLabels {
  name: string;
  label: string;
  type?: string;
  compound_name?: string;
  value: string[];
  required?: boolean;
  list_provider?: string;
  read_only?: boolean;
  sub_type?: string;
  key_from_value: boolean;
  calculated?: boolean;
  default?: string;
  reference?: string;
  format_rules?: FieldFormatRules;
}

export interface FormatRules {
  format_rules?: FieldFormatRules;
}

export enum ExpandableConfig {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  DESKTOP_ONLY = "DESKTOP_ONLY",
  MOBILE_ONLY = "MOBILE_ONLY",
  ALWAYS = "ALWAYS",
}

export enum ExpandableMode {
  ALWAYS_COLLAPSED = "ALWAYS_COLLAPSED",
  COLLAPSED_ON_MOBILE = "COLLAPSED_ON_MOBILE",
  COLLAPSED_ON_DESKTOP = "COLLAPSED_ON_DESKTOP",
  ALWAYS_EXPANDED = "ALWAYS_EXPANDED",
  EXPANDED_ON_MOBILE = "EXPANDED_ON_MOBILE",
  EXPANDED_ON_DESKTOP = "EXPANDED_ON_DESKTOP",
}

export interface FieldFormatRules {
  columns_labels: ColumnsLabels[];
  dependencies: string[];
  fields: {
    sort_index: number;
    field_id: number;
    field_name: string;
  }[];
  supplier_filtering_rules?: string;
  default_value?: string;
  section_config?: {
    expandable?: ExpandableConfig;
    expandable_mode?: ExpandableMode;
  };
  default_value_conditions?: string[];
  condition_order_by?: string[];
  sort_type?: SortType;
  sort?: string;
  search_by?: string[]; // string array by columns to search
  search_by_type?: { [key: string]: string }; //"search_by_type": {"id": "number","address": "text"}
  file_type?: string[]; // ['pdf', 'png']
  searchable_limit?: boolean;
  appointment_props?: {
    title?: string;
    disabled_days?: number[];
    type?: "crm_user" | "account";
    responsible?: string;
    account_list?: (string | number)[];
    columns_labels?: string[];
    data?: Record<
      string,
      Record<"value" | "label" | "key" | "only_selected", string>
    >;
  };
  full_container?: boolean;
  n_to_be_completed?: number | "all";
  table_type: "inline";
  table_row_accessor_key?: string; // To replace use  _KEY to get row default values by other value ex; "table_row_accessor_key: 'accesorio_selected";
  no_allow_delete_rows_on_edit: boolean;
  no_allow_update_rows_on_edit: boolean;
  no_allow_new_rows_on_edit: boolean;
  max_row: number;
  hide: true;
  render_type?: "field" | "radio_button" | "switch";
  sub_type?: SelectSubType.SEARCHEABLE | "SELECT";
  indicative?: string;
  hide_indicative?: boolean;
  default?: string | number;
  date_since_now?: boolean;
  blocked_from_date?: string;
  blocked_date?: {
    before_date?: string; // '2021-12-31'
    after_date?: string; // '2021-12-31'
  };
  compressFiles?: boolean;
  compressFilesQuality?: number;
  placeholder?: string;
  placeholder_not_found?: string;
  placeholder_input?: string;
  required?: string; // Campo 1 === 'hola'
  remove_table_props?: string[];
  custom_value?: {
    [key: string]: string | number | boolean;
  };
  color_by_value?: {
    [column: string]: {
      background?: {
        [value: string]: string;
      };
      text_color?: {
        [value: string]: string;
      };
    };
  };
  clear_form_values?: string[];
  table_edit_button_label?: string;
  table_delete_button_label?: string
  table_field_validation_rule?: TableFieldValidationRule;
  required_rules?: {
    required_sequencies?: number[]
  }
}

export interface TableFieldValidationRule {
  field_name?: string
  field_label?: string
  column_operation: "sum" | "multiplication"
  comparison_operator:
      | "greater_than"            // >
      | "greater_than_or_equal"   // >=
      | "less_than"               // <
      | "less_than_or_equal"      // <=
      | "equal_to"               // ==
      | "not_equal_to"           // !=
      | "between"                // Entre dos valores
      | "not_between";           // Fuera del rango de dos valores
  target_value: number | [number, number]; // Número único o rango
  error_message?: string; // Mensaje de error opcional
}