/* eslint no-eval: 0 */
import {format} from "date-fns";
import {ListProvider, ProcessData, Task, TaskProcess, TaskState,} from "../types/Task";
import cogoToast from "cogo-toast";
import {FieldType, SubType} from "../types/FieldType";
import IField from "../types/FormBuilder/IField";
import {ColumnsLabels, Field, TableFieldValidationRule} from "../types/Field";
import {Permissions, PermissionsData} from "../types/Permissions";
import {EventProcess, GatewayProcess, ProcessModel, Sequence,} from "../types/models/processModel/Process";
import {AccountCustomTab, ApplyToSalesManager, SalesManagerConfig, SalesOrgConfig, UserData,} from "../types/User";
import * as Sentry from "@sentry/nextjs";
import {Chart} from "../types/Chart";
import Router from "next/router";
import pdfIcon from "../public/assets/icons/pdf-file.png";
import excelIcon from "../public/assets/icons/excel.png";
import jpgIcon from "../public/assets/icons/jpg.png";
import pngIcon from "../public/assets/icons/file-png.png";
import powerPointIcon from "../public/assets/icons/powerpoint.png";
import wordIcon from "../public/assets/icons/word.png";
import documentIcon from "../public/assets/icons/document.png";
import * as XLSX from "xlsx";

// @ts-ignore
import cookieCutter from "cookie-cutter";
import moment from "moment";
import {Andor, Condition, GroupCondition, SbxConditionType, SbxResponse,} from "../types/Sbx";
import {cloudScriptRun, findByModel, getSbxModelFields,} from "../services/backend/SbxService";
import {
  ActionFilterOperator,
  Alias,
  AnalyticQuery,
  AnalyticQueryAction,
  BaseAnalyticQuery,
  FilterReport,
  Report,
  ReportMetadata,
  Source,
  SourceFilter,
  TruncateReport,
} from "../types/Analytic";
import {getParseToken} from "../services/UtilsService";
import {TableFormColumn} from "../components/TaskComponent/TableForm/TableTaskComponent";
import {getProviderById, getProviderByIdWidthOptions,} from "../services/backend/DataProviderService";
import {AnyData} from "../types/AnyData";
import {Item} from "../components/LayoutComponenents/BadgeComponent";
import {FilterTableReport} from "../components/Shared/FilterTableDataComponent/FilterTableDataComponent";
import {Column} from "../components/Shared/CustomTableComponent/CustomTableComponent";
import {downloadFile} from "../services/backend/ContentService";
import {Response} from "../types/Response";
import {conditions} from "../components/RuleGenerator/Types";
import EventType from "../types/Workflow/EventType";
import {
  executeAnalyticJson,
  executeReloadReportQuery,
  executeReportQuery,
  getAnalyticJsonColumns,
} from "../services/backend/AnalyticsService";
import {Query} from "../components/Shared/QueryComponent/QueryComponent";
import {Theme} from "react-autosuggest";
import {BusinessDay} from "../store/Config/Slice";
import {UpdateSource} from "../components/ReportGeneratorComponent/SourceComponent/SourceComponent";
import {ProviderType} from "../types/ProviderType";
import {Find} from "sbxcorejs";
import store from "../store";
import {Content} from "../types/Folder/Content";
import {Appointment} from "../types/Appointment";
import {UseFormGetValues} from "react-hook-form";
import {ChartData} from "chart.js";
import {Account} from "../types/Account";
import {MultiReport} from "../components/analytics/AnalyticsPageComponent";
import {checkDynamicTruncate, getColumnsReport} from "./analyticsUtils";
import {ParsedUrlQuery} from "node:querystring";
import * as React from "react";

export const DEFAULT_SIZE = 15;

export function sliceObjects(
  array: any[],
  pageSize: number,
  currentPage: number,
) {
  return array.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
}

export function uuidV4(): string {
  return `${makeId()}-${makeId()}-${makeId()}-${makeId()}`;
}

export function makeId() {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export const checkMail = (mail: string) => {
  return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export function megabyteToByte(value?: number) {
  return 1024 * 1024 * (value || 10);
}

export function getDateFromValue(date?: string | null) {
  try {
    if (date) {
      return new Date(date);
    }
    throw Error();
  } catch (e) {
    return undefined;
  }
}

export function getType(type: SubType) {
  switch (SubType[type]) {
    case SubType.EMAIL:
      return "email";

    case SubType.PASSWORD:
      return "password";

    case SubType.NUMBER:
      return "number";

    case SubType.TIME:
      return "time";

    default:
      return "text";
  }
}

export const getHtmlFromEditor = async (refEditor: any) => {
  return new Promise<string>((resolve) => {
    refEditor.current.editor.exportHtml((dataEditor: any) => {
      resolve(dataEditor.html);
    });
  });
};

export const saveDesignFromEditor = async (refEditor: any) => {
  return new Promise<string>((resolve) => {
    refEditor.current.editor.saveDesign((dataEditor: any) => {
      resolve(dataEditor);
    });
  });
};

export function stopPropagation(event: any) {
  event.stopPropagation();
}

export function getFieldName(field: Field, complete?: boolean) {
  let name = `${field.name}`
    .replace(new RegExp(`${field.form_id}_`, "g"), "")
    .replace(new RegExp(`_${field.id}`, "g"), "")
    .split("_")
    .join(" ");
  return complete
    ? `${
        (field.form_id ? `${field.form_id}_` : "") +
        name +
        (field.id ? `_${field.id}` : "")
      }`
        .split(" ")
        .map((str) => str)
        .join("_")
    : name;
}

export function setNameToField(field: IField) {
  let newField = Object.assign({}, field);
  newField.name = getFieldName(field, true);
  return newField;
}

export const getDefaultValue = (field: IField) => {
  switch (field.field_type) {
    case "OPTIONS":
    case "DATE":
      return null;
    case "DATE_RANGE":
      return { startDate: null, endDate: null };
    default:
      return "";
  }
};

export const getRandomColor = () => {
  return `#${Math.random().toString(16).substr(-6)}`;
};

export const getInitialLetter = (name: string) => {
  return name?.charAt(0)?.toUpperCase() ?? "";
};

export const formatDate = (date: string) => {
  return new Date(date);
};

export const convertDateToYYYYMMMDD = (
  date: string | number,
  locale = "en",
) => {
  if (typeof date === "number") date.toString();
  try {
    const date1 = new Date(date);
    return format(date1, "yyyy-MMM-dd");
  } catch (e) {
    return "";
  }
};

export const convertDateCustom = (date: Date, formatter = "yyyy-MMM-dd") => {
  try {
    return format(date, formatter);
  } catch (e) {
    return date;
  }
};

export function getFormatDate(
  date: Date | null | undefined,
  formatDate: "yyyyMMdd" | string,
) {
  try {
    return date ? format(date, formatDate) : null;
  } catch (e) {
    return null;
  }
}

export const convertDateToNumberDate = (date: Date, locale = "en") => {
  // if (typeof date === 'number') date.toString();
  try {
    return format(date, "yyyyMMdd");
    // return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return "";
  }
};

export const convertDateToYYYYMMDD = (date: Date) => {
  try {
    return format(date, "yyyy-MM-dd");
  } catch (e) {
    return "";
  }
};

export const convertDateToYYYYMM = (date: Date) => {
  try {
    return format(date, "yyyy-MM");
  } catch (e) {
    return "";
  }
};

export const convertDateToYYYYMMDDHHmm = (
  date: Date,
  formatDate = "yyyy-MM-dd HH:mm",
) => {
  return format(date, formatDate);
};

export const formatDateFunc = (date: Date, formatDate = "yyyy-MM-dd HH:mm") => {
  return format(date, formatDate);
};

export function getFormatNumber(date: Date) {
  const h = date.getHours(),
    m = date.getHours();

  function formatT(n: number) {
    return n >= 10 ? n.toString() : `0${n}`;
  }

  return `${formatT(h)}:${formatT(m)}`;
}

export function convertNumberAndTimeToDate(date: number, time: string) {
  const newDate = convertNumberDateToDate(date);
  const [hour, minutes] = time.split(":");
  newDate.setHours(parseInt(hour));
  newDate.setMinutes(parseInt(minutes));
  return newDate;
}

export const convertDateToYYYYMMDDHHmmaa = (date: any) => {
  return format(date, "dd MMM yyyy HH:mm a");
};

export const convertDateToDDMMMYYYYHHmm = (date: any) => {
  try {
    return format(date, "dd MMM yyyy p");
  } catch (e) {
    return "";
  }
};

export const convertDateToDDMMMYYYY = (date: Date) => {
  try {
    return format(date, "dd MMM yyyy");
  } catch (e) {
    return date.toString();
  }
};

export function deleteAllCookies() {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      if (reader.result) {
        const base64String = reader.result.toString().split(",")[1];
        resolve(base64String);
      } else {
        reject(new Error("Failed to convert Blob to base64"));
      }
    };
    reader.onerror = () => {
      reject(new Error("Error reading Blob as base64"));
    };
  });
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

export const getTaskState = (taskState: string, stateList: TaskState[]) => {
  return (
    taskState ?? stateList?.find((state) => state.start)?.name ?? "Por hacer"
  );
};

type ToastProps = {
  type?: "success" | "info" | "loading" | "warn" | "error";
  message: string;
  options?: {
    position?:
      | "top-left"
      | "top-center"
      | "top-right"
      | "bottom-left"
      | "bottom-center"
      | "bottom-right";
    heading?: string;
  };
};

export const toast = ({
  type = "success",
  message = "",
  options,
}: ToastProps) => {
  cogoToast[type](message, {
    ...options,
    position: options?.position ?? "bottom-left",
  });
};

export const getUnderscoreLabel = (label: string) => {
  label = label.split("_").join(" ");
  const splitStr = label.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};

export const getUserName = (task: Task) => {
  return task.resource_name ?? task.resource_id ?? "";
};

export const getUnderscoreColumn = (column: string, value: string) => {
  return `object_${column}_${value}`;
};

export const getArrowPosition = (index: number) => {
  return index % 2 === 0 ? "10%" : index % 3 === 0 ? "50%" : "90%";
};

export enum InputTypes {
  text = "text",
  number = "number",
  options = "options",
  multi_options = "multi_options",
  textarea = "textarea",
  boolean = "boolean",
}

export interface PropertyMenu {
  label?: string;
  name: string;
  type: InputTypes;
  index?: number;
  key?: string;
  keyOption?: string;
  labelOption?: string;
  isVisible?: (form: { [key: string]: any }) => boolean;
  onChange?: (evt: { label: string; value: string }[]) => {
    name: string;
    value: string;
  };
  onChangeReplace?: (
    menu: PropertyMenu[],
    option: { label: string; value: string },
  ) => PropertyMenu[];
  options?: { label: string; value: string | number }[];
}

export enum ProcessModelItems {
  TASK = "TASK",
  TASK_SCRIPT = "TASK_SCRIPT",
  SEQ = "SEQ",
  EVENT = "EVENT",
}

export const EVENT_MENU: PropertyMenu[] = [
  {
    name: "event_type",
    type: InputTypes.options,
    options: [
      { value: "END", label: "END" },
      { value: "START", label: "START" },
      {
        label: "INTERMEDIATE_WAIT",
        value: "INTERMEDIATE_WAIT",
      },
      { value: "INTERMEDIATE_DATA", label: "INTERMEDIATE_DATA" },
    ],
  },
  { name: "name", type: InputTypes.text },
  { name: "data_store_id", type: InputTypes.options, options: [], index: 0 },
  {
    label: "attachment",
    name: "attachments",
    type: InputTypes.options,
    options: [],
    keyOption: "attachment_id",
    labelOption: "",
    index: 0,
  },
];

export const ACTION_TYPES = [
  { label: "positive", value: 1 },
  { label: "negative", value: 2 },
  { label: "secure", value: 3 },
  { label: "neutral", value: 0 },
];

export const TASK_MENU: PropertyMenu[] = [
  { name: "duration", type: InputTypes.number },
  { name: "name", type: InputTypes.text },
  { name: "label", type: InputTypes.text },
  { name: "description", type: InputTypes.textarea },
  { name: "rule", type: InputTypes.text },
  {
    name: "assigment_type",
    type: InputTypes.options,
    options: [
      { label: "USER", value: "USER" },
      { value: "GROUP", label: "GROUP" },
      {
        value: "COMPLEX",
        label: "COMPLEX",
      },
    ],
    onChangeReplace: (menu, option) => {
      return menu.map((menuI) => {
        if (menuI.name === "rule") {
          if (option.value === "USER") {
            menuI = {
              ...menuI,
              type: InputTypes.multi_options,
              options: [],
              keyOption: "user_list",
              onChange: (evt) => {
                const values = evt.map((event) => event.value);
                return {
                  name: "rule",
                  value: `process["__result"] = {'users':[${values}]}`,
                };
              },
            };
          } else if (option.value === "GROUP") {
            menuI = {
              ...menuI,
              type: InputTypes.multi_options,
              options: [],
              keyOption: "group_list",
              onChange: (evt) => {
                const values = evt.map((event) => event.value);
                return {
                  name: "rule",
                  value: `process["__result"] = {'groups':[${values}]}`,
                };
              },
            };
          } else {
            menuI = { ...menuI, type: InputTypes.text };
          }
        }
        return menuI;
      });
    },
    isVisible: (form) => {
      return form ? form["task_type"] === "USER" : false;
    },
  },
  {
    name: "task_type",
    type: InputTypes.options,
    options: [
      { label: "USER", value: "USER" },
      { value: "SCRIPT", label: "SCRIPT" },
    ],
  },
  {
    name: "activity_type",
    type: InputTypes.options,
    options: [
      { label: "TASK", value: "TASK" },
      { label: "TASK_SCRIPT", value: "TASK_SCRIPT" },
    ],
  },
  {
    label: "attachment",
    name: "attachments",
    type: InputTypes.options,
    options: [],
    keyOption: "attachment_id",
    labelOption: "",
    index: 0,
  },
  {
    name: "finish_assignments",
    type: InputTypes.boolean,
  },
];

export const SEQ_MENU: PropertyMenu[] = [
  { name: "label", type: InputTypes.text },
  { name: "name", type: InputTypes.text },
  { name: "inline", type: InputTypes.boolean },
  {
    label: "action_type",
    name: "action_type",
    type: InputTypes.options,
    options: [
      { label: "0", value: 0 },
      { label: "1", value: 1 },
      { value: "2", label: "2" },
    ],
  },
];

export const getNode: any = (processModel: ProcessModel, id: string) => {
  if (processModel) {
    return {
      TASK: processModel.tasks.find(
        (task) =>
          (task.id && task.id.toString() === id) || task.id_manager === id,
      ),
      TASK_SCRIPT: processModel.tasks.find(
        (task) =>
          (task.id && task.id.toString() === id) || task.id_manager === id,
      ),
      EVENT: processModel.events.find(
        (event) =>
          (event.id && event.id.toString() === id) || event.id_manager === id,
      ),
      SEQ: processModel.sequences.find(
        (sequence) =>
          (sequence.id && sequence.id.toString() === id) ||
          sequence.id_manager === id,
      ),
    };
  } else {
    return {};
  }
};

export const updateNodeProcessModel = ({
  processModel,
  id,
  node,
  type,
}: {
  processModel: ProcessModel;
  id: number | string;
  node: any;
  type: string;
}) => {
  if (processModel) {
    switch (type) {
      case "TASK": {
        let arr = [...processModel?.tasks];
        const isNew = !arr.some(
          (task) =>
            (task.id && task.id.toString() === id) || task.id_manager === id,
        );
        if (isNew) {
          arr.push({
            ...node,
            task_type: node.task_type ?? "USER",
            activity_type: node.activity_type ?? "TASK",
          });
        } else {
          arr = arr.map((task) => {
            if (
              (task.id && task.id.toString() === id) ||
              task.id_manager === id
            ) {
              task = { ...task, ...node, manager_style: "{}" };
            }
            return task;
          });
        }
        return { ...processModel, tasks: arr };
      }

      case "TASK_SCRIPT": {
        let arr = [...processModel?.tasks];
        const isNew = !arr.some(
          (task) =>
            (task.id && task.id.toString() === id) || task.id_manager === id,
        );
        if (isNew) {
          arr.push({
            ...node,
            task_type: node.task_type ?? "USER",
            activity_type: node.activity_type ?? "TASK",
          });
        } else {
          arr = arr.map((task) => {
            if (
              (task.id && task.id.toString() === id) ||
              task.id_manager === id
            ) {
              task = { ...task, ...node, manager_style: "{}" };
            }
            return task;
          });
        }
        return { ...processModel, tasks: arr };
      }

      case "EVENT": {
        let arr = [...processModel?.events];
        const isNew = !arr.some(
          (event) =>
            (event.id && event.id.toString() === id) || event.id_manager === id,
        );

        if (isNew) {
          arr.push(node);
        } else {
          arr = arr.map((event) => {
            if (
              (event.id && event.id.toString() === id) ||
              event.id_manager === id
            ) {
              event = { ...event, ...node, manager_style: "{}" };
            }

            return event;
          });
        }

        return { ...processModel, events: arr };
      }
      case "SEQ": {
        let arr = [...processModel?.sequences];
        const isNew = !arr.some(
          (sequence) =>
            (sequence.id && sequence.id.toString() === id) ||
            sequence.id_manager === id,
        );

        if (isNew) {
          arr.push(node);
        } else {
          arr = arr.map((sequence) => {
            if (
              (sequence.id && sequence.id.toString() === id) ||
              sequence.id_manager === id
            ) {
              sequence = { ...sequence, ...node, manager_style: "{}" };
            }

            return sequence;
          });
        }

        return { ...processModel, sequences: arr };
      }
      default:
        return null;
    }
  }

  return null;
};

export type EVENT_TYPE =
  | "START"
  | "END"
  | "INTERMEDIATE_WAIT"
  | "INTERMEDIATE_DATA";

export const getEventType = (type: string) => {
  const typeShape = type.toLowerCase().split(":")[1];
  type = typeShape ?? type;

  switch (type) {
    case "startEvent":
      return "START";
    case "startevent":
      return "START";
    case "endEvent":
      return "END";
    case "endevent":
      return "END";
    case "intermediatethrowevent":
      return "INTERMEDIATE_WAIT";
    case "intermediateThrowEvent":
      return "INTERMEDIATE_WAIT";
    default:
      return type;
  }
};

export const getVariableDefaultValue = (defaultValue: string) => {
  return defaultValue.replace(/{*}*\$*/g, "");
};

export const isVarExpression = (defaultValue: string) => {
  return (
    defaultValue.includes("+") ||
    defaultValue.includes("-") ||
    defaultValue.includes("*") ||
    defaultValue.includes("/")
  );
};

export const isTableOperation = (defaultValue: string) => {
  return defaultValue.includes("count") || defaultValue.includes("sum");
};

export type tableOperation = "count" | "sum";

export const tableOperationAction = ({
  action,
  value,
}: {
  action: tableOperation;
  value: number;
}) => {
  return {
    count: 1,
    sum: value,
  }[action];
};

export const calculateExpression = (expression: string) => {
  try {
    return Function('"use strict";return (' + expression + ")")();
  } catch (e) {
    return null;
  }
};

export const evalExpression = (expression: string) => {
  const result = calculateExpression(expression);

  if (
    (result ||
      result === 0 ||
      typeof result === "boolean" ||
      result === false) &&
    !isNaN(result)
  ) {
    return calculateExpression(expression);
  }

  return "";
};

export function downloadTextToFile(
  text: string,
  type: "html" | "txt" | "csv" | string,
  name?: string,
) {
  let makeTextFile = function (text: string) {
    const data = new Blob([text], { type: "text/" + type });
    return window.URL.createObjectURL(data);
  };
  const url = makeTextFile(text);
  let a = document.createElement("a");
  a.href = url;
  a.download = name || "unassigned" + `.${type}`;
  a.click();
}

function getIteratorToTable(type: number, name: string) {
  switch (type) {
    case 1:
      return `<table class="table">
                    <thead>
                     <th style=" border-bottom: 1px solid #ddd; padding: 10px">${capitalize(
                       name,
                     )}</th>
                    </thead>
                    <tbody>
                     <% data.${name}.forEach(function(item) { %>
                      <tr>
                       <td style=" border-bottom: 1px solid #ddd; padding: 10px"><%= item %></td>
                      </tr>
                     <%}); %>
                    </tbody>
                </table>`;
    case 2:
      return `<table class="table">
                    <thead>
                     <tr>
                     <% Object.keys(data.${name}[0]).forEach(function(key) { %>
                       <th style=" border-bottom: 1px solid #ddd; padding: 10px; min-width: 120px"><%= key %></th>
                     <%}); %>
                      </tr>
                    </thead>
                    <tbody>
                     <% data.${name}.forEach(function(item) { %>
                      <tr>
                      <% Object.keys(data.${name}[0]).forEach(function(key) { %>
                       <td style=" border-bottom: 1px solid #ddd; padding: 10px">
                       <% if (item[key]) { %>
                        <%= item[key] %>
                       <% } %>
                       </td>
                       <%}); %>
                      </tr>
                      <%}); %>
                    </tbody>
                </table>`;
  }
}

export function replaceValues(html: string) {
  html = html
    .replace(RegExp("&lt;", "g"), "<")
    .replace(RegExp("&gt;", "g"), ">");

  try {
    //table iterator
    if (html.includes("@table")) {
      console.log("transform table to iterator.");
      const tables: { toReplace: string; iterator: string }[] = html
        .split("@table")
        .reduce((a: any[], b: string, i) => {
          //Ignoring first position
          if (i) {
            const label = b.split(");").shift();
            if (label) {
              const name = label.split(".").pop();
              if (name) {
                a.push({
                  toReplace: `@table${label});`,
                  iterator: getIteratorToTable(
                    label.includes("All") ? 2 : 1,
                    name,
                  ),
                });
              }
            }
          }
          return a;
        }, []);
      tables.forEach((e) => {
        html = html.replace(e.toReplace, e.iterator);
      });
    }
  } catch (e) {
    console.error(e);
  }
  return html;
}

export function capitalize(str: string = "", config?: { lowerCase?: boolean }) {
  if (str) {
    if (config?.lowerCase)
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
}

export function splitJoinText(text: string, props?: AnyData) {
  return text.split("_").join(" ");
}

export const redirect404 = (destination = "/404") => {
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
};

export const removeDuplicateFromArrayObj = (arr: any[], key: string) => {
  const seen = new Set();
  return (arr ?? []).filter((element) => {
    const duplicate = seen.has(element[key]);
    seen.add(element[key]);
    return !duplicate;
  });
};

export const removeDuplicateFromArrayDeepObj = (arr: any[], key: string) => {
  const seen = new Set();

  return arr.filter((element) => {
    const duplicate = seen.has(getObjValueInDeep(element, key));
    seen.add(getObjValueInDeep(element, key));
    return !duplicate;
  });
};

export const getModulesWithPermissions = (permissions: PermissionsData[]) => {
  const m = permissions.reduce((obj: any, data) => {
    if (!obj[data.module_name]) {
      obj[data.module_name] = [];
    }

    //validate if the permission already exist in the list
    if (!obj[data.module_name].find((e: any) => e.id === data.id)) {
      obj[data.module_name].push(data);
    }

    return obj;
  }, {});

  return Object.keys(m).map((e) => ({ module_name: e, permissions: m[e] }));
};

export const permissionListToMap = (permissions: PermissionsData[]) => {
  let permissionsMapper = permissions.reduce(
    (
      object: {
        [key: string]: Permissions;
      },
      data: PermissionsData,
    ) => {
      if (!object[data.module_name]) {
        object[data.module_name] = data.module_name as Permissions;
      }
      const per = `${data.module_name}_${data.permission}` as Permissions;
      object[per] = per;
      return object;
    },
    {},
  );
  permissionsMapper[Permissions.NO_VALIDATE] = Permissions.NO_VALIDATE;
  return { permissionsMapper };
};

export function isObject(data?: any): boolean {
  return typeof data === "object";
}

export function toMap<T>(array: T[], key: string, only?: string) {
  return array.reduce((obj: Record<string, T>, row: T) => {
    const _keyValue = getDataFromKey(row, key);
    if (only) {
      obj[_keyValue] = getDataFromKey(row, only) as T;
    } else {
      obj[_keyValue] = row;
    }
    return obj;
  }, {});
}

export function toMapAccum<T>(
  array: T[],
  key: string,
  config?: { unique?: { key: string } },
) {
  return array.reduce((obj: { [key: string]: T[] }, row: T) => {
    const _keyValue = getDataFromKey(row, key);
    if (!obj[_keyValue]) {
      obj[_keyValue] = [];
    }
    if (config?.unique?.key) {
      const exist = obj[_keyValue].find(
        (r) =>
          getDataFromKey(row, config.unique?.key ?? "") ===
          getDataFromKey(r, config.unique?.key ?? ""),
      );
      if (!exist) {
        obj[_keyValue].push(row);
      }
    } else {
      obj[_keyValue].push(row);
    }
    return obj;
  }, {});
}

export function toMapAccumMultiple<T>(
  array: T[],
  key: string,
  sort: boolean = false,
) {
  const data = array.reduce(
    (acc, item) => {
      const keys = (item as any)[key].split(",");

      keys.forEach((key: any) => {
        if (acc[key]) {
          (acc[key] as any).push(item);
        } else {
          (acc as any)[key] = [item];
        }
      });

      return acc;
    },
    {} as { [key: string]: T[] },
  );

  if (sort) {
    return Object.keys(data)
      .sort()
      .reduce(
        (acc, key) => {
          acc[key] = data[key];
          return acc;
        },
        {} as { [key: string]: T[] },
      );
  }
  return data;
}

export function getDataFromKey<T>(data: T, key = ""): string {
  try {
    let val: any = data;
    if (val[key]) return val[key];
    key?.split(".")?.forEach((key) => {
      val = val[key];
    });
    return val as string;
  } catch (e) {
    return "";
  }
}

export const grantPermission = (
  permission: Permissions | Permissions[],
  mapper: string | any,
  id?: number,
) => {
  try {
    if (typeof mapper === "string") {
      mapper = JSON.parse(mapper);
    }
    const somePermission = Array.isArray(permission)
      ? permission.find((per) => mapper[per])
      : mapper[permission];

    if (isObject(somePermission)) {
      const metadata = (somePermission as PermissionsData).metadata;
      if (metadata) {
        if (metadata.length) {
          const allAllow = metadata.reduce((allows: number[], meta) => {
            if (meta.includes("allow")) {
              const newMeta = toJSON(meta) as { allow?: number[] | string[] };
              if (newMeta?.allow) {
                newMeta.allow.forEach((key) => {
                  if (typeof key === "number") {
                    allows.push(key);
                  }
                });
              }
            }
            return allows;
          }, []);

          if (allAllow.length && id) {
            return allAllow.includes(id);
          }
        }
      }
    }

    return !!somePermission;
  } catch (e) {
    return false;
  }
};

export function filterArray(array: any[], value: string) {
  return array.filter((gr) => {
    return Object.keys(gr).some(
      (key) => `${gr[key]}`.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );
  });
}

export function filterArrayString(array: string[], value: string) {
  return array.filter((gr) => {
    return gr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  });
}

export function downloadFileUtil(file: File, name: string) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.setAttribute("download", `${name}`);
  a.click();
}

export const localeEn = {
  format: "{reason} at line {line}",
  symbols: {
    colon: "colon", // :
    comma: "comma", // ,  ،  、
    semicolon: "semicolon", // ;
    slash: "slash", // /  relevant for comment syntax support
    backslash: "backslash", // \  relevant for escaping character
    brackets: {
      round: "round brackets", // ( )
      square: "square brackets", // [ ]
      curly: "curly brackets", // { }
      angle: "angle brackets", // < >
    },
    period: "period", // . Also known as full point, full stop, or dot
    quotes: {
      single: "single quote", // '
      double: "double quote", // "
      grave: "grave accent", // ` used on Javascript ES6 Syntax for String Templates
    },
    space: "space", //
    ampersand: "ampersand", // &
    asterisk: "asterisk", // *  relevant for some comment sytanx
    at: "at sign", // @  multiple uses in other coding languages including certain data types
    equals: "equals sign", // =
    hash: "hash", // #
    percent: "percent", // %
    plus: "plus", // +
    minus: "minus", // −
    dash: "dash", // −
    hyphen: "hyphen", // −
    tilde: "tilde", // ~
    underscore: "underscore", // _
    bar: "vertical bar", // |
  },
  types: {
    // ... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
    key: "key",
    value: "value",
    number: "number",
    string: "string",
    primitive: "primitive",
    boolean: "boolean",
    character: "character",
    integer: "integer",
    array: "array",
    float: "float",
  },
  invalidToken: {
    tokenSequence: {
      prohibited:
        "'{firstToken}' token cannot be followed by '{secondToken}' token(s)",
      permitted:
        "'{firstToken}' token can only be followed by '{secondToken}' token(s)",
    },
    termSequence: {
      prohibited: "A {firstTerm} cannot be followed by a {secondTerm}",
      permitted: "A {firstTerm} can only be followed by a {secondTerm}",
    },
    double: "'{token}' token cannot be followed by another '{token}' token",
    useInstead: "'{badToken}' token is not accepted. Use '{goodToken}' instead",
    unexpected: "Unexpected '{token}' token found",
  },
  brace: {
    curly: {
      missingOpen: "Missing '{' open curly brace",
      missingClose: "Open '{' curly brace is missing closing '}' curly brace",
      cannotWrap: "'{token}' token cannot be wrapped in '{}' curly braces",
    },
    square: {
      missingOpen: "Missing '[' open square brace",
      missingClose: "Open '[' square brace is missing closing ']' square brace",
      cannotWrap: "'{token}' token cannot be wrapped in '[]' square braces",
    },
  },
  string: {
    missingOpen: "Missing/invalid opening string '{quote}' token",
    missingClose: "Missing/invalid closing string '{quote}' token",
    mustBeWrappedByQuotes: "Strings must be wrapped by quotes",
    nonAlphanumeric:
      "Non-alphanumeric token '{token}' is not allowed outside string notation",
    unexpectedKey: "Unexpected key found at string position",
  },
  key: {
    numberAndLetterMissingQuotes:
      "Key beginning with number and containing letters must be wrapped by quotes",
    spaceMissingQuotes: "Key containing space must be wrapped by quotes",
    unexpectedString: "Unexpected string found at key position",
  },
  noTrailingOrLeadingComma:
    "Trailing or leading commas in arrays and objects are not permitted",
};

export const setUserSentry = (userData: UserData) => {
  Sentry.setUser({
    email: userData.email,
    user_id: userData.id,
    full_name: userData.full_name,
    is_admin: userData.isAdmin,
  });
};

export function recursiveChart(
  item: Chart,
  {
    dep,
    update,
  }: { dep?: (chart: Chart) => void | any; update?: { field: "user" } },
) {
  function recursive(chart: Chart, dep?: any) {
    if (dep) {
      if (update) {
        chart[update.field] = dep(chart);
      } else {
        dep(chart);
      }
    }
    chart.items.forEach((c) => recursive(c, dep));
  }

  recursive(item, dep);
  return item;
}

export const getObjValueInDeep = (
  obj: { [key: string]: any } | any,
  deepProperties: string,
) => {
  try {
    const properties = deepProperties.split(".");

    properties.forEach((property) => {
      if (
        typeof obj[property] === "number" ||
        obj[property] ||
        obj[property] === 0 ||
        !!obj[property] ||
        typeof obj[property] === "boolean"
      ) {
        obj = obj[property];
      }

      if (obj[property] === null) {
        obj[property] = null;
        obj = obj[property];
      }
    });

    if (typeof obj === "number" || typeof obj === "string") {
      return obj;
    }

    return typeof obj === "object" ? "" : ((obj ?? "") as any);
  } catch (e) {
    return "";
  }
};
export const getArrayValueInDeep = (
  obj: { [key: string]: any },
  deepArrayProperties: string[],
) => {
  try {
    deepArrayProperties.forEach((property) => {
      if (obj[property] === "") {
        obj[property] = "";
      }

      if (obj[property]) {
        obj = obj[property];
      }

      if (obj[property] === null) {
        obj[property] = null;
        obj = obj[property];
      }
    });

    return (obj ?? "") as any;
  } catch (e) {
    return "";
  }
};

export const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0);
};

export const getMonthTwoDigits = (month: number) => {
  return month < 10 ? "0" + month : month;
};

export const routeKey = () =>
  Router.pathname.toString().replace(RegExp("/", "g"), "");

export const saveCookieData = (value: string, key: string) => {
  let history: any = JSON.parse(sessionStorage.getItem(key) ?? "{}");
  history[routeKey()] = value;
  sessionStorage.setItem(key, JSON.stringify(history));
};

export const getCookieData = (key: string) => {
  let history: any = JSON.parse(sessionStorage.getItem(key) ?? "{}");
  return history[routeKey()] ?? "";
};

export const getDifferenceDays = (date1: Date, date2: Date) => {
  try {
    const dateStart = moment(date1); //todays date
    const dateUpdate = moment(date2); // another date
    const days = dateUpdate.diff(dateStart, "days");

    return `${days} ${days > 1 || days === 0 ? "Días" : "Día"}`;
    // return moment.duration(dateStart.diff(dateUpdate)).asDays();
  } catch (e) {
    return "";
  }
};

export const getDifferenceDaysNumber = (date1: Date, date2: Date) => {
  try {
    const dateStart = moment(date1); //todays date
    const dateUpdate = moment(date2); // another date
    return dateUpdate.diff(dateStart, "days");
  } catch (e) {
    return 0;
  }
};

export const thousandFormat = function (num: number | string) {
  return num
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(?=\d*\.?)(\d{3})/g, "$1.")
    .split("")
    .reverse()
    .join("")
    .replace(/^[.]/, "");
};

export const getFetchedResults = (response: SbxResponse, fetch: string[]) => {
  if (
    response?.fetched_results &&
    Object.keys(response.fetched_results)?.length > 0 &&
    fetch?.length > 0
  ) {
    for (let fetched of fetch) {
      let entities = fetched.split(".");
      for (const item of response?.results || []) {
        const nItem: any = item;
        for (const fetched_result in response.fetched_results) {
          // Fetched, get the base reference if exist another one.
          fetched = entities.length > 1 ? entities[0] : fetched;
          if (nItem[fetched] in response.fetched_results[fetched_result]) {
            nItem[fetched] =
              response.fetched_results[fetched_result][nItem[fetched]];
          }
        }
        // Use entities since 2 position, because the first is the base and the rest is the references.
        // Ex: ["customer.company"]
        if (entities.slice(1).length > 0) {
          for (const entity of entities.slice(1)) {
            for (const fetched_result in response.fetched_results) {
              if (
                nItem[fetched] &&
                nItem[fetched].hasOwnProperty(entity) &&
                response.fetched_results.hasOwnProperty(fetched_result) &&
                nItem[fetched][entity] in
                  response.fetched_results[fetched_result]
              ) {
                nItem[fetched][entity] =
                  response.fetched_results[fetched_result][
                    nItem[fetched][entity]
                  ];
              }
            }
          }
        }
      }
    }
  }

  if (response.items && response.results) {
    response.items = [...response.results];
  }
  return response;
};

export const isDefaultVarExpression = (default_value: string) => {
  return (default_value ?? "").toString().includes("${");
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const removeDuplicateFromArray = (array: any[]) => {
  return array.filter((c, index) => {
    return array.indexOf(c) === index;
  });
};

export function convertNumberDateToDate(number_date: number | string) {
  try {
    let string_date = number_date.toString();
    const year = string_date.slice(0, 4);
    const month = string_date.slice(4, 6);
    const day = string_date.slice(6, 8);
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  } catch (e) {
    return new Date();
  }
}

export function convertTimeToFormatHHMM(time: number) {
  console.log(time);
  if (!time || time.toString().length > 4) {
    return "";
  }
  const format = time.toString().split("");
  const mm = format.slice(2, 3);
  const hh = format.slice(0, 1);
  return `${hh}:${mm}`;
}

export function convertHHMMToNumber(value: string) {
  return parseInt(value.split(":").join(""));
}

export const getSbxItemByKey = async ({
  key,
  model,
  fetch,
}: {
  model: string;
  key: string;
  fetch?: string[];
}) => {
  const where = [
    {
      ANDOR: "AND",
      GROUP: [{ ANDOR: "AND", FIELD: "_KEY", VAL: key, OP: "=" }],
    },
  ];

  return await getSbxModelFields({
    provider: {
      name: model,
      query: JSON.stringify({ WHERE: where, FETCH: fetch ?? [] }),
    },
  });
};

export const removeBracketsFromString = (str: string) => {
  return str.replace(/[\])}[{(]/g, "").trim();
};

export const addTokenToAnalyticQuery = (query: BaseAnalyticQuery) => {
  const token = localStorage.getItem("crm_token");

  if (token) {
    if (
      query?.source?.from === "sbx-workflow" ||
      query?.source?.from === "sbx-crm-user"
    ) {
      // query.source.override_auth = getParseToken(token);
      query = {
        ...query,
        source: { ...query.source, override_auth: getParseToken(token) },
      };
    }

    if (query?.source?.sources && query.source.sources.length > 0) {
      query = {
        ...query,
        source: {
          ...query.source,
          sources: query.source.sources.map((source) => {
            if (
              source.from === "sbx-workflow" ||
              source.from === "sbx-crm-user"
            ) {
              // source.override_auth = getParseToken(token);
              source = {
                ...source,
                override_auth: getParseToken(token),
              };
            }

            return source;
          }),
        },
      };
    }

    if (query.actions && query.actions.length > 0) {
      query = {
        ...query,
        actions: query.actions.map((action) => {
          if (action.type === "merge" && action.source) {
            if (
              action.source.from === "sbx-workflow" ||
              action.source.from === "sbx-crm-user"
            ) {
              // action.source.override_auth = getParseToken(token);

              action = {
                ...action,
                source: {
                  ...action.source,
                  override_auth: getParseToken(token),
                },
              };
            }
          }
          return action;
        }),
      };
    }
  }

  return query;
};

export function filterDataV2(arr: any[], value: string) {
  const regex = new RegExp(
    value.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    "i",
  );

  try {
    return arr.filter((data) =>
      Object.values(data).some((val) => {
        if (typeof val === "string") {
          return regex.test(val.toLowerCase());
        } else if (val) {
          try {
            return regex.test(val?.toString()?.toLowerCase());
          } catch (e) {}
        } else if (Array.isArray(val)) {
          return val.some(
            (item) =>
              typeof item === "string" && regex.test(item.toLowerCase()),
          );
        } else if (
          typeof val === "object" &&
          val !== null &&
          Object.values(val).length > 0
        ) {
          const list = Object.values(val).filter((it) => it);

          try {
            return list.some((item) =>
              regex.test(
                typeof item === "string"
                  ? item.toLowerCase()
                  : item?.toString()?.toLowerCase() ?? "",
              ),
            );
          } catch (e) {
            return list.some(
              (item) =>
                typeof item === "string" && regex.test(item.toLowerCase()),
            );
          }
        } else {
          return false;
        }
      }),
    );
  } catch (e) {
    console.error(e);
    return arr;
  }
}

export function filterData(arr: any[], value: string) {
  try {
    return arr.filter((data) =>
      Object.keys(data).some((e) => {
        const type = typeof data[e];

        switch (type) {
          case "object":
            return (JSONtoString(data[e]) ?? "")
              .toLowerCase()
              .includes(value.toLowerCase());

          default:
            return (data[e] ?? "")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
        }
      }),
    );
  } catch (e) {
    console.log(e);
    return arr;
  }
}

export const getDefaultVarsFromStr = (str: string) => {
  return (str ?? "").match(/\${[^}]*}/g);
};

export function getFieldNameFromKeyField(name: string) {
  return name.replace("${", "").replace("}", "");
}

export const getDynamicListProviderQuery = ({
  list_provider,
  getFormValues,
  getValue,
  formState,
  isDefaultQuery,
}: {
  list_provider: ListProvider;
  formState?: { [key: string]: string | number };
  getFormValues?: (value: any) => any;
  getValue?: (value: any) => any;
  isDefaultQuery?: boolean;
}) => {
  const varList = getDefaultVarsFromStr(list_provider.query);

  if (varList && varList.length > 0) {
    let isVarNull = false;

    for (const defaultVar of varList) {
      const defaultValue = getVariableDefaultValue(defaultVar);
      if (
        ((formState && formState[defaultValue]) ||
          (getFormValues && getFormValues(defaultValue)) ||
          (getValue && getValue(defaultValue))) &&
        list_provider
      ) {
        let value: any = null;

        if (getFormValues && getFormValues(defaultValue)) {
          value = getFormValues(defaultValue);
        } else if (formState && formState[defaultValue]) {
          value = formState[defaultValue];
        } else {
          if (getValue && getValue(defaultValue)) {
            value = getValue(defaultValue);
          }
        }

        // const value = formState ? formState[defaultValue] : getFormValue ? getFormValue(defaultValue) : null
        if (value) {
          // special case when is an object by process data {name, value}
          if (value?.value) {
            value = value.value;
          }

          if (IsJsonString(value) && Array.isArray(JSON.parse(value))) {
            value = JSON.parse(value);

            const providerQuery: Query = JSON.parse(list_provider.query);
            // providerQuery[defaultVar as string ] = value

            if (Array.isArray(providerQuery.where)) {
              providerQuery.where = providerQuery.where.map((where) => {
                const group = where.GROUP.map((group) => {
                  if (group.VAL === defaultVar) {
                    group.VAL = value;
                  }
                  return group;
                });
                return { ...where, GROUP: group };
              });
            } else {
              if (
                providerQuery.where &&
                (providerQuery.where as { keys: string })?.keys
              ) {
                if (
                  (providerQuery.where as { keys: string })?.keys === defaultVar
                ) {
                  (providerQuery.where as any).keys = value;
                }
              }
            }

            list_provider.query = JSON.stringify(providerQuery);
          } else if (Array.isArray(value)) {
            // console.log("value", Array.isArray(value))
            // console.log("\n\n\n\n")

            const providerQuery = JSON.parse(list_provider.query);

            if (Array.isArray(providerQuery.where)) {
              providerQuery.where = providerQuery.where.map(
                (where: Condition) => {
                  const group = where.GROUP.map((group) => {
                    if (group.VAL === defaultVar) {
                      group.VAL = value;
                      group.OP = "IN";
                    }
                    return group;
                  });
                  return { ...where, GROUP: group };
                },
              );
            } else {
              if (
                providerQuery.where &&
                (providerQuery.where as { keys: string })?.keys
              ) {
                if (
                  (providerQuery.where as { keys: string })?.keys === defaultVar
                ) {
                  (providerQuery.where as any).keys = value;
                }
              }
            }

            list_provider.query = JSON.stringify(providerQuery);
          } else {
            list_provider = {
              ...list_provider,
              query: list_provider.query.replace(defaultVar, value),
            };
          }
        }
      } else {
        // console.log('defaultVar', defaultVar)
        // console.log('defaultValue', defaultValue)
        if (isDefaultQuery) {
          list_provider = {
            ...list_provider,
            query: list_provider.query.replace(defaultVar, ""),
          };
        } else {
          isVarNull = true;
          break;
        }
      }
    }
    if (isVarNull) {
      return null;
    }

    return list_provider;
  } else {
    return list_provider;
  }
};

export const parseString = (str: string) => {
  return str.replace('"', "");
};

export type ProviderOptionsResponse = {
  success: boolean;
  provider_response?: ListProvider;
  items: any;
  row_model?: string;
  item?: any;
};

export const getProviderOptions = async ({
  list_provider,
  formState,
  header,
  search,
  getFormValue,
  task,
}: {
  list_provider: ListProvider;
  header?: TableFormColumn;
  formState?: { [key: string]: string };
  getFormValue?: (value?: any) => void;
  search?: string;
  task?: Task | null;
}): Promise<
  | ProviderOptionsResponse
  | Response<ListProvider>
  | SbxResponse<ListProvider>
  | null
  | undefined
> => {
  let removeFirstWhere = false;
  let mainQuery = "";
  let defaultQuery = "";
  let isQueryByKeys = false;
  let clouscriptKey = "";
  // debugger

  let whereSearch: any[] = [];

  if (list_provider?.id) {

    if (list_provider.query && IsJsonString(list_provider.query)) {
      const query = JSON.parse(list_provider.query);

      if (query.subtype && query.subtype === "cloudscript") {
        clouscriptKey = query?._KEY ?? "";
      } else {
        if (query && query.where) {
          if (Array.isArray(query.where) && query.where.length > 0) {
            isQueryByKeys = query.where.some((where: Condition) => {
              return where.GROUP.some((group) => {
                return group.OP === "IN" && group.FIELD === "_KEY";
              });
            });
          } else {
            if (query.where.keys) {
              isQueryByKeys = true;
            }
          }
        }
      }
    }

    if (list_provider.provider_type === ProviderType.DATABASE) {
      const responseOptions = await getProviderByIdWidthOptions(
        list_provider.id,
      );
      if (responseOptions.success) {
        return {
          ...responseOptions,
          items: responseOptions.item?.options,
          row_model: header?.column ?? "",
        };
      } else {
        return responseOptions;
      }
    } else {
      if (header?.search_by && header?.search_by?.length > 0 && search) {
        let query = new Find("", 0);
        query = searchByField({
          search_by: header.search_by,
          query,
          search,
          search_by_type: header.search_by_type,
        });
        whereSearch = query.compile().where;

        // console.log('whereSearch', whereSearch)
        // console.log('list_provider', list_provider)
        if (
          IsJsonString(list_provider.query) &&
          list_provider.provider_type === ProviderType.SBX
        ) {
          mainQuery = list_provider.query;
          const query: Query = JSON.parse(list_provider.query);

          const initialWhere = [...query.where];

          query.where = [...query.where, ...whereSearch];

          list_provider.query = JSON.stringify(query);
          const newQuery = JSON.parse(list_provider.query);
          // This condition is to insert the main where condition from provider option service to the rest or conditions
          if (initialWhere && initialWhere.length > 0) {

            removeFirstWhere = true;
            newQuery.where = [...newQuery.where].map((where, index) => {
              if (index === 0) {
                return where;
              }

              return {
                ...where,
                GROUP: [
                  ...where.GROUP,
                  ...(initialWhere.map((where) => where.GROUP).flat() ?? []),
                ],
              };
            });



            list_provider.query = JSON.stringify(newQuery);
          }
        }

        if (
          list_provider.default_query &&
          IsJsonString(list_provider.default_query) &&
          list_provider.provider_type === ProviderType.SBX
        ) {
          defaultQuery = list_provider.default_query;
          const query: Query = JSON.parse(list_provider.default_query);
          const initialWhere = [...query.where];
          query.where = [...query.where, ...whereSearch];
          list_provider.default_query = JSON.stringify(query);
          const newQuery = JSON.parse(list_provider.default_query);
          if (initialWhere && initialWhere.length > 0) {

            removeFirstWhere = true;
            newQuery.where = [...newQuery.where].map((where, index) => {
              if (index === 0) {
                return where;
              }

              return {
                ...where,
                GROUP: [
                  ...where.GROUP,
                  ...(initialWhere.map((where) => where.GROUP).flat() ?? []),
                ],
              };
            });

            list_provider.default_query = JSON.stringify(newQuery);
          }
        }
      }

      const new_list_provider = getDynamicListProviderQuery({
        list_provider,
        formState,
        getFormValues: getFormValue,
      });



      if (new_list_provider) {
        let responseSbx: SbxResponse<ListProvider> | null = null;

        if (
          new_list_provider.provider_type === ProviderType.SBX_SEQUENTIAL_QUERY
        ) {
          if (IsJsonString(new_list_provider.query)) {
            const query: Query = JSON.parse(new_list_provider.query);
            if (mainQuery) {
              mainQuery =
                getDynamicListProviderQuery({
                  list_provider: { query: mainQuery } as ListProvider,
                  formState,
                  getFormValues: getFormValue,
                })?.query ?? "";
            }

            responseSbx = await getParallelQueryProvider({
              query,
              mainQuery: IsJsonString(mainQuery)
                ? JSON.parse(mainQuery)
                : undefined,
              removeFirstWhere,
              provider: new_list_provider,
              header,
              search,
            });


          }
        } else {
          if (header?.search_by && header?.search_by?.length > 0 && search) {
            const query = JSON.parse(new_list_provider.query);
            if (query.where && query.where.length > 0 && removeFirstWhere) {
              query.where = query.where.slice(1);
              new_list_provider.query = JSON.stringify(query);
            }
          }

          if (clouscriptKey) {
            if (task?.id) {
              const form = getFormValue ? getFormValue() : {};

              const params: { [key: string]: any } = {
                task_id: task.id,
                task_execution_id: task.task_execution_id,
                process_execution_id: task.process_execution_id,
                process_data: {
                  ...form,
                  ...formState,
                },
              };
              if (whereSearch && whereSearch.length > 0) {
                params.custom_filters = whereSearch;
              }

              responseSbx = await cloudScriptRun({
                key: clouscriptKey,
                params,
              });
            }
          } else {
            responseSbx = await getSbxModelFields({
              provider: { ...new_list_provider },
              findUrl:
                header?.searchable_limit || isQueryByKeys ? "find" : "find_all",
            });
          }
        }

        if (responseSbx && responseSbx?.success) {
          const row_model =
            header?.column ??
            JSON.parse(new_list_provider.query).row_model ??
            JSON.parse(new_list_provider.query).ROW_MODEL ??
            header?.reference ??
            "";

          if (
            header?.search_by &&
            header?.search_by?.length > 0 &&
            search &&
            header.search_by_type
          ) {
            responseSbx.results = filterListByField({
              search_by: header.search_by,
              items: (responseSbx.results ?? []) as any[],
              search,
              search_by_type: header.search_by_type,
            });
          }
          return {
            ...responseSbx,
            items: responseSbx.results,
            provider_response: list_provider,
            row_model,
          };
        } else {
          return responseSbx;
        }
      } else {
        if (list_provider.default_query) {
          const defaultQueryObj = JSON.parse(list_provider.default_query);
          if (
            defaultQueryObj.subtype &&
            defaultQueryObj.subtype === "cloudscript"
          ) {
            clouscriptKey = defaultQueryObj?._KEY ?? "";
          }

          const varList = getDefaultVarsFromStr(list_provider.default_query);
          let provider: ListProvider | null = { ...list_provider };
          if (varList && varList.length > 0) {
            provider = getDynamicListProviderQuery({
              list_provider: {
                ...list_provider,
                query: list_provider.default_query,
              },
              formState,
              isDefaultQuery: true,
            });
            if (provider) {
              provider.default_query = provider.query;
            } else {
              provider = { ...list_provider };
            }
          }

          if (provider && provider.default_query) {
            provider = {
              ...provider,
              query: provider.default_query,
            };

            let responseSbx: SbxResponse<ListProvider> | null = null;

            if (provider.provider_type === ProviderType.SBX_SEQUENTIAL_QUERY) {
              if (IsJsonString(provider.query)) {
                const query: Query = JSON.parse(provider.query);
                if (defaultQuery) {
                  defaultQuery =
                    getDynamicListProviderQuery({
                      list_provider: { query: defaultQuery } as ListProvider,
                      formState,
                      getFormValues: getFormValue,
                      isDefaultQuery: true,
                    })?.query ?? "";
                }

                responseSbx = await getParallelQueryProvider({
                  query,
                  provider,
                  mainQuery: IsJsonString(defaultQuery)
                    ? JSON.parse(defaultQuery)
                    : undefined,
                  removeFirstWhere,
                  search,
                  header,
                });
              }
            } else {
              if (
                header?.search_by &&
                header?.search_by?.length > 0 &&
                search
              ) {
                const query = JSON.parse(provider.query);
                if (query.where && query.where.length > 0 && removeFirstWhere) {
                  query.where = query.where.slice(1);
                  provider.query = JSON.stringify(query);
                }
              }

              if (clouscriptKey) {
                if (task?.id) {
                  const form = getFormValue ? getFormValue() : {};

                  const params = {
                    task_id: task.id,
                    task_execution_id: task.task_execution_id,
                    process_execution_id: task.process_execution_id,
                    process_data: {
                      ...form,
                      ...formState,
                    },
                  };

                  responseSbx = await cloudScriptRun({
                    key: clouscriptKey,
                    params,
                  });
                }
              } else {
                responseSbx = await getSbxModelFields({
                  findUrl:
                    header?.searchable_limit || isQueryByKeys
                      ? "find"
                      : "find_all",
                  provider,
                });
              }
            }

            if (responseSbx && responseSbx?.success && provider.default_query) {
              const row_model =
                header?.column ??
                JSON.parse(provider.default_query).row_model ??
                JSON.parse(provider.default_query).ROW_MODEL;

              if (
                header?.search_by &&
                header?.search_by?.length > 0 &&
                search &&
                header.search_by_type
              ) {
                responseSbx.results = filterListByField({
                  search_by: header.search_by,
                  items: (responseSbx.results ?? []) as any[],
                  search,
                  search_by_type: header.search_by_type,
                });
              }

              return {
                ...responseSbx,
                items: responseSbx.results,
                provider_response: provider,
                row_model,
              };
            } else {
              return responseSbx;
            }
          } else {
            return { success: true, items: [] };
          }
        }
        return { success: true, items: [] };
      }
    }
  }
};

export const getParallelQueryProvider = async ({
  provider,
  query,
  header,
  mainQuery,
  search,
  removeFirstWhere,
}: {
  query: Query;
  mainQuery: Query;
  provider: ListProvider;
  removeFirstWhere: boolean;
  header?: TableFormColumn;
  search?: string;
}) => {
  let index = 0;
  let stopFetch = false;
  let responseSbx: SbxResponse<ListProvider> | null = null;

  let searchByWhere: Condition[] = [];

  if (header?.search_by && header?.search_by?.length > 0 && search) {
    const query = JSON.parse(provider.query);

    let queryModel = new Find(query.row_model, 0);
    // queryModel.newGroupWithOr()

    queryModel = searchByField({
      query: queryModel,
      search_by: header?.search_by ?? [],
      search_by_type: header?.search_by_type ?? {},
      search: search ?? "",
    });

    searchByWhere = queryModel.compile().where;

    searchByWhere = searchByWhere.map((where) => {
      return {
        ...where,
        GROUP: [...query.where[index].GROUP, ...where.GROUP],
      };
    });
  }

  while (!stopFetch) {
    responseSbx = await getSbxModelFields({
      findUrl: header?.searchable_limit ? "find" : "find_all",
      provider: {
        ...provider,
        query: JSON.stringify({
          ...query,
          where:
            index === 0 && removeFirstWhere
              ? searchByWhere
              : [query.where[index], ...searchByWhere],
        }),
      },
    });

    if (
      !responseSbx?.success ||
      (responseSbx?.success &&
        responseSbx?.results &&
        responseSbx.results?.length > 0)
    ) {
      stopFetch = true;
    }

    if (
      index + 1 <
      (mainQuery?.where ? mainQuery.where.length : query.where.length)
    ) {
      index++;
    } else {
      stopFetch = true;
    }
  }

  return responseSbx;
};

export const getAllDataByProvider = async ({
  provider_id,
  header,
  formState,
  getFormValue,
  search,
  task,
}: {
  provider_id: string;
  header?: TableFormColumn;
  search?: string;
  formState?: { [key: string]: string };
  getFormValue?: (value: any) => void;
  task?: Task | null;
}) => {
  const response = await getProviderById(parseInt(provider_id));

  if (response.success && response.item) {
    const varList = getDefaultVarsFromStr(response.item.query);
    if (varList && varList.length > 0 && !formState) {
      if (response.item.default_query) {
        return await getProviderOptions({
          list_provider: {
            ...response.item,
            query: response.item.default_query,
          },
          header,
          formState,
          task,
          getFormValue,
          search,
        });
      }

      return { success: true, items: [], provider_response: response.item };
    } else {
      const responseProvider = await getProviderOptions({
        list_provider: response.item,
        header,
        formState,
        getFormValue,
        task,
        search,
      });

      return {...responseProvider, provider_response: response.item};
    }
  }
};

export const getColumnValueFromRules = ({
  columns,
  isProvider,
}: {
  isProvider?: boolean;
  columns: ColumnsLabels[];
}) => {
  return isProvider
    ? columns[0].key_from_value
      ? columns[0].value
        ? columns[0].value[0]
        : "_KEY"
      : "_KEY"
    : "_KEY";
};

export const getCompoundName = ({
  columns,
  item,
  custom_compound_name,
}: {
  columns?: ColumnsLabels[];
  item: any;
  custom_compound_name?: string;
}) => {
  if (columns) {
    const { name, compound_name } = columns[0];
    if (compound_name) {
      return getInterpretVar({ item, strVar: compound_name });
    }

    return name && item ? item[name] ?? getObjValueInDeep(item, name) : "";
  } else {
    if (custom_compound_name) {
      return getInterpretVar({ item, strVar: custom_compound_name });
    }
  }

  return "";
};

export const getInterpretVar = ({
  strVar,
  item,
  inDeep = true,
}: {
  item: any;
  strVar: string;
  inDeep?: boolean;
}) => {
  const varList = getDefaultVarsFromStr(strVar);
  if (varList && varList.length > 0) {
    let label = strVar;

    varList.forEach((strVar) => {
      let nameVar = getVariableDefaultValue(strVar);
      let value: string | number | null = null;

      let isLeading = false;
      let isTrim = false;

      if (nameVar.includes("__leading__")) {
        nameVar = nameVar.replace("__leading__", "");
        isLeading = true;
      }

      if (nameVar.includes("__trim__")) {
        nameVar = nameVar.replace("__trim__", "");
        isTrim = true;
      }

      value = inDeep ? getObjValueInDeep(item, nameVar) : item[nameVar];

      if (isLeading) {
        if (typeof value === "string") {
          try {
            value = parseInt(value);
          } catch (e) {}
        }
      }

      if (isTrim) {
        if (typeof value === "string") {
          value = value.trim();
        }
      }

      if ((value || value === "" || value === 0) && label.includes(strVar)) {
        label = label.replace(strVar, value as string);
      }
    });

    return label;
  }

  return "";
};

export const cleanInterpretVar = ({
  strVar,
  item,
  inDeep = true,
}: {
  item: any;
  strVar: string;
  inDeep?: boolean;
}) => {
  const varList = getDefaultVarsFromStr(strVar);
  if (varList && varList.length > 0) {
    let label = strVar;
    varList.forEach((strVar) => {
      const nameVar = getVariableDefaultValue(strVar);
      const value = inDeep ? getObjValueInDeep(item, nameVar) : item[nameVar];
      if (!value && label.includes(strVar)) {
        label = label.replace(strVar, "");
      }
    });
    return label;
  }

  return "";
};

export const sortIntFun = ({
  a,
  b,
  type,
}: {
  a: any;
  b: any;
  type: "ASC" | "DESC";
}) => {
  if (type === SortType.ASC) {
    return a > b ? 1 : -1;
  } else {
    return a > b ? -1 : 1;
  }
};

export const sortStringFun = ({
  a,
  b,
  type,
}: {
  a: any;
  b: any;
  type: "ASC" | "DESC";
}) => {
  if (type === SortType.ASC) {
    return a.localeCompare(b, undefined, { numeric: true });
  } else {
    return b.localeCompare(a, undefined, { numeric: true });
  }
};

export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
}

export const getSortedOptionsByRules = ({
  sort_type = SortType.ASC,
  options,
  condition_order_by,
}: {
  options: any[];
  sort_type?: SortType;
  condition_order_by: string[];
}) => {
  if (options && options.length > 0 && condition_order_by) {

    return options.sort((a, b) => {
      for (const order_by of condition_order_by) {
        const valueA = getObjValueInDeep(a, order_by);
        const valueB = getObjValueInDeep(b, order_by);

        if (valueA === valueB) continue; // Si son iguales, seguir con la siguiente propiedad

        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortStringFun({ a: valueA, b: valueB, type: sort_type });
        } else {
          return sortIntFun({ a: valueA, b: valueB, type: sort_type });
        }
      }
      return 0; // Si todas las comparaciones son iguales, mantener el orden original
    });

    // if (typeof getObjValueInDeep(options[0], order_by) === "string") {
    //   console.log('options', options)
    //   return options.sort((a, b) =>
    //     sortStringFun({
    //       a: getObjValueInDeep(a, order_by),
    //       b: getObjValueInDeep(b, order_by),
    //       type: sort_type,
    //     }),
    //   );
    // } else {
    //   return options.sort((a, b) =>
    //     sortIntFun({
    //       a: getObjValueInDeep(a, order_by),
    //       b: getObjValueInDeep(b, order_by),
    //       type: sort_type,
    //     }),
    //   );
    // }
  }

  return options;
};

export function getReferenceSelectOptions({
  options,
  header,
  isProvider,
}: {
  header: TableFormColumn | ColumnsLabels;
  options: any[];
  isProvider?: boolean;
}) {
  const columns = (
    isProvider
      ? header.format_rules?.columns_labels || [header]
      : (header as TableFormColumn).sub_columns
  ) as ColumnsLabels[];

  if (options) {
    if (
      options.length > 0 &&
      header.format_rules &&
      header.format_rules.condition_order_by
    ) {
      options = getSortedOptionsByRules({
        options,
        sort_type: header.format_rules?.sort_type,
        condition_order_by: header.format_rules.condition_order_by,
      });
    }

    if (columns) {
      const { name, compound_name } = columns[0];
      const valueKey = getColumnValueFromRules({ columns, isProvider });

      if (options.length > 0) {
        if (compound_name) {
          const varList = getDefaultVarsFromStr(compound_name);

          if (varList && varList.length > 0) {
            // console.log('options', options)
            return options.map((item) => {
              let label = compound_name;

              varList.forEach((strVar) => {
                const nameVar = getVariableDefaultValue(strVar);
                const value = getObjValueInDeep(item, nameVar);
                if (label.includes(strVar)) {
                  if (value || value === 0) {
                    label = label.replace(strVar, value);
                  } else {
                    label = label.replace(strVar, "");
                  }
                }
              });

              return {
                label,
                value: getObjValueInDeep(item, valueKey),
              };
            });
          }
        }

        if (name) {
          return options
            ?.filter((option) => !!getObjValueInDeep(option, name))
            .sort((a, b) => {
              return getObjValueInDeep(a, name)
                .toString()
                .localeCompare(getObjValueInDeep(b, name).toString());
            })
            .map((res) => {
              return {
                label: getObjValueInDeep(res, name) ?? "",
                value: getObjValueInDeep(res, valueKey),
              };
            });
        }
      }

      return options.map((fieldColumn: { [x: string]: any }) => ({
        label: fieldColumn[name] ?? "",
        value: getObjValueInDeep(fieldColumn, valueKey),
      }));
    } else {
      return options;
    }
  }

  return [];
}

export function getItemsBetweenRange(
  rows: any[],
  range: {
    startDate: null | Date;
    endDate: null | Date;
  },
  field: string,
) {
  return rows.filter((row) => {
    if (range.endDate && range.startDate) {
      return (
        row[field] >= convertDateToNumberDate(range.startDate) &&
        row[field] <= convertDateToNumberDate(range.endDate)
      );
    }
    return true;
  });
}

export const getIconType = (
  name: string,
  t: (data: string) => string,
): Item => {
  const newName = t(`common:${name.toLowerCase()}`);
  switch (name) {
    default:
      return { type: "success", icon: "file-add", label: newName };

    case "EDIT":
      return { type: "secondary", icon: "pencil", label: newName };

    case "READ":
      return { type: "primary", icon: "license", label: newName };

    case "DELETE":
      return { type: "danger", icon: "trash", label: newName };

    case "EXECUTE":
      return { type: "info", icon: "select", label: newName };
  }
};

export const transformObjToNewSingleObj = (
  obj: { [key: string]: any },
  keyValue: string,
) => {
  const newObj: { [key: string]: string } = {};

  Object.keys(obj).forEach((key) => {
    newObj[key] = getObjValueInDeep(obj[key], keyValue);
  });

  return newObj;
};

export function iterator(f: number, u: number) {
  const arr: number[] = [];
  let i = f;
  while (i <= u) arr.push(i++);
  return arr;
}

let time: any;

export function debounceTime(
  func: (params: any) => any,
  params: any,
  timeout: number,
): Promise<any> {
  if (time) clearTimeout(time);
  return new Promise((resolve) => {
    time = setTimeout(async () => {
      const res = await func(params);
      resolve(res);
    }, timeout);
  });
}

export function stringLimit(str: string, limit: number) {
  return str.length > limit ? `${str.slice(0, limit)}...` : str;
}

const addSourceRangeFilter = ({
  rangeField,
  source,
}: {
  rangeField: FilterReport;
  source: Source;
}) => {
  let monthDependency: null | number = null;

  const newSource = { ...source };

  const filters: SourceFilter[] = [];

  if (rangeField.dependency && rangeField.field) {
    let filter = newSource.filters?.find(
      (filter) =>
        filter.field === rangeField.dependency?.split(".").slice(1).join("."),
    );
    if (!filter) {
      const nSource = newSource.sources?.find(
        (source) =>
          source.filters?.find(
            (filter) =>
              filter.field ===
              rangeField.dependency?.split(".").slice(1).join("."),
          ),
      );
      if (nSource) {
        filter = nSource.filters?.find(
          (filter) =>
            filter.field ===
            rangeField.dependency?.split(".").slice(1).join("."),
        );
      }
    }

    if (filter && isValidDate(new Date(filter.value as string))) {
      monthDependency = new Date(filter.value as string).getMonth();
    }
  }

  if (rangeField.field && rangeField.start_date) {
    // console.log("Entra (rangeField.field && rangeField.start_date)")
    // console.log("Value", replaceConstVarWith({month: monthDependency, constVar: rangeField.start_date, date_type: rangeField.date_type}))
    filters.push({
      logic_operator: "and",
      filter_operator: ">=",
      field: rangeField.field.split(".").slice(1).join("."),
      value: replaceConstVarWith({
        month: monthDependency,
        constVar: rangeField.start_date,
        date_type: rangeField.date_type,
      }),
    });
  }

  if (rangeField.field && rangeField.end_date) {
    // console.log("value", replaceConstVarWith({month: monthDependency, constVar: rangeField.end_date, date_type: rangeField.date_type}))
    filters.push({
      logic_operator: "and",
      filter_operator: "<=",
      field: rangeField.field.split(".").slice(1).join("."),
      value: replaceConstVarWith({
        month: monthDependency,
        constVar: rangeField.end_date,
        date_type: rangeField.date_type,
      }),
    });
  }

  // console.log("\n\n\n\n\n\n\n")

  return { ...newSource, filters: newSource.filters?.concat(filters) };
};

export const queryAddSourceRangeFilter = ({
  rangeField,
  query,
}: {
  rangeField: FilterReport;
  query: BaseAnalyticQuery;
}) => {
  // console.log("Range field 1", rangeFiel)

  let newQuery = Object.assign({}, query);
  if (rangeField.field) {
    if (newQuery.source.with === rangeField.field.split(".")[0]) {
      if (
        !newQuery.source.filters?.some(
          (filter) =>
            rangeField.field &&
            filter.field === rangeField.field.split(".").slice(1).join("."),
        )
      ) {
        newQuery = {
          ...newQuery,
          source: addSourceRangeFilter({ source: newQuery.source, rangeField }),
        };
      }
    } else {
      newQuery = {
        ...newQuery,
        source: {
          ...newQuery.source,
          sources: newQuery.source.sources?.map((source) => {
            if (
              rangeField.field &&
              source.with === rangeField.field.split(".")[0]
            ) {
              if (
                !source.filters?.some(
                  (filter) =>
                    rangeField.field &&
                    filter.field ===
                      rangeField.field.split(".").slice(1).join("."),
                )
              ) {
                return addSourceRangeFilter({ source, rangeField });
              }
            }

            return source;
          }),
        },
      };
    }
  }

  return { ...newQuery };
};

export type ResponseReportQuery = {
  data: any[];
  columns?: any[];
  allColumns?: any[];
  docKeys?: { [key: string]: any };
  allColumnsCopy?: any[];
  filters?: { table_name: string; label: string }[];
  name: string;
  remove_empty_columns?: boolean;
  report_in_process?: boolean;
  info: { [key: string]: any };
};

const getExecuteReportService = async ({
  report,
  query,
  reload,
  noCache,
}: {
  query: BaseAnalyticQuery;
  report: Report;
  reload?: boolean;
  noCache?: boolean;
}) => {
  if (reload) {
    return await executeReloadReportQuery(query, report);
  }

  if (noCache) {
    return await executeAnalyticJson(query);
  }

  return await executeReportQuery(query, report);
};

export const getQueryWithTruncate = (
  report: Report,
  query: BaseAnalyticQuery,
  isReportBuilder = false,
) => {
  const newQuery = { ...query };

  if (
    report.truncate &&
    IsJsonString(report.truncate as string) &&
    (JSON.parse(report.truncate as string).model ||
      JSON.parse(report.truncate as string).field)
  ) {
    newQuery.truncate = JSON.parse(report.truncate as string);
  }

  if (
    newQuery.truncate &&
    ((newQuery.truncate as TruncateReport).dynamic_from ||
      (newQuery.truncate as TruncateReport)?.dynamic_range)
  ) {
    newQuery.truncate = checkDynamicTruncate(
      newQuery.truncate as TruncateReport,
    );

    if (newQuery.truncate.others && newQuery.truncate.others.length > 0) {
      newQuery.truncate.others = newQuery.truncate.others.map((truncate) =>
        checkDynamicTruncate(truncate),
      );
    }
  }

  const truncate = newQuery.truncate as TruncateReport;

  const valueTypes = ["reference", "text"];

  if (truncate?.others && truncate.others.length > 0 && !isReportBuilder) {
    (newQuery.truncate as TruncateReport).others = truncate.others.filter(
      (truncate) =>
        valueTypes.some((type) => truncate.type === type)
          ? truncate.value
          : true,
    );
  }

  if (
    truncate &&
    valueTypes.some((type) => truncate.type === type) &&
    !truncate.value &&
    !isReportBuilder
  ) {
    delete newQuery.truncate;
  }

  return newQuery;
};

export const getReportQuery = async ({
  report,
  parentFilters,
  filtersToAdd,
  user,
  reload,
  noCache,
}: {
  report: Report;
  parentFilters?: FilterTableReport[];
  filtersToAdd?: string[];
  user?: UserData;
  reload?: boolean;
  noCache?: boolean;
}) => {
  const convertQuery = checkAnalyticQueryVars(report.query);

  if (report?.query && JSON.parse(convertQuery)) {
    let handleQuery = false;
    let query = addTokenToAnalyticQuery(JSON.parse(convertQuery));
    let columns: Column[] = [];

    if (parentFilters) {
      const result = removeOrAddColumnsByQuery({
        parentFilters,
        query,
        columns,
        filtersToAdd,
      });
      query = result.query;
      handleQuery = result.handleQuery;
    }

    if (report.visible_for && IsJsonString(report.visible_for) && user) {
      const visibleFor = JSON.parse(report.visible_for);
      let filter = "";

      Object.keys(visibleFor).forEach((key, index) => {
        if (user[key]) {
          filter +=
            index > 0
              ? " & ("
              : "" +
                `'${user[key]}' == ${visibleFor[key]} ${index > 0 ? ")" : ""}`;
        }
      });

      query.actions.push({
        type: "filter",
        filter,
      });
    }

    query = getQueryWithTruncate(report, query);

    if (
      query.actions.some((action) => action.type === "merge" && action.truncate)
    ) {
      query.actions.forEach((action) => {
        if (action.type === "merge" && action.truncate) {
          action.truncate = checkDynamicTruncate(
            action.truncate as TruncateReport,
          );
        }
      });
    }

    if (
      report.alias &&
      IsJsonString(report.alias as string) &&
      JSON.parse(report.alias as string).key
    ) {
      query.alias = JSON.parse(report.alias as string);

      if (!query.alias?.hasOwnProperty("update")) {
        (query.alias as Alias).update = false;
      }
    }

    let filters: FilterReport[] = [];

    if (report.filter && JSON.parse(report.filter)?.length > 0) {
      filters = JSON.parse(report.filter);
    }

    if (filters.some((filter) => filter.type === "date_range")) {
      const rangeFields = filters.filter(
        (filter) => filter.type === "date_range",
      );

      rangeFields.forEach((rangeField) => {
        query = queryAddSourceRangeFilter({ rangeField, query });
      });
    }

    const responseJson = await getExecuteReportService({
      report,
      query,
      reload,
      noCache,
    });

    if (
      responseJson?.success &&
      responseJson.items &&
      responseJson.items?.length > 0
    ) {
      const columnsReport = await getColumnsReport(
        report,
        responseJson.items,
        columns,
      );

      return {
        data: columnsReport.data,
        columns: columnsReport.columns,
        allColumns: columnsReport.allColumns,
        filters,
        docKeys: columnsReport.docKeys,
        name: report.name,
        handleQuery,
        info: responseJson?.info ?? {},
        remove_empty_columns: report.remove_empty_columns,
      } as MultiReport;
    } else {
      if (responseJson?.message === "Report in process" || responseJson?.old) {
        const columnsReport = await getColumnsReport(
          report,
          responseJson.old ?? [],
          columns,
        );

        return {
          name: report.name,
          data: columnsReport.data,
          columns: columnsReport.columns,
          allColumns: columnsReport.allColumns,
          report_in_process: true,
          filters,
          handleQuery,
          info: responseJson?.info ?? {},
          remove_empty_columns: report.remove_empty_columns,
        } as ResponseReportQuery;
      } else {
        const response = await getAnalyticJsonColumns(query);

        if (response?.success && response.items) {
          let columns: string[] = response.items as string[];
          if (report.sort && JSON.parse(report.sort)) {
            const sortColumns = JSON.parse(report.sort);
            if (sortColumns && Array.isArray(sortColumns)) {
              columns = columns.filter((column) =>
                sortColumns.includes(column),
              );
            }
          }

          return {
            name: report.name,
            columns: columns.map((column) => ({
              name: column,
              header: column,
            })),
            data: [],
            info: responseJson?.info ?? {},
            remove_empty_columns: false,
            filters,
          };
        }
      }
    }
  }

  return null;
};

export function removeOrAddColumnsByQuery({
  parentFilters,
  query,
  columns,
  filtersToAdd,
}: {
  parentFilters: FilterTableReport[];
  query: BaseAnalyticQuery;
  columns: Column[];
  filtersToAdd?: string[];
}) {
  let noIncludeFilters: string[] = [];
  let handleQuery = false;
  if (parentFilters && columns.length > 0) {
    const columnsName = columns.map((column) => column.name);
    noIncludeFilters = parentFilters
      .filter(
        (parentF) =>
          parentF.table_name && !columnsName.includes(parentF.table_name),
      )
      .map((filter) => filter.table_name ?? "");
  }

  if (noIncludeFilters.length > 0) {
    const group_by_list = query.actions.filter(
      (action) => action.type === "group_by",
    );
    if (group_by_list.length > 0) {
      const last_group_by = group_by_list[group_by_list.length - 1];
      if (last_group_by.columns && last_group_by.columns.length > 0) {
        if (filtersToAdd && filtersToAdd.length > 0) {
          last_group_by.columns = (last_group_by.columns as string[]).filter(
            (column) => {
              if (noIncludeFilters.includes(column)) {
                return filtersToAdd.includes(column);
              } else {
                return true;
              }
            },
          );
        } else {
          last_group_by.columns = (last_group_by.columns as string[]).filter(
            (column) => !noIncludeFilters.includes(column),
          );
        }
        handleQuery = true;
      }
    }
  }

  return { query, handleQuery };
}

export function convertTableRowsToCSVString(
  columns: Column[],
  rows: any[],
): string {
  let text = columns.map((e) => e.header).join(";");
  rows.forEach((row: any) => {
    text +=
      `\n` +
      columns
        .map((c) =>
          jsonLevels(row, `${c.data ?? c.name}${c.value ? `.${c.value}` : ""}`),
        )
        .join(";");
  });
  return text;
}

export function jsonLevels(json: AnyData, field: string) {
  function getValue(value: any) {
    const type = typeof value;
    switch (type) {
      case "object":
        let newVal: any = {};
        if (value) {
          Object.keys(value).forEach((key) => {
            const tt = typeof value[key];
            switch (tt) {
              case "string":
              case "boolean":
              case "number":
              case "undefined":
                newVal[key] = value[key] ?? "";
            }
          });
        }
        return JSON.stringify(newVal ?? "");
      default:
        return value ?? "";
    }
  }

  if (field.includes(".")) {
    let val = json;
    field.split(".").forEach((key) => {
      val = val[key];
    });
    return getValue(val);
  }
  return getValue(json[field]);
}

export const downloadKeyFile = async (fileKey: string) => {
  const response = await downloadFile(fileKey);
  if (response?.success) {
    window.open(response.url);
  }
};

export const promiseLimit = async ({
  arrayPromise,
  limit,
  functionPromise,
  objKey,
  onSuccessBatch,
}: {
  arrayPromise: any[];
  limit: number;
  functionPromise: (params: any) => Promise<any>;
  onSuccessBatch?: (params: {
    batchTotal: number;
    response: any[];
    params: any[];
    batchProcessed: number;
  }) => void;
  objKey?: string;
}) => {
  let responseArray: Response[] = [];

  let initial = 0;
  let end = limit;

  while (end <= arrayPromise.length + 1) {
    const promises = arrayPromise
      .slice(initial, end)
      .map((obj) =>
        objKey
          ? functionPromise(getObjValueInDeep(obj, objKey))
          : functionPromise(obj),
      );
    const response = await Promise.all(promises);

    responseArray = [...responseArray, ...response];

    if (onSuccessBatch) {
      onSuccessBatch({
        response,
        params: arrayPromise
          .slice(initial, end)
          .map((obj) => (objKey ? getObjValueInDeep(obj, objKey) : obj)),
        batchTotal: arrayPromise.length,
        batchProcessed:
          initial + limit > responseArray.length
            ? responseArray.length
            : initial + limit,
      });
    }

    initial += limit;
    end += limit;
  }

  return responseArray;
};

export const splitRuleArray = (rule: string) => {
  const defaultValues: any[] = [];

  if (rule) {
    const vArrOr = rule.split(" || ");
    vArrOr.forEach((a) => {
      const vArrAnd = a.split(" && ");
      vArrAnd.forEach((a) => defaultValues.push(a));
    });
  }

  const sp = defaultValues.map((a) => {
    const d = a.split(
      ` ${conditions.find((c) => a.includes(c.value))?.value} `,
    );

    return {
      value: d[1],
      field: d[0]?.replaceAll("(", "")?.replaceAll(")", ""),
    };
  });
  return sp;
};

export function getValueField(valueField: { value: any; field: IField }) {
  const {
    field: { field_type, sub_type, single_value, name, id, detail_form },
    value,
  } = valueField;

  const fields = detail_form?.fields || [];

  switch (field_type) {
    case FieldType.SMALL_TEXT:
    case FieldType.LARGE_TEXT:
      const val = {
        id,
        name,
        value,
        single_value,
      };
      switch (sub_type) {
        case SubType.NUMBER:
          return { ...val, value: parseInt(val.value) };
        default:
          return val;
      }

    case FieldType.DATE:
      return {
        id,
        name,
        value: value ? new Date(value).toISOString() : "",
        single_value,
      };

    case FieldType.DATE_RANGE:
      return {
        id,
        name,
        value:
          value && value.startDate && value.endDate
            ? `${new Date(value.startDate).toDateString()} -> ${new Date(
                value.endDate,
              ).toDateString()}`
            : "",
        single_value,
      };

    case FieldType.TABLE:
      const result: any = Object.keys(value)
        .map((e) => {
          const row = value[e];
          return Object.keys(row).map((r) => {
            const subField = fields.find((f) => f.id === parseInt(r));
            if (subField) {
              return getValueField({ value: row[r], field: subField });
            } else return undefined;
          });
        })
        .slice();
      return {
        id,
        name,
        value: result,
        single_value,
      };

    case FieldType.FORM_GROUP:
      const resultForm: any = Object.keys(value).map((r) => {
        const subField = fields.find((f) => f.id === parseInt(r));
        if (subField) {
          return getValueField({ value: value[r], field: subField });
        } else return undefined;
      });
      return {
        id,
        name,
        value: resultForm,
        single_value,
      };

    case FieldType.OPTIONS:
      switch (sub_type) {
        case "SELECT":
        case "TOGGLE":
          return value
            ? {
                id,
                name,
                single_value,
                value: single_value
                  ? value.value
                  : value.map((val: any) => val.value),
              }
            : null;
        default:
          return null;
      }

    default:
      return null;
  }
}

export const filterFields = (array: IField[], val: { [key: string]: any }) => {
  function getRuleValueField(name: string) {
    let vr: any = Object.keys(val).reduce((a: any, v: string) => {
      const typeField = array.find((f) => `${f.id}` === v);

      if (
        typeField?.field_type !== FieldType.FORM_GROUP &&
        typeField?.field_type !== FieldType.TABLE &&
        v === name
      ) {
        a = val[v];
      }
      return a;
    }, null);
    const field = array.find((f) => f.name === name);
    if (vr && field) {
      vr = getValueField({ value: vr, field })?.value;
    }

    return (typeof vr === "string" ? `"${vr}"` : vr) || `""`;
  }

  const b = array.filter((a) => {
    let visible = a.visible_when || "true";
    let rules = splitRuleArray(a.visible_when || "");
    rules.forEach((r) => {
      const value = getRuleValueField(r.field);
      if (Array.isArray(value)) {
        const val = value.find((e: string) => `"${e}"` === r.value);
        visible = visible.replace(r.field, `"${val}"`);
      } else {
        visible = visible.replace(r.field, value);
      }
    });
    return eval(visible);
  });
  return b;
};

export function sortSequences(sequences: any[], events: any[]) {
  let sequences2: any[] = [];
  let visited = sequences.map((it) => {
    return false;
  });
  let nexts: any[] = [];
  let event_start = events.find(
    (event) => event.event_type === EventType.START,
  );
  if (!event_start) {
    return sequences;
  }
  for (let i = 0; i < sequences.length; i++) {
    if (
      sequences[i]["from_item_id"] === event_start.id &&
      sequences[i]["from_item_type"] === "EVENT"
    ) {
      nexts.push(i);
    }
  }
  if (nexts.length === 0) {
    return sequences;
  }
  while (nexts.length != 0) {
    if (!sequences2.some((seq) => seq.id === sequences[nexts[0]].id)) {
      sequences2.push(sequences[nexts[0]]);
    }

    visited[nexts[0]] = true;
    for (let i = 0; i < sequences.length; i++) {
      if (
        sequences[i]["from_item_id"] === sequences[nexts[0]]["to_item_id"] &&
        sequences[i]["from_item_type"] ===
          sequences[nexts[0]]["to_item_type"] &&
        !visited[i]
      ) {
        nexts.push(i);
      }
    }
    nexts = nexts.slice(1, nexts.length);
  }

  for (let i = 0; i < sequences.length; i++) {
    if (!visited[i]) {
      sequences2.push(sequences[i]);
    }
  }
  return sequences2;
}

export function sortTaskEventBySequences(
  tasks: TaskProcess[],
  sequences: Sequence[],
  events: EventProcess[],
  gateways: GatewayProcess[],
) {
  const noExistInSequences: any[] = [],
    results: any[] = [];
  const existInSequences: any[] = sequences.reduce((taskEvents: any[], seq) => {
    if (seq.from_item_type === "EVENT") {
      const evt = events.find((e) => e.id === seq.from_item_id);
      if (evt && !taskEvents.some((event) => event.id === evt.id)) {
        taskEvents.push({ ...evt, type: "event" });
      }
    } else if (seq.from_item_type !== "EVENT") {
      if (seq.from_item_type === "GATEWAY") {
        const gateway = gateways.find(
          (gateway) =>
            gateway.id === seq.from_item_id && seq.from_item_type === "GATEWAY",
        );
        if (gateway && !taskEvents.some((gate) => gate.id === gateway.id)) {
          taskEvents.push({ ...gateway, type: "gateway" });
        }
      }
    } else {
      if (seq.from_item_type !== "EVENT") {
        const tks = tasks.find(
          (task) =>
            task.id === seq.from_item_id &&
            seq.from_item_type === task.activity_type,
        );
        if (tks && !taskEvents.some((task) => task.id === tks.id)) {
          taskEvents.push({ ...tks, type: "task" });
        }
      }
    }

    return taskEvents;
  }, []);

  tasks.forEach((task) => {
    const exist = existInSequences.find(
      (data) => data.type === "task" && data.id === task.id,
    );
    if (!exist) noExistInSequences.push({ ...task, type: "task" });
  });

  events.forEach((event) => {
    const exist = existInSequences.find(
      (data) => data.type === "event" && data.id === event.id,
    );
    if (!exist) noExistInSequences.push({ ...event, type: "event" });
  });

  gateways.forEach((gateway) => {
    const exist = existInSequences.find(
      (data) => data.type === "gateway" && data.id === gateway.id,
    );
    if (!exist) noExistInSequences.push({ ...gateway, type: "gateway" });
  });

  existInSequences.forEach((data: any) => results.push(data));
  noExistInSequences.forEach((data: any) => results.push(data));
  return results;
}

export function splitDataArrayFromString(rule: string) {
  try {
    return (
      rule
        .split("[")
        .pop()
        ?.split("]")
        .shift()
        ?.split(",")
        .map((i) => parseInt(i)) ?? []
    );
  } catch (e) {
    return [];
  }
}

// const sbxKeyToAnalyticKey: {[key: string]: string} = {
//   ANDOR: "logic_operator",
//   FIELD: "field",
//   OP: "filter_operator",
//   VAL: "value"
// }

export const convertSbxFilterToAnalytic = (
  filters: GroupCondition[],
  model: string = "",
) => {
  return filters.map((it) => {
    const opt: SourceFilter = {
      logic_operator: it.ANDOR.toLowerCase(),
      field: it.FIELD,
      filter_operator: (it.OP ?? "").toLowerCase(),
      value: it.VAL,
    };

    if (model) {
      opt.model = model;
    }

    return opt;
  });
};

export const convertAnalyticFilterToSbx = (filters: SourceFilter[]) => {
  return filters.map((it) => {
    return {
      ANDOR: (it.logic_operator as Andor).toUpperCase(),
      FIELD: it.field,
      OP: (it.filter_operator as SbxConditionType).toUpperCase(),
      VAL: it.value,
    };
  });
};

function getStartOfWeek(date: Date) {
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
}

type StaticConstVar =
  | "${date_start_month}"
  | "${date_end_month}"
  | "${now}"
  | "${now_numberdate}"
  | "${date_end_month_add_week}"
  | "${start_of_week}"
  | "${start_of_year}";
export const StaticConstVar: StaticConstVar[] = [
  "${date_start_month}",
  "${now_numberdate}",
  "${now}",
  "${start_of_week}",
  "${start_of_year}",
];

export const replaceConstVarWith = ({
  constVar,
  date_type,
  month,
  year,
}: {
  month?: number | null;
  year?: number | null;
  constVar: StaticConstVar | string;
  date_type?: "numberDate";
}) => {
  const today = new Date();

  if (typeof month === "number" && month >= 0) {
    today.setMonth(month);
  }

  if (typeof year === "number" && year >= 0) {
    today.setFullYear(year);
  }

  switch (constVar) {
    case "${date_start_month}":
      const nDate = new Date(today.getFullYear(), today.getMonth(), 1);
      if (date_type && date_type === "numberDate") {
        return parseInt(convertDateToNumberDate(nDate));
      }
      return nDate.toISOString();
    case "${date_end_month}": {
      const nDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      if (date_type && date_type === "numberDate") {
        return parseInt(convertDateToNumberDate(nDate));
      }
      return nDate.toISOString();
    }
    case "${date_end_month_add_week}": {
      const nDate = new Date(today.getFullYear(), today.getMonth() + 1, 7);
      if (date_type && date_type === "numberDate") {
        return parseInt(convertDateToNumberDate(nDate));
      }
      return nDate.toISOString();
    }
    case "${now}":
      return `${today.toISOString()}`;
    case "${now_numberdate}":
      return convertDateToNumberDate(today);
    case "${start_of_week}": {
      const nDate = getStartOfWeek(today);
      if (date_type && date_type === "numberDate") {
        return parseInt(convertDateToNumberDate(nDate));
      }
      return nDate.toISOString();
    }
    case "${start_of_year}}": {
      const nDate = new Date(today.getFullYear(), 0, 1);
      if (date_type && date_type === "numberDate") {
        return parseInt(convertDateToNumberDate(nDate));
      }
      return nDate.toISOString();
    }
    default:
      return constVar;
  }
};

export function checkAnalyticQueryVars(query: string) {
  StaticConstVar.forEach((constVar) => {
    if (query.toLowerCase().includes(constVar.toLowerCase())) {
      query = query.replaceAll(
        constVar,
        replaceConstVarWith({ constVar }) as string,
      );
    }
  });

  return query;
}

export const getAnalyticQueryDomain = (
  json: BaseAnalyticQuery | Source,
  domain: number,
) => {
  if (
    (json as BaseAnalyticQuery).source &&
    !(json as BaseAnalyticQuery).source.in
  ) {
    (json as BaseAnalyticQuery).source.in = domain;
  }

  if ((json as BaseAnalyticQuery).source?.sources!?.length > 0) {
    (json as BaseAnalyticQuery).source.sources?.forEach((source) => {
      if (!source.in) {
        source.in = domain;
      }
    });
  }

  return json as BaseAnalyticQuery;
};

export const getSelectOptionByConfig = (field: Field, options: any[]) => {
  if (field.format_rules_definition?.columns_labels) {
    const { name, compound_name } =
      field.format_rules_definition?.columns_labels[0];

    if (options && options.length > 0) {
      if (compound_name) {
        const varList = getDefaultVarsFromStr(compound_name);
        // console.log("varList", varList)
        // console.log("options", options)

        if (varList && varList.length > 0) {
          return options
            .filter(
              (item) =>
                !isDefaultVarExpression(
                  getInterpretVar({
                    strVar: compound_name,
                    item,
                  }),
                ),
            )
            .map((item) => {
              let label = getInterpretVar({ strVar: compound_name, item });
              return {
                label: `${label}`,
                value: item._KEY ?? item.value ?? "",
                data: item,
              };
            });
        }
      }

      if (name) {
        return options
          ?.filter((option) => !!getObjValueInDeep(option, name))
          .sort(
            (a, b) =>
              (getObjValueInDeep(a, name) ?? "")?.localeCompare(
                getObjValueInDeep(b, name) ?? "",
              ),
          )
          .map((res) => {
            return {
              label: getObjValueInDeep(res, name) ?? "",
              value: res._KEY ?? res.value ?? "",
              data: res,
            };
          });
      }
    }
  } else {
    if (options && options.length > 0) {
      return options.map((option) => ({
        label: option.label,
        value: option.value,
      }));
    }
  }

  return [];
};

export function success(message = "") {
  toast({ type: "success", message });
}

export function error(message = "") {
  toast({ type: "error", message });
}

export const getDuplicateProcessModel = (newProcessModel: ProcessModel) => {
  delete newProcessModel.updated;
  delete newProcessModel.created;
  delete newProcessModel.id;
  // newProcessModel.name += ` (${t("duplicate")})`

  newProcessModel.tasks = newProcessModel.tasks.map((task) => {
    // task.manager_style = pbW.getSyles(xml)[task.id_manager ?? task.activity_type + "_" + task.id]
    let newTask = { ...task };
    if (newTask.id) {
      if (newTask.process_id) {
        delete newTask.process_id;
      }

      newTask.id_manager = newTask.id.toString();
      delete newTask.id;

      if (newTask.attachments && newTask.attachments.length > 0) {
        newTask.attachments = newTask.attachments.map((attachment) => {
          const newAttachment = { ...attachment };
          if (newAttachment.id) {
            delete newAttachment.id;
          }
          return newAttachment;
        });
      }
    }
    delete newTask.created;
    delete newTask.updated;
    return newTask;
  });

  newProcessModel.events = newProcessModel.events.map((event) => {
    // event.manager_style = pbW.getSyles(xml)[event.id_manager ?? "EVENT_" + event.id]
    const newEvent = { ...event };
    if (newEvent.event_type) {
      newEvent.event_type = getEventType(newEvent.event_type);
    }

    if (newEvent.id) {
      if (newEvent.process_id) {
        delete newEvent.process_id;
      }
      newEvent.id_manager = newEvent.id.toString();
      delete newEvent.id;

      if (newEvent.attachments && newEvent.attachments.length > 0) {
        newEvent.attachments = newEvent.attachments.map((attachment) => {
          const newAttachment = { ...attachment };
          if (newAttachment.id) {
            delete newAttachment.id;
          }
          return newAttachment;
        });
      }
    }

    delete newEvent.created;
    delete newEvent.updated;
    return newEvent;
  });

  newProcessModel.gateways = newProcessModel.gateways.map((gateway) => {
    // event.manager_style = pbW.getSyles(xml)[event.id_manager ?? "EVENT_" + event.id]
    const newGateway = { ...gateway };
    if (newGateway.id) {
      if (newGateway.process_id) {
        delete newGateway.process_id;
      }
      newGateway.id_manager = newGateway.id.toString();
      delete newGateway.id;
    }

    delete newGateway.created;
    delete newGateway.updated;
    return newGateway;
  });

  newProcessModel.sequences = newProcessModel.sequences.map((sequence) => {
    const newSequence: any = { ...sequence };
    // newSequence.manager_style = pbW.getSyles(xml)[sequence.id_manager ?? "SEQ_" + sequence.id]

    if (newSequence.id) {
      newSequence.id_manager = newSequence.id.toString();
      delete newSequence.id;

      // if (sequence.attachments && sequence.attachments[0]?.id) {
      //   delete sequence.attachments[0].id;
      // }

      if (newSequence.process_id) {
        delete newSequence.process_id;
      }

      if (newSequence.from_item_id) {
        newSequence.from_item_id_manager = newSequence.from_item_id.toString();
        delete newSequence.from_item_id;
      }

      if (newSequence.to_item_id) {
        newSequence.to_item_id_manager = newSequence.to_item_id.toString();
        delete newSequence.to_item_id;
      }
    }

    delete newSequence.created;
    delete newSequence.updated;

    if (newSequence.updateEl) {
      if (newSequence.updateEl.from_item_id_manager) {
        newSequence.from_item_id_manager =
          newSequence.updateEl.from_item_id_manager;
      } else {
        newSequence.from_item_id = newSequence.updateEl.from_item_id;
      }

      if (newSequence.updateEl.to_item_id_manager) {
        newSequence.to_item_id_manager =
          newSequence.updateEl.to_item_id_manager;
      } else {
        newSequence.to_item_id = newSequence.updateEl.to_item_id;
      }

      // newSequence.from_item_id = newSequence.updateEl.from_item_id
      newSequence.from_item_type = newSequence.updateEl.from_item_type;
      // newSequence.to_item_id = newSequence.updateEl.to_item_id
      newSequence.to_item_type = newSequence.updateEl.to_item_type;

      delete newSequence.updateEl;
    }

    return newSequence;
  });

  return newProcessModel;
};

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}

export const getIconFile = (extension: string) => {
  switch (extension) {
    case "pdf":
      return pdfIcon;
    case "xlsx":
      return excelIcon;
    case "csv":
      return excelIcon;
    case "png":
      return pngIcon;
    case "jpg":
      return jpgIcon;
    case "pptx":
      return powerPointIcon;
    case "docx":
      return wordIcon;
    default:
      return documentIcon;
  }
};

export const compare = (a: any, b: any, property: string, asc: boolean) => {
  if (a[property]) {
    if (asc) {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
    } else {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
    }

    return 0;
  } else {
    return 0;
  }
};

export const checkValidColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color == strColor;
};

export const checkIsValidHexColor = (strColor: string) => {
  const RegExp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
  return RegExp.test(strColor);
};

export function IsJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const getAnalyticActionFilterOption = (t: (str: string) => string) => {
  return [
    {
      label: t("EQUAL_TO"),
      value: ActionFilterOperator.EQUAL_TO,
    },
    // {
    //   label: t("EXIST"),
    //   value: ActionFilterOperator.EXIST
    // },
    // {
    //   label: t("NO_EXIST"),
    //   value: ActionFilterOperator.NOT_EXIST
    // },
    {
      label: t("GREATER_THAN"),
      value: ActionFilterOperator.GREATER_THAN,
    },
    {
      label: t("SMALLER_THAN"),
      value: ActionFilterOperator.SMALLER_THAN,
    },
    {
      label: t("GREATER_OR_EQUAL_THAN"),
      value: ActionFilterOperator.GREATER_OR_EQUAL_THAN,
    },

    {
      label: t("SMALLER_OR_EQUAL_THAN"),
      value: ActionFilterOperator.SMALLER_OR_EQUAL_THAN,
    },
    {
      label: t("DIFFERENT_OF"),
      value: ActionFilterOperator.DIFFERENT_OF,
    },
    // {
    //   label: t("START_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "word%"
    //   }
    // },
    // {
    //   label: t("END_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "%word"
    //   }
    // },
    // {
    //   label: t("CONTAIN_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "%word%"
    //   }
    // },
  ];
};

export const isValidNumber = (numb: string) => {
  return !isNaN(parseFloat(numb));
};
export const actionFilterOperatorArr = [
  "==",
  "!=",
  ">",
  "<",
  "is not null",
  "is null",
];

export const isFilterAction = (action: AnalyticQueryAction) => {
  return (
    action.filter?.includes("&") ||
    action.filter?.includes("|") ||
    actionFilterOperatorArr.some(
      (operator) => action.filter?.includes(operator),
    ) ||
    action?.filter === "*"
  );
};

export const drawRect = ({
  y,
  x,
  width,
  height,
  ctx,
  text,
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  text?: string;
}) => {
  // ctx.rect(x, y, height, width);
  ctx.rect(x, y, 50, 50);
  ctx.fillStyle = "white";
  ctx.fill();
  ctx.lineWidth = 1;
  if (text) {
    ctx.fillStyle = "black";
    ctx.fillText(text, x, y + height / 2);
  }
};

export const drawCircle = ({
  y,
  x,
  ctx,
  text,
  radius,
}: {
  ctx: CanvasRenderingContext2D;
  radius: number;
  x: number;
  y: number;
  text?: string;
}) => {
  let circle = new Path2D();
  circle.arc(x, y, radius, 0, Math.PI * 2, false);

  if (text) {
    ctx.fillText(text, x - radius, y);
  }
  ctx.stroke(circle);
};

export const drawArrow = ({
  y,
  x,
  ctx,
  toX,
  toY,
}: {
  ctx: CanvasRenderingContext2D;
  toX: number;
  toY: number;
  x: number;
  y: number;
}) => {
  const headlen = 10; // length of head in pixels
  const tox = toX;
  const toy = toY;
  const dx = tox - x;
  const dy = toy - y;
  const angle = Math.atan2(dy, dx);
  ctx.moveTo(x, y);
  ctx.lineTo(tox, toy);
  ctx.lineTo(
    tox - headlen * Math.cos(angle - Math.PI / 6),
    toy - headlen * Math.sin(angle - Math.PI / 6),
  );
  ctx.moveTo(tox, toy);
  ctx.lineTo(
    tox - headlen * Math.cos(angle + Math.PI / 6),
    toy - headlen * Math.sin(angle + Math.PI / 6),
  );
};

type ProcessNodeTypes = "TASK" | "EVENT" | "SEQUENCE";

export const getNodeTypeToProcess = (
  type: ProcessNodeTypes,
): keyof ProcessModel => {
  switch (type) {
    case "SEQUENCE":
      return "sequences";
    case "EVENT":
      return "events";
    default:
      return "tasks";
  }
};

export const getNodeByProcessModel = ({
  processModel,
  id,
}: {
  processModel: ProcessModel;
  id: string;
}) => {
  const type_id = id.split("_");
  if (type_id.length > 0) {
    const type = type_id[0] as ProcessNodeTypes;
    const idNumber = parseInt(type_id[1]);

    const node = processModel[getNodeTypeToProcess(type)].find(
      (node: { id: number }) => node.id === idNumber,
    );
    if (node) {
      return node as Sequence | Task | EventProcess;
    }
  }
};

export function toNumber(str: string): number {
  try {
    const n = parseInt(str);
    return isNaN(n) ? 0 : n;
  } catch (e) {
    return 0;
  }
}

export const autoSuggestDefaultTheme: Theme = {
  container: {
    position: "relative",
  },
  // input: {
  //   width: 240,
  //   height: 30,
  //   padding: '10px 20px',
  //   fontFamily: 'Helvetica, sans-serif',
  //   fontWeight: 300,
  //   fontSize: 16,
  //   border: '1px solid #aaa',
  //   borderTopLeftRadius: 4,
  //   borderTopRightRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   borderBottomRightRadius: 4,
  // },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 51,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
};

export function groupByHead(
  key: string,
  toValue: boolean,
  arr: any[],
  ignoreKeys?: string[],
):
  | {
      rows: any[];
      columns: Column[];
    }
  | AnyData {
  if (!arr.length) {
    return { rows: [], columns: [] };
  }
  const newData = arr.reduce((newObj: any, obj: any) => {
    const obj2 = { ...obj };
    ignoreKeys?.forEach((key) => {
      delete obj2[key];
    });
    Object.keys(obj2).forEach((subKey) => {
      if (subKey === key) {
        if (toValue) {
          if (obj2[subKey]) {
            newObj.a = [...(newObj.a ?? []), obj2[subKey]];
            newObj.b = (newObj.b ?? 0) + 1;
            newObj.c = (newObj.c ?? []).filter(
              (bb: any) => bb !== obj2[subKey],
            );
            newObj.c.push(obj[subKey]);
          }
        } else {
          if (!newObj[obj[subKey]]) newObj[obj2[subKey]] = [];
          newObj[obj2[subKey]].push(obj2);
        }
      }
    });

    return newObj;
  }, {});
  if (toValue) {
    const vv = newData.c;
    return {
      filtered: vv && vv.length ? (vv.length > 1 ? vv : vv.shift()) : null,
      length: newData.b,
      result: newData.a,
    };
  } else {
    const values = Object.values(newData);
    const rows = values.map((obj: any) => {
      if (obj.length > 1) {
        const data = Object.keys(obj[0]).reduce((newObj: any, key2) => {
          const { result, length, filtered } = groupByHead(
            key2,
            true,
            obj,
          ) as any;
          newObj[key2] = filtered;
          if (length && length > 1) {
            newObj[`${key2}_length`] = length;
          }
          if (
            newObj[`${key2}_length`] &&
            Array.isArray(filtered) &&
            filtered.length !== length
          ) {
            newObj[key2] = result;
          }
          return newObj;
        }, {});

        return {
          ...data,
          all_data: obj ?? [],
        };
      }
      return obj.shift();
    });
    let existAll = false,
      columnsToArray: string[] = [];
    const columns = Object.values(
      rows.reduce((objKeys: any, obj: any) => {
        Object.keys(obj).forEach((subKey) => {
          if (Array.isArray(obj[subKey]) && !subKey.includes("_all")) {
            objKeys[subKey] = {
              type: "Array",
              header: subKey
                .split("_")
                .filter((e) => e)
                .join(" "),
              name: subKey,
            };
            columnsToArray.push(subKey);
          }
          if (subKey.includes("all_")) {
            objKeys[subKey] = {
              type: "ArrayObject",
              header: subKey
                .split("_")
                .filter((e) => e)
                .join(" "),
              name: subKey,
            };
            existAll = true;
          }
          if (
            subKey.includes("_length") &&
            Array.isArray(obj[subKey.replace("_length", "")])
          ) {
            objKeys[subKey] = {
              type: "String",
              header: subKey
                .split("_")
                .filter((e) => e)
                .join(" "),
              name: subKey,
            };
          }
        });
        return objKeys;
      }, {}),
    ) as Column[];

    return {
      rows: rows.map((v: any) => {
        if (existAll && !v.all_data) {
          if (!v.all_data) {
            const all = Object.assign({}, v);
            v.all_data = [all];
          }
        }

        columnsToArray.forEach((key) => {
          if (!Array.isArray(v[key])) {
            v[key] = [v[key]];
            v[`${key}_length`] = 1;
          }
        });
        return v;
      }),
      columns,
    };
  }
}

export function filterColumnsToGroup(columns: Column[], value: string) {
  return columns.filter((c) => (c.header ?? "").includes(value));
}

export const checkSpecialCharactersForRegex = (list: string[]) => {
  return list.map((character) => {
    if (character === "+" || character === "*") {
      return "\\" + character;
    }
    return character;
  });
};

export const getNextBusinessDay = (businessDays: BusinessDay[]) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  let isAvailableDay = false;
  while (!isAvailableDay) {
    const month = date.getMonth();
    let todayDate = date.getDate().toString();
    todayDate = parseInt(todayDate) < 10 ? "0" + todayDate : todayDate;

    const businessDate = businessDays.find(
      (bDays) => parseInt(bDays.month_index) === month + 1,
    );
    if (businessDate?.days && JSON.parse(businessDate.days)) {
      isAvailableDay = JSON.parse(businessDate.days)[todayDate];
      if (!isAvailableDay) {
        date.setDate(date.getDate() + 1);
      }
    } else {
      break;
    }
  }

  return isAvailableDay ? date.toISOString() : "";
};

export function toJSON(str?: string | null): any {
  try {
    if (str) {
      return JSON.parse(str);
    }
    throw Error;
  } catch (e) {
    console.log("Error converting ", str, " to JSON format!");
    return undefined;
  }
}

export const stringUtils = {
  toObject<Type>(str: string): Type | undefined {
    return toJSON(str) as Type;
  },
};

export function JSONtoString(obj: any, format?: boolean): string | undefined {
  try {
    if (obj) {
      if (format) {
        return JSON.stringify(obj, null, "\t");
      }

      return JSON.stringify(obj);
    }
    throw Error;
  } catch (e) {
    return undefined;
  }
}

export const updateAnalyticSource = ({
  baseSource,
  sources,
  query,
}: {
  query: AnalyticQuery;
  sources: UpdateSource[];
  baseSource?: Source;
}) => {
  if (sources.length > 0) {
    for (const source of sources) {
      if (source.sourceId === query.source.temporal_id) {
        if (source.sourceValue === null) {
          delete query.source[source.sourceKey];
        } else {
          query.source = {
            ...query.source,
            [source.sourceKey]: source.sourceValue,
          };
        }
      } else {
        if (query.source?.sources && query.source.sources.length > 0) {
          query.source.sources = query.source.sources.map((sourceQuery) => {
            if (source.sourceId === sourceQuery.temporal_id) {
              if (source.sourceValue === null) {
                delete sourceQuery[source.sourceKey];
              } else {
                sourceQuery = {
                  ...sourceQuery,
                  [source.sourceKey]: source.sourceValue,
                };
              }
            }

            return sourceQuery;
          });
        }
      }
    }

    // if (baseSource){
    //   getColumns({source:baseSource, analyticQuery: query});
    // }

    return query;
    // dispatchForm({name: 'analyticQuery', value: query});
  }
};

export const isEnableTaskAction = (
  data: { [key: string]: any },
  sequence_rules: string,
) => {
  try {
    data = Object.keys(data).reduce(
      (obj: { [key: string]: { value: any } }, key) => {
        if (typeof data[key] === "number" || typeof data[key] === "string") {
          obj[key] = { value: data[key] };
        } else {
          obj[key] = data[key];
        }

        return obj;
      },
      {},
    );

    const rulesKeys = removeBracketsFromString(sequence_rules)
      .replace(/[^a-zA-Z._]/g, "")
      .split("process")
      .filter((key) => key !== "__sequence_enabled" && key);

    rulesKeys.forEach((rule) => {
      if (!rule.includes("value")) {
        if (data[rule] && data[rule].value) {
          data[rule] = data[rule].value;
        } else {
          if (!getObjValueInDeep(data, rule) || !data[rule]) {
            data[rule] = getObjValueInDeep(data, rule);
          }
        }
      }
    });

    // console.log('data', data)
    const code =
      "process =" +
      JSON.stringify(data) +
      "; process['__sequence_enabled'] = true;" +
      sequence_rules +
      "return process";

    const F = new Function(code);
    const x = F();

    return !!(
      ("__sequence_enabled" in x && x["__sequence_enabled"]) ||
      !("__sequence_enabled" in x)
    );
  } catch (e) {
    return false;
  }
};

export function removeItemFromArray(numberToRemove: number, array: Array<any>) {
  return new Array(...array).filter((n) => n !== numberToRemove);
}

export const getEnvironmentByConfig = (domain_id: string) => {
  return {
    129: "iBuyFlowers",
    272: "Chilco",
    281: "Fempha",
    284: "Simas",
    286: "Sbx",
  }[domain_id];
};

export const copyToClipboard = (text: string) => {
  const textarea = document.createElement("textarea");
  document.body.appendChild(textarea);
  textarea.value = text;
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);
  toast({ type: "info", message: "Text copied" });
};

const organizationQueryCache: { [where: string]: SbxResponse } = {};

export const getSalesManagerOrganizationQuery = async (params: {
  model: string;
  where?: Condition[];
}) => {
  const { user } = store.getState().AuthReducer;
  const config = user.config;
  if (
    config?.sbx_crm.organization?.sales_managers &&
    Object.keys(config?.sbx_crm.organization?.sales_managers).length > 0
  ) {
    const sales_manager = config.sbx_crm.organization?.sales_managers;

    params.where = await getSalesOrganizationWhere({
      where: params.where,
      items_id: sales_manager.manager_id,
      sales_obj: sales_manager as SalesManagerConfig,
      id: "manager_id",
      model: params.model,
    });
  }

  return params?.where ? params.where : [];
};

export const getSalesOrgOrganizationQuery = async (params: {
  model: string;
  where?: Condition[];
}) => {
  const { user } = store.getState().AuthReducer;
  const config = user.config;

  if (
    config?.sbx_crm.organization?.sales_org &&
    Object.keys(config?.sbx_crm.organization?.sales_org).length > 0
  ) {
    const sales_org = config.sbx_crm.organization?.sales_org;
    params.where = await getSalesOrganizationWhere({
      where: params.where,
      items_id: sales_org.org_id,
      sales_obj: sales_org as SalesOrgConfig,
      id: "org_id",
      model: params.model,
    });
  }
  return params?.where ? params.where : [];
};

/**
 * It takes a sales organization configuration object and a user, and returns a where clause that can be used to filter the
 * data that the user can see
 * @param params - {sales_obj: (SalesManagerConfig | SalesOrgConfig), where?: Condition[], id: string, model: string,
 * items_id: string}
 */
const getSalesOrganizationWhere = async (params: {
  sales_obj: SalesManagerConfig | SalesOrgConfig;
  where?: Condition[];
  id: string;
  model: string;
  items_id: string;
}) => {
  const { user } = store.getState().AuthReducer;
  const { sales_obj } = params;
  const apply_model: ApplyToSalesManager | undefined = (
    sales_obj.apply_to as Array<SalesManagerConfig>
  ).find((apply) => apply.model.split(".")[0] === params.model);

  if (
    sales_obj.hasOwnProperty("exclude_users") &&
    sales_obj.exclude_users.includes(user.id ?? 0)
  ) {
    return params.where;
  }

  if (apply_model) {
    if (sales_obj?.user_id && user) {
      if (sales_obj[params.id as keyof typeof sales_obj] && sales_obj.model) {
        // const manager_id = user[sales_org.manager_id]
        let query = new Find(sales_obj.model, 0);
        query.andWhereIsEqualTo(sales_obj.user_id, user.id);
        const final_query: Query = query.compile();

        let response: null | SbxResponse = null;

        if (organizationQueryCache[JSON.stringify(final_query.where)]) {
          response = organizationQueryCache[JSON.stringify(final_query.where)];
        } else {
          response = await findByModel({
            row_model: final_query.row_model,
            where: final_query.where,
            fetch: [],
            page: 1,
            size: 15,
            noGetOrganization: true,
          });

          if (response?.success) {
            organizationQueryCache[JSON.stringify(final_query.where)] =
              response;
          }
        }

        if (
          response &&
          response?.success &&
          response?.items &&
          response?.items.length > 0
        ) {
          const sales_key: string[] = response.items
            .filter(
              (sales_item: { [key: string]: string }) =>
                sales_item[params.items_id],
            )
            .map(
              (sales_item: { [key: string]: string }) =>
                sales_item[params.items_id],
            );

          if (sales_key.length > 0 && params) {
            const apply_model: ApplyToSalesManager | undefined = (
              sales_obj.apply_to as Array<SalesManagerConfig>
            ).find((apply) => apply.model.split(".")[0] === params.model);
            if (apply_model) {
              if (!params.where) {
                params.where = [];
              }

              let field = apply_model[params.id as keyof typeof apply_model];

              if (apply_model.model.split(".").length > 1) {
                // Ej: model -> sbx_crm.company & manager_id -> acc_manager = company.acc_manager
                field = apply_model.model.split(".").slice(1)[0] + "." + field;
              }

              params.where.push({
                ANDOR: "AND",
                GROUP: [
                  {
                    ANDOR: "AND",
                    VAL: sales_key,
                    // "FIELD": model + "." + apply_model[params.id as keyof typeof apply_model],
                    FIELD: field,
                    OP: "IN",
                  },
                ],
              });
            }
          }
        }
      }
    }
  }

  return params.where;
};

/**
 * It takes a query, and if the query has a `where` clause, it will add the `where` clause to the query
 * @param params - {where: Condition[] | { keys: string[] }, row_model: string }
 */
export const organizationFindQuery = async (params: {
  where: Condition[] | { keys: string[] };
  row_model: string;
}) => {
  if (params.where?.keys && params.where?.keys.length > 0) {
    let query = new Find(params.row_model, 0);
    query.andWhereIsIn("_KEY", params.where.keys);
    const nQuery: Query = query.compile();
    params.where = nQuery?.where.length > 0 ? nQuery.where : [];
  }

  params.where = await getSalesManagerOrganizationQuery({
    where: params.where as Condition[],
    model: params.row_model,
  });

  params.where = await getSalesOrgOrganizationQuery({
    where: params.where as Condition[],
    model: params.row_model,
  });

  return params.where as Condition[];
};

export const getContentFileName = (file: Content) =>
  file.name?.includes("_") && file.name?.includes("file")
    ? file.name.split("_")?.at(-1) ?? file.name
    : file.name;

// export const labelRange: {[key: number | string]: string} = {
//   0: "Este mes",
//   1: "Ultimos 30 dias",
//   2: "Este año",
//   3: "Hace un año",
//   4: "Todo",
//   'date-range': "Seleccionar fechas"
// }

export const labelRange: { [key: number | string]: string } = {
  0: "this_month",
  1: "last_30_days",
  5: "Three months ago",
  6: "This trimester",
  9: "Last_six_weeks",
  10: "now",
  7: "Six months ago",
  8: "This semester",
  2: "this_year",
  3: "one_year_ago",
  4: "all",
  "date-range": "date-range",
  "select-date": "select-date",
};

const truncateRange = {
  THIS_MONTH: 0,
  LAST_30_DAYS: 1,
  THIS_YEAR: 2,
  YEAR_AGO: 3,
  ALL_TIME: 4,
  SIX_MONTHS_AGO: 7,
  THIS_SEMESTER: 8,
  THREE_MONTHS_AGO: 5,
  THIS_TRIMESTER: 6,
  LAST_SIX_WEEKS: 9,
  NOW: 10,
};

export const truncateArray: {
  label: string;
  value: string | number;
  name: string;
}[] = [
  { label: "Este mes", value: truncateRange["THIS_MONTH"], name: "this_month" },
  {
    label: "Ultimos 30 dias",
    value: truncateRange["LAST_30_DAYS"],
    name: "last_30_days",
  },
  // {label: "Hace tres meses", value: truncateRange["THREE_MONTHS_AGO"], name: "three_months_ago"},
  // {label: "Este trimestre", value: truncateRange["THIS_TRIMESTER"], name: "this_trimester"},
  // {label: "Ultimas seis semanas", value: truncateRange["LAST_SIX_WEEKS"], name: "last_six_weeks"},
  // {label: "Hace seis meses", value: truncateRange["SIX_MONTHS_AGO"], name: "six_months_ago"},
  // {label: "Este semestre", value: truncateRange["THIS_SEMESTER"], name: "this_semester"},
  // {label: "Este año", value: truncateRange["THIS_YEAR"], name: "this_year"},
  // {label: "Hace un año", value: truncateRange["YEAR_AGO"], name: "one_year_ago"},
  { label: "Hoy", value: truncateRange["NOW"], name: "now" },
  // {label: "Todo", value: truncateRange["ALL_TIME"], name: "all_time"},
  // {label: "Seleccionar rango de fechas", value: "date-range", name: "date-range"},
  // {label: "Seleccionar fecha", value: "select-date", name: "select-date"}
];

export function isValidDate(d: Date | number | string) {
  return d instanceof Date;
}

export function containsNumbers(str: string) {
  return /[0-9]/.test(str);
}

export function containsCharacter(str: string) {
  const regex = /^[a-zA-Z_]+$/;
  return regex.test(str);
}

export function validateVisibleWhenVar(str: string) {
  // Escape special characters and join the characters with "|"
  const escapedPattern = "1_1_equipo".replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const regexString = "[" + escapedPattern + "]";
  return new RegExp(regexString).test(str);
}

export function getFormat(
  key: string,
  appointment: any | Appointment,
  getValue: (value: any) => any,
) {
  const value = appointment[key];
  switch (key) {
    case "summary":
      return { value, title: key };
    case "description":
      return { value, title: key };
    case "invitees":
      return { value, title: key };
    case "start_time":
      return { value, title: "startTime" };
    case "end_time":
      return { value, title: "endTime" };
    case "start_date":
      return {
        value: convertNumberDateToDate(value).toDateString(),
        title: "startDate",
      };
    case "due_date":
      return {
        value: convertNumberDateToDate(value).toDateString(),
        title: "endDate",
      };
    case "account":
      return {
        title: "client",
        value: appointment.account_data?.data?.company?.company_name,
      };
    case "sales_addressee":
      const val = getValue(value);
      return { title: "addressee", value: val?.label };
    case "workflow_id":
      const pr = getValue(value);
      return { title: "process", value: pr?.label };
    default:
      return { value: null, title: null };
  }
}

export function DEBUGGER_MODE() {
  if (localStorage.getItem("DEBUGGER_MODE") === "DEBUGGER") debugger;
}

export const isUUID = (str: string) => {
  const match =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  return str.match(match);
};

export const isUUIDV4 = (str: string) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(str);
};

export const evalConditionExpression = ({
  expression,
  form,
  getFormValue,
  fields,
}: {
  expression: string;
  form: { [key: string]: string };
  getFormValue?: (name?: string) => {};
  fields: string[];
}) => {
  let default_expression = expression;

  const varList = expression
    .trim()
    .split(/!==|===|>|<|>=|<=|==|\(|\)|&|\|/g)
    .map((variable) => {
      if (variable?.includes(".includes")) {
        variable = variable.split(".includes")[0];
      }

      return variable.trim();
    });

  // .split(".includes").map(v => removeBracketsFromString(v).replaceAll("'", ''));
  let formValues: { [key: string]: any } = {};

  if (getFormValue) {
    formValues = { ...getFormValue() };
    if (formValues.field_labels) {
      formValues = { ...formValues, ...formValues.field_labels };
      delete formValues.field_labels;
    }
  }

  // console.log("formValues", formValues)
  // console.log("form", form)
  // console.log("varList",varList)
  //
  // console.log("fields", fields)

  varList
    .filter((variable) =>
      variable.includes(".")
        ? true
        : fields.includes(variable.trim()) ||
          Object.keys(form).includes(variable.trim()),
    )
    .forEach((variable) => {
      let value: any = null;
      // console.log("Variable", variable)

      const nVariable = variable.trim().replaceAll(/\(|\)|,/g, "");

      if (
        form &&
        (getObjValueInDeep(form, nVariable) ||
          getObjValueInDeep(form, nVariable) === null)
      ) {
        value = getObjValueInDeep(form, nVariable);
      }

      if (
        formValues &&
        (getObjValueInDeep(formValues, nVariable) ||
          getObjValueInDeep(formValues, nVariable) === null)
      ) {
        value = getObjValueInDeep(formValues, nVariable);
      }

      if (!value) {
        value = form[nVariable];
      }

      // console.log("variable", nVariable)
      // console.log("value", value)
      // console.log("default_expresion", default_expression)
      // console.log("containsCharacter(nVariable)", containsCharacter(nVariable))
      // console.log("\n\n\n\n\n\n")
      if (containsCharacter(nVariable) || validateVisibleWhenVar(nVariable)) {
        // console.log("IsJsonString(variable)", IsJsonString(variable))
        // console.log("containsNumbers(variable)", containsNumbers(variable))
        //   console.log("isUUID(JSON.parse(variable))", isUUID(JSON.parse(variable)))
        // console.log("\n\n\n\n")
        if (
          !(
            IsJsonString(variable) &&
            containsNumbers(variable) &&
            isUUID(JSON.parse(variable))
          )
        ) {
          if (variable.includes("currentUser.")) {
            if (value) {
              default_expression = default_expression.replaceAll(
                variable,
                value,
              );
            }
          } else {
            if (typeof value === "object" && value !== null) {
              if (value?.value) {
                default_expression = default_expression.replaceAll(
                  variable,
                  value?.value ? `"${value?.value}"` : "null",
                );
              } else {
                if (value?.type === "NULL" || !value.value) {
                  default_expression = default_expression.replaceAll(
                    variable,
                    "null",
                  );
                }
              }
            } else {
              default_expression = default_expression.replaceAll(
                variable,
                value ? `"${value}"` : "null",
              );
            }
          }
        }
      }
    });

  return evalExpression(default_expression.trim());
};

export const getTaskValueFromData = ({
  str,
  task,
  getValues,
  form,
}: {
  str: string;
  task: Task | null;
  form?: { [key: string]: string };
  getValues?: UseFormGetValues<any>;
}) => {
  const varList = getDefaultVarsFromStr(str);
  let defaultStr = str;

  if (varList && varList.length > 0) {
    varList.forEach((defaultVar) => {
      const nameVar = getVariableDefaultValue(defaultVar);

      if (task?.process_data[nameVar]) {
        if (task?.process_data[nameVar].value) {
          defaultStr = defaultStr.replaceAll(
            defaultVar,
            task?.process_data[nameVar].value,
          );
        } else {
          if (
            task.process_data[nameVar] &&
            typeof task.process_data[nameVar] !== "undefined"
          ) {
            defaultStr = defaultStr.replaceAll(
              defaultVar,
              task.process_data[nameVar] as any,
            );
          }
        }
      } else if (
        getValues &&
        getValues(nameVar) &&
        defaultStr.includes(nameVar)
      ) {
        defaultStr = defaultStr.replaceAll(defaultVar, getValues(nameVar));
      } else {
        if (form && form[nameVar]) {
          defaultStr = defaultStr.replaceAll(defaultVar, form[nameVar]);
        }
      }
    });
  }

  return defaultStr;
};

const default_chart = ({
  possibleColor,
  data,
  datasets,
  color,
  label,
  y_axis,
}: {
  y_axis: string[];
  label: string;
  color: string[];
  data: ChartData<any>;
  datasets: { [key: string]: any };
  possibleColor: { [key: string]: string };
}) => {
  if (datasets && Object.keys(datasets).length) {
    data.datasets = Object.keys(datasets).map((label) => {
      return {
        label,
        backgroundColor: possibleColor[label] ?? "rgba(255,99,132,0.2)",
        borderColor: possibleColor[label] ?? "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: possibleColor[label] ?? "rgba(255,99,132,0.4)",
        hoverBorderColor: possibleColor[label] ?? "rgba(255,99,132,1)",
        data: datasets[label],
      };
    });
  } else {
    data.datasets = [
      {
        label: label,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        data: y_axis,
      },
    ];
  }

  return data;
};

function random_rgba() {
  const o = Math.round,
    r = Math.random,
    s = 255;
  return (
    "rgba(" +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    r().toFixed(1) +
    ")"
  );
}

const arrayColors = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];

export const getReportChartData = ({
  report_data,
  metadata,
}: {
  report_data: any[];
  metadata: ReportMetadata;
}) => {
  let x_axis: string[] = [];
  let y_axis: string[] = [];
  let color: string[] = [];
  let label = "";
  let datasets: { [key: string]: any } = {};
  let possibleColor: { [key: string]: any } = {};
  if (metadata) {
    label = metadata["y_axis"];
    x_axis = report_data.map((item) => {
      if (
        item[metadata["x_axis"]] ||
        typeof item[metadata["x_axis"]] === "number"
      ) {
        return item[metadata["x_axis"]];
      }

      return "";
    });
    y_axis = report_data.map((item) => item[metadata["y_axis"]]);
    color = report_data.map((item, idx) => {
      return arrayColors[idx] ? arrayColors[idx] : random_rgba();
    });
    if (metadata.split_by) {
      const possibleSplit = Object.keys(
        report_data.reduce((obj: { [label: string]: number[] }, item) => {
          if (metadata.split_by) {
            obj[item[metadata.split_by]] ||= [];
            obj[item[metadata.split_by]].push(1);
          }

          return obj;
        }, {}),
      );

      possibleColor = possibleSplit.reduce((obj: any, it, index) => {
        if (arrayColors[index]) {
          obj[it] = arrayColors[index];
        } else {
          obj[it] = random_rgba();
        }
        return obj;
      }, {});
      x_axis = Object.keys(
        report_data.reduce((obj: { [label: string]: number[] }, item) => {
          obj[item[metadata.x_axis]] ||= [];
          obj[item[metadata.x_axis]].push(1);
          return obj;
        }, {}),
      ).sort();

      const temp = report_data.reduce(
        (obj: { [x_axis: string]: { [label: string]: number } }, item) => {
          obj[item[metadata.x_axis]] ||= {};
          if (metadata.split_by) {
            obj[item[metadata.x_axis]][item[metadata.split_by]] =
              item[metadata.y_axis];
          }
          return obj;
        },
        {},
      );

      datasets = x_axis.reduce((obj: { [label: string]: number[] }, item) => {
        possibleSplit.forEach((label) => {
          obj[label] ||= [];
          obj[label].push(
            temp[item] && temp[item][label] ? Math.round(temp[item][label]) : 0,
          );
        });
        return obj;
      }, {});

      const total = Object.keys(temp).reduce(
        (obj: { [key: string]: number }, y_axis) => {
          obj[y_axis] ||= 0;

          obj[y_axis] = Object.keys(temp[y_axis]).reduce(
            (acum, split) => (acum += temp[y_axis][split]),
            0.0,
          );

          return obj;
        },
        {},
      );

      if (metadata?.total === true || !metadata.hasOwnProperty("total")) {
        datasets["Total"] = Object.values(total);
      }
    }
  }

  let data: ChartData<any> = {
    labels: x_axis,
    datasets: [],
  };

  return default_chart({ data, datasets, label, color, y_axis, possibleColor });
};

export class HistoryLocal {
  name: string = "";

  constructor(name: string) {
    this.name = name;
  }

  setItem(value: any, name?: string) {
    const data = JSON.parse(window.localStorage.getItem(this.name) ?? "[]");
    data.push({ date: new Date().toISOString(), value, name });
    window.localStorage.setItem(this.name, JSON.stringify(data));
  }

  getItem(): Array<{ name?: string; value: AnyData; date: string }> {
    return JSON.parse(window.localStorage.getItem(this.name) ?? "[]").sort(
      (
        a: { date: string },
        b: {
          date: string;
        },
      ) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
  }
}

export const getVarValueByType = ({
  value,
  t,
}: {
  value: any;
  t: (v: string) => string;
}) => {
  if (typeof value === "boolean") {
    return value ? t("yes") : "No";
  } else {
    return value;
  }
};

export const getTimeFormat = (time: Date | string) => {
  let hour, minutes;
  if (typeof time === "string") {
    const [h, m] = time.split(":");
    hour = parseInt(h as string);
    minutes = parseInt(m as string);
  } else {
    hour = time.getHours();
    minutes = time.getMinutes();
  }
  return {
    hour,
    minutes,
    time: parseInt(`${hour}${stringFormat("##", minutes)}`),
    stringTime: `${stringFormat("##", hour)}:${stringFormat("##", minutes)}`,
  };
};

export function subtractHours(startTime: string, endTime: string) {
  const baseDate = "2000-01-01"; // Arbitrary date to avoid Date object issues

  const start = new Date(`${baseDate}T${startTime}:00`);
  let end = new Date(`${baseDate}T${endTime}:00`);

  if (end < start) {
    // If end time is earlier than start time, it means it passed midnight
    end.setDate(end.getDate() + 1);
  }

  const diffMs = end.getTime() - start.getTime();
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  return {
    hours,
    minutes
  }
}

export function stringFormat(format: string, value: number) {
  const str = value?.toString() ?? "";
  const difference = str.length - format.length;
  return difference >= 0
    ? `${value}`
    : `${iterator(1, Math.abs(difference))
        .map((i) => "0")
        .join("")}${value}`;
}

interface Times {
  hour: number;
  minutes: number;
  time: number;
  stringTime: string;
}

export const getTimes = () => {
  const getFormat = (hour: number, minutes: number) => {
    return {
      hour,
      minutes,
      time: parseInt(`${hour}${stringFormat("##", minutes)}`),
      stringTime: `${stringFormat("##", hour)}:${stringFormat("##", minutes)}`,
    };
  };
  return iterator(0, 23).reduce(
    (arr: Times[], number) =>
      arr.concat([getFormat(number, 0), getFormat(number, 30)]),
    [],
  );
};

export function getNumberTime(time: string | Date) {
  if (typeof time === "string") {
    const [h, m] = time.split(":").map((n) => parseInt(n));
    return parseInt(`${h}${stringFormat("##", m)}`);
  } else {
    return parseInt(
      `${time.getHours()}${stringFormat("##", time.getMinutes())}`,
    );
  }
}

export function excludeHour(hour: number, minutes = 0, seconds = 0): Date {
  const date = new Date();
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setHours(hour);
  return date;
}

export function validateTime(val: string | null, value?: Date | null) {
  if (value) {
    if (val) {
      const currentTime = getNumberTime(val);
      return getTimes().reduce((dates: Date[], timeVal) => {
        if (timeVal.time < currentTime) {
          const date = new Date(value);
          date.setMinutes(timeVal.minutes);
          date.setHours(timeVal.hour);
          dates.push(date);
        }
        return dates;
      }, []);
    }
  }
  return [];
}

export function getValuesByKeys(
  keys: string | (string | number)[],
  data: { [key: string]: ProcessData },
) {
  function byItem(key: string) {
    const fieldName = getFieldNameFromKeyField(key.toString());
    return data ? data[fieldName]?.value : undefined;
  }

  if (Array.isArray(keys)) {
    return keys.reduce((newKeys: string[], kk) => {
      if (kk.toString().includes("${")) {
        const defaultValue = byItem(kk.toString());
        if (defaultValue) {
          newKeys.push(defaultValue);
        }
      } else {
        newKeys.push(kk.toString());
      }
      return newKeys;
    }, []);
  } else {
    return keys.includes("${") ? byItem(keys) : keys;
  }
}

export function openInNewTab(url: string) {
  if (!url.includes("https://")) {
    url = `https://${url}`;
  }

  window.open(url, "_blank")!.focus();
}

export function isEncoded(uri: string) {
  uri = uri || "";

  return uri !== decodeURIComponent(uri);
}

export function valueType(value: any): object | string | number {
  const parseObject =
    typeof value === "string" && IsJsonString(value) ? toJSON(value) : value;

  if (typeof parseObject === "object") {
    return parseObject as object;
  }

  if (isValidNumber(value)) {
    return value as number;
  }

  return value as string;
}

type Service = () => Promise<Response>;

export async function asyncParallel(services: Service[]) {
  const promises = services.map((service) => service());
  const results: any[] = [];

  const settledPromises = await Promise.allSettled(promises);

  settledPromises.forEach((settledPromise) => {
    if (settledPromise.status === "fulfilled") {
      results.push(settledPromise.value);
    }
  });

  return results;
}

const searchBySplitText = ({
  query,
  search,
  search_column,
  type,
}: {
  query: Find;
  search: string;
  search_column: string;
  type: "split-text" | "split-text-and" | string;
}) => {
  // if (type === 'split-text-and') {

  // }



  search.split(" ").forEach((searchText, index) => {
    if (type === "split-text-and" && index > 0) {
      // query.andWhereItContains(search_column, searchText);

      query.andWhereItContains(search_column, searchText);
    } else {



      query.newGroupWithOr();
      query.andWhereItContains(search_column, searchText);
    }
    // query.orWhereItContains(search_column, searchText);
    // }
  });
};

// Función para calcular la puntuación de coincidencia
function calculateMatchScore(
  item: any,
  search_by: string[],
  search_by_type: any,
  search: string,
): number {
  let score = 0;
  const searchTerms = search.toLowerCase().split(" ");

  search_by.forEach((search_column) => {
    const itemValue = item[search_column]?.toString()?.toLowerCase() || "";

    if (search_by_type[search_column] === "split-text") {
      searchTerms.forEach((term) => {
        if (itemValue.includes(term)) {
          score += 1; // Incrementa la puntuación por cada término coincidente
        }
      });
    } else {
      if (searchTerms.every((term) => itemValue.includes(term))) {
        score += searchTerms.length; // Incrementa la puntuación por cada término en coincidencia total
      }
    }
  });

  return score;
}

export function filterListByField({
  search_by_type,
  search,
  search_by,
  items,
}: {
  search_by: string[];
  search: string;
  search_by_type: { [key: string]: string };
  items: any[];
}) {
  // Only check for split-text-and if it's present in the search_by_type object

  if (
    Object.values(search_by_type).some((split) => split === "split-text-and")
  ) {
    let newItems = [...items];
    newItems = newItems.filter((item) => {
      let isMatch = false;
      search_by.forEach((search_column) => {
        if (search_by_type[search_column] === "split-text") {
          isMatch =
            isMatch ||
            search.split(" ").some((searchText) => {
              // if  (search_column === "code" && item['code'] && item['code'].includes('547177')){
              //   debugger
              // }
              //
              // console.log('valye', item[search_column] ?? "")
              // console.log('search', searchText)

              return (
                item &&
                item[search_column] !== null &&
                typeof item[search_column] !== "undefined" &&
                item[search_column]
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            });
        } else {
          isMatch =
            isMatch ||
            search.split(" ").every((searchText) => {
              return (
                item &&
                item[search_column] !== null &&
                typeof item[search_column] !== "undefined" &&
                item[search_column]
                  .toString()
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            });
        }
      });

      return isMatch;
    });

    // Agregar puntuación de coincidencia a cada elemento
    const itemsWithScores = newItems.map((item) => {
      const score = calculateMatchScore(
        item,
        search_by,
        search_by_type,
        search,
      );
      return { ...item, score };
    });

    // Ordenar los elementos por puntuación de coincidencia en orden descendente
    itemsWithScores.sort((a, b) => b.score - a.score);
    // Eliminar la propiedad de puntuación antes de devolver los resultados
    return itemsWithScores.map(({ score, ...rest }) => rest);
  } else {
    if (search && search.length > 0) {
      // Agregar puntuación de coincidencia a cada elemento
      const itemsWithScores = [...items].map((item) => {
        const score = calculateMatchScore(
          item,
          search_by,
          search_by_type,
          search,
        );
        return { ...item, score };
      });

      // Ordenar los elementos por puntuación de coincidencia en orden descendente
      itemsWithScores.sort((a, b) => b.score - a.score);
      // Eliminar la propiedad de puntuación antes de devolver los resultados
      return itemsWithScores.map(({ score, ...rest }) => rest);
    } else {
      return items;
    }
  }

  // return newItems.sort((a, b) => b.isMatch - a.isMatch)
}

export function searchByField({
  query,
  search_by,
  search,
  search_by_type,
}: {
  query: Find;
  search_by: string[];
  search: string;
  search_by_type?: { [key: string]: string };
}) {
  query.newGroupWithOr();

  // console.log('search_by', search_by)

  if (!search_by || search_by.length === 0) {
    return query;
  }

  search_by.forEach((search_column) => {
    if (
      search_by_type &&
      Object.keys(search_by_type) &&
      search_by_type[search_column]
    ) {
      if (search_by_type[search_column] === "number") {
        if (containsNumbers(search) && !containsCharacter(search)) {
          query.newGroupWithOr();
          query.andWhereIsEqualTo(search_column, search);
        }
      } else {
        if (search_by_type && search_by_type[search_column].includes("split")) {
          // console.log('search_column', search_column)
          searchBySplitText({
            query,
            search,
            search_column,
            type: search_by_type[search_column],
          });
        } else {
          query.newGroupWithOr();
          query.andWhereItContains(search_column, search);
          // query.orWhereItContains(search_column, search);
        }
      }
    } else {
      if (
        !containsNumbers(search) ||
        (search_by_type && search_by_type[search_column] === "text")
      ) {
        query.newGroupWithOr();
        query.andWhereItContains(search_column, search);
      } else {
        if (search_by_type && search_by_type[search_column].includes("split")) {
          searchBySplitText({
            query,
            search,
            search_column,
            type: search_by_type[search_column],
          });
        } else {
          // query.orWhereItContains(search_column, search);
          query.newGroupWithOr();
          query.andWhereItContains(search_column, search);
        }
      }
    }
  });

  return query;
}

export const getFilterVarsByAccount = ({
  account,
  data,
  salesAddresee,
  query,
}: {
  account?: Account;
  salesAddresee?: { [key: string]: any };
  data?: { [key: string]: any };
  query: string;
}) => {
  let conditionsOperators: string[] = [
    "!==",
    "===",
    ">",
    "<",
    ">=",
    "<=",
    "==",
    "&",
    "|",
  ];
  const varList = query
    .replaceAll(" ", "")
    .trim()
    .split(/(!==|===|>|<|>=|<=|==|&|\|)/g);

  return varList.reduce((str, variable) => {
    if (conditionsOperators.includes(variable)) {
      str += " " + variable;
      return str;
    }

    const value = getObjValueInDeep(
      { salesAddressee: salesAddresee, account, row: data, ...(data ?? {}) },
      variable,
    );

    if (value) {
      str += ` '${value}'`;
      return str;
    }

    str += " " + variable;
    return str;
  }, "");
};

export type BaseAccountData = Partial<AccountCustomTab> & {
  account?: Account;
  salesAddresee?: { [key: string]: any };
  data?: { [key: string]: any };
};

export const getBaseAction = ({
  extra_query,
  report_filter_column,
  account_filter_column = "_KEY",
  extra_query_apply,
  account,
  salesAddresee,
  data,
}: BaseAccountData) => {
  const base: AnalyticQueryAction = {
    type: "filter",
    filter:
      report_filter_column && account
        ? `${report_filter_column} == '${getObjValueInDeep(
            account,
            account_filter_column,
          )}'`
        : "",
    transformation: "",
  };

  const pathname = window.location.pathname + window.location.search;

  if (
    extra_query &&
    (!extra_query_apply ||
      (extra_query_apply &&
        extra_query_apply?.some((path) => pathname.includes(path))))
  ) {
    const condition = getFilterVarsByAccount({
      account,
      salesAddresee,
      data,
      query: extra_query,
    });

    if (condition.length > 0) {
      base.filter = `${
        base.filter ? `${base.filter} & ` : ""
      } ${condition.trim()}`;
    }
  }

  if (base.filter) {
    if (base.filter.trim().endsWith("&") || base.filter.trim().endsWith("|")) {
      base.filter = base.filter.trim().slice(0, -1);
    }
  }

  return [base];
};

export function replaceFieldsIntoText(
  text: string,
  process: { [key: string]: ProcessData },
): string {
  let txt = text;
  if (text.includes("$")) {
    const names = text.split("$").reduce((array: string[], name) => {
      const nn = name.replace("{", "").split("}").shift();
      if (nn) {
        array.push(getFieldNameFromKeyField("${" + `${nn}}`));
      }
      return array;
    }, []);
    names.forEach((name) => {
      txt = txt.replaceAll("${" + `${name}}`, process[name]?.value || "");
    });
  }
  return txt;
}

export const getBodyWhere = (body: {
  where: Condition[] | { keys: string[] };
  row_model: string;
}) => {
  if (
    body.where &&
    (body.where as Condition[]).some(
      (where) =>
        where && where.GROUP && where.GROUP.some((group) => group.OP === "IN"),
    )
  ) {
    (body.where as Condition[]).forEach((where) => {
      where.GROUP.forEach((group) => {
        if (group.OP === "IN") {
          if (
            typeof group.VAL === "string" &&
            IsJsonString(group.VAL) &&
            Array.isArray(JSON.parse(group.VAL))
          ) {
            group.VAL = JSON.parse(group.VAL);
          } else {
            if (!Array.isArray(group.VAL)) {
              if (group.VAL) {
                if (typeof group.VAL === "string" && group.VAL.includes(",")) {
                  group.VAL = group.VAL.split(",");
                } else {
                  group.VAL = [group.VAL as string];
                }
              } else {
                group.VAL = [];
              }
            }
          }
        }
      });
    });
  }

  return body.where;
};

export const removeLastCharacter = (inputString: string) => {
  return inputString.slice(0, -1);
};

export const handleFileUpload = (
  file: File,
  getRows: (rows: any[]) => void,
) => {
  try {
    const reader = new FileReader();
    let items: any[] = [];
    reader.onload = (evt: ProgressEvent<FileReader>) => {
      /* Parse data */
      if (evt?.target?.result) {
        const bstr = evt.target.result;
        const XLSX = require("xlsx");
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws);
        items = processData(data);
        getRows(items);
      }
    };
    reader.readAsText(file, "UTF-8");
    reader.readAsBinaryString(file);
  } catch (e) {
    console.error(e);
    getRows([]);
  }
};

export const processData = (dataString: string) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(
    /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
  );

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length == headers.length) {
      const obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] == '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) {
          obj[headers[j].trim()] = d;
        }
      }
      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }

  return list;
};

export const handleFileUploadV2 = (
  file: File,
  getRows: (rows: any[]) => void,
) => {
  try {
    const reader = new FileReader();
    reader.onload = (evt: ProgressEvent<FileReader>) => {
      if (evt?.target?.result) {
        const decoder = new TextDecoder("iso-8859-1");
        const csvData = decoder.decode(evt.target.result as ArrayBuffer);
        const items = processDataFileV2(csvData);
        getRows(items);
      }
    };
    reader.readAsArrayBuffer(file);
  } catch (e) {
    console.error(e);
    getRows([]);
  }
};

const processDataFileV2 = (dataString: string) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(";").map((header) => header.trim());

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(";");

    if (headers && row.length === headers.length) {
      const obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d) {
          d = d.trim();
          if (d.startsWith('"') && d.endsWith('"')) {
            d = d.slice(1, -1);
          }
        } else {
          d = "";
        }
        obj[headers[j]] = d;
      }
      if (Object.values(obj).some((x) => x !== "")) {
        list.push(obj);
      }
    }
  }
  return list;
};

export const transformWhereQueryData = ({
  where,
  user,
}: {
  where: Condition[];
  user: UserData;
}) => {
  return where.map((field) => {
    if (field.GROUP.length > 0) {
      const GROUP = field.GROUP.map((group) => {
        if (
          group.VAL &&
          typeof group.VAL === "string" &&
          (group.VAL as string).includes("currentUser")
        ) {
          group = {
            ...group,
            VAL: getInterpretVar({ strVar: group.VAL, item: user }),
          };
        }

        return group;
      });

      field = { ...field, GROUP };
    }

    return field;
  });
};

export function setQueryParams(
  url: string,
  query: Record<string, string> | ParsedUrlQuery,
) {
  const [urlPath = "", queryStr = ""] = url.split("?") as [string, string];
  const searchParams = new URLSearchParams(queryStr);
  Object.entries(query).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value as string);
    } else {
      searchParams.delete(key);
    }
  });

  return `${urlPath}${
    searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""
  }`;
}

export function containsHTML(strings: string[]): boolean {
  // Expresión regular para detectar etiquetas HTML básicas
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;

  // Iteramos sobre cada string en el array
  for (const str of strings) {
    if (htmlRegex.test(str)) {
      return true;
    }
  }

  // Si ninguna string contiene código HTML, devolvemos false
  return false;
}

export function transformHtmlTextTagsToSpan(htmlString: string): string {
  // Expresión regular para encontrar todas las etiquetas HTML
  const htmlTagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

  // Función de reemplazo para transformar las etiquetas encontradas en <span>
  return htmlString.replace(htmlTagRegex, (match, p1) => {
    return match.startsWith("</") ? `</span>` : `<span>`;
  });
}

export function transformHtmlArrayToSpan(strings: string[]): string[] {
  return strings.map((str) => transformHtmlTextTagsToSpan(str));
}

export const generateAndDownloadExcel = (
  jsonArray: any[],
  columns: { label: string; value: string; isTotalColumn?: boolean }[],
  name = "data",
) => {
  // Create a new array of objects with only the specified columns
  const modifiedArray = jsonArray.map((item) => {
    const newItem: any = {};
    columns.forEach((col) => {
      newItem[col.label] = item[col.value];
    });
    return newItem;
  });

  // Calculate totals if necessary
  const totals: any = {};
  columns.forEach((col) => {
    if (col.isTotalColumn) {
      totals[col.label] = modifiedArray.reduce(
        (sum, item) => sum + (parseFloat(item[col.label]) || 0),
        0,
      );
    }
  });

  // Convert the modified array to a worksheet with headers in the correct order
  const worksheetData = modifiedArray.map((item) =>
    columns.map((col) => item[col.label]),
  );

  // Add headers to worksheetData
  const headers = columns.map((col) => col.label);
  worksheetData.unshift(headers);

  // If there are totals to add, create a totals row and add an initial empty column
  if (Object.keys(totals).length > 0) {
    const totalsRow = columns.map((col) =>
      col.isTotalColumn ? totals[col.label] : "",
    );
    totalsRow.unshift("Total"); // Insert "Total" label at the beginning of the row

    // Add an empty cell at the beginning of each data row
    worksheetData.forEach((row, index) => {
      if (index === 0) {
        row.unshift(""); // Empty header for the first column
      } else {
        row.unshift(""); // Empty cells for the rest of the rows
      }
    });

    worksheetData.push(totalsRow); // Add the totals row at the end
  }

  // Create worksheet from worksheetData
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file and trigger download
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

// export const generateAndDownloadExcel = (
//   jsonArray: any[],
//   columns: { label: string; value: string }[],
//   name = "data",
// ) => {
//   // Create a new array of objects with only the specified columns
//   const modifiedArray = jsonArray.map((item) => {
//     const newItem: any = {};
//     columns.forEach((col) => {
//       newItem[col.label] = item[col.value];
//     });
//     return newItem;
//   });
//
//   // Convert the modified array to a worksheet with headers in the correct order
//   const worksheetData = modifiedArray.map((item) =>
//     columns.map((col) => item[col.label]),
//   );
//
//   // Add headers to worksheetData
//   const headers = columns.map((col) => col.label);
//   worksheetData.unshift(headers);
//
//   // Create worksheet from worksheetData
//   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
//
//   // Create a new workbook and append the worksheet
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//
//   // Generate the Excel file and trigger download
//   XLSX.writeFile(workbook, `${name}.xlsx`);
// };

interface DataObject {
  [key: string]: any;
}

export const generateAndDownloadExcelWithSubheaders = (
  data: any[],
  mainColumns: { label: string; value: string }[],
  fileName = "data",
) => {
  const wb = XLSX.utils.book_new();
  const wsName = "Sheet1";
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  const headers: string[] = [];
  const subHeaders: string[][] = [];
  for (const col of mainColumns) {
    headers.push(col.label);
    if (
      data.length > 0 &&
      data[0][col.value] &&
      typeof data[0][col.value] === "object"
    ) {
      const subKeys = Object.keys(data[0][col.value]);
      subHeaders.push(subKeys.map((subKey) => `${col.label}_${subKey}`));
    } else {
      subHeaders.push([]);
    }
  }

  // Adding headers and subheaders to worksheet
  const headerRange = XLSX.utils.decode_range(ws["!ref"] as string);
  let colIndex = 0;
  for (let i = 0; i < headers.length; i++) {
    ws[XLSX.utils.encode_cell({ r: headerRange.s.r, c: colIndex })] = {
      v: headers[i],
    };
    colIndex++;

    for (let j = 0; j < subHeaders[i].length; j++) {
      ws[XLSX.utils.encode_cell({ r: headerRange.s.r + 1, c: colIndex })] = {
        v: subHeaders[i][j],
      };
      colIndex++;
    }
  }

  // Adding data rows
  for (let i = 0; i < data.length; i++) {
    const row: DataObject = {};
    colIndex = 0;
    for (let j = 0; j < mainColumns.length; j++) {
      const mainCol = mainColumns[j];
      if (
        data[i][mainCol.value] &&
        typeof data[i][mainCol.value] === "object"
      ) {
        const subKeys = Object.keys(data[i][mainCol.value]);
        for (const subKey of subKeys) {
          row[`${mainCol.label} ${subKey}`] = data[i][mainCol.value][subKey];
        }
        colIndex += subKeys.length;
      } else {
        row[mainCol.label] = data[i][mainCol.value];
        colIndex++;
      }
    }
    // Insert data row without headers
    XLSX.utils.sheet_add_json(ws, [row], { skipHeader: i > 0, origin: -1 });
  }

  XLSX.utils.book_append_sheet(wb, ws, wsName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export function existsKey(obj: any, key: string): boolean {
  const keys = key.split(".");

  let currentObj = obj;
  for (let k of keys) {
    if (currentObj && k in currentObj) {
      currentObj = currentObj[k];
    } else {
      return false;
    }
  }

  return true;
}

export async function detectFileType(
  link: string,
): Promise<"video" | "image" | "unknown"> {
  // Extensiones de video e imagen comunes
  const videoExtensions: string[] = [
    "mp4",
    "webm",
    "ogg",
    "mov",
    "avi",
    "wmv",
    "flv",
    "mkv",
    "3gp",
    "m4v",
  ];
  const imageExtensions: string[] = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "webp",
    "svg",
    "tiff",
  ];

  // Obtener la extensión del archivo del link
  const extension = link.split(".").pop()?.toLowerCase() || "";

  // Verificar si la extensión corresponde a un video o imagen
  if (videoExtensions.includes(extension)) {
    return "video";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else {
    try {
      // Intentar descargar el archivo para verificar su tipo real (MIME type)
      const response = await fetch(link);
      if (!response.ok) throw new Error("Error fetching the file");

      const contentType = response.headers.get("content-type");

      // Verificar si el tipo de contenido es de video o imagen
      if (contentType?.includes("video")) {
        return "video";
      } else if (contentType?.includes("image")) {
        return "image";
      } else {
        return "unknown";
      }
    } catch (error) {
      console.error("Error fetching the file:", error);
      return "unknown";
    }
  }
}

/**
 * Valida un valor numérico contra una regla específica.
 * @param value - Valor a validar.
 * @param rule - Regla de validación.
 * @returns {string | null} - Mensaje de error si la validación falla, o `null` si es válida.
 */
export function validateNumericField({rule, value, t}: {value: number, rule: TableFieldValidationRule, t: (data: string) => string}): string | null {

  const { comparison_operator, target_value, error_message } = rule;

  switch (comparison_operator) {
    case "greater_than":
      return value > target_value
          ? null
          : error_message || t(`custom-message:value_must_be_greater_than`).replace("#N", target_value.toString());

    case "greater_than_or_equal":
      return value >= target_value
          ? null
          : error_message || t(`custom-message:value_must_be_greater_than_or_equal_to`).replace("#N", target_value.toString());

    case "less_than":
      return value < target_value
          ? null
          : error_message || t(`custom-message:value_must_be_less_than`).replace("#N", target_value.toString());

    case "less_than_or_equal":
      return value <= target_value
          ? null
          : error_message || t(`custom-message:value_must_be_less_than_or_equal_to`).replace("#N", target_value.toString());

    case "equal_to":
      return value === target_value
          ? null

          : error_message || t(`custom-message:value_must_be_equal_to`).replace("#N", target_value.toString());

    case "not_equal_to":
      return value !== target_value
          ? null
          // : error_message || `Value must not be equal to ${target_value}`;
          : error_message || t(`custom-message:value_must_not_be_equal_to`).replace("#N", target_value.toString());

    case "between":
      if (Array.isArray(target_value) && target_value.length === 2) {
        const [min, max] = target_value;
        return value >= min && value <= max
            ? null
            // : error_message || `Value must be between ${min} and ${max}`;
            : error_message || t(`custom-message:value_must_be_between`).replace("#N1", min.toString()).replace("#N2", max.toString());
      }
      return t("invalid_target_value_for_between")

    case "not_between":
      if (Array.isArray(target_value) && target_value.length === 2) {
        const [min, max] = target_value;
        return value < min || value > max
            ? null
            : error_message || `Value must not be between ${min} and ${max}`;
      }
      return `Invalid target_value for "not_between". Expected an array [min, max].`;

    default:
      return `Unknown comparison operator: ${comparison_operator}`;
  }
}

export const extractTextFromReactNode = (node: React.ReactNode): string => {
  if (typeof node === 'string' || typeof node === 'number') {
    return node.toString();
  }

  if (Array.isArray(node)) {
    return node.map(child => extractTextFromReactNode(child)).join('');
  }

  if (React.isValidElement(node)) {
    return extractTextFromReactNode(node.props.children);
  }

  return '';
};

// Function to detect if an object is a React Element
export const isReactElement = (obj: any): obj is React.ReactElement =>
    React.isValidElement(obj);

// Function to detect if an object is a "React component type" (function or class)
// Note: This is a heuristic and may vary based on your case.
export const isReactComponentType = (obj: any): boolean => {
  // If it's a function, it could be a function component or a class component (in JS/TS, classes are functions at runtime)
  if (typeof obj === 'function') return true;

  // Also check if it's an object with a 'render' property (common for class components)
  if (typeof obj === 'object' && obj !== null && 'render' in obj) return true;

  return false;
};

export const isValidStrNumber = (str: string): boolean => {
  return !isNaN(Number(str));
};

export function safeStringify(obj: any): string {
  const seen = new WeakSet();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) return "[Circular]";
      seen.add(value);
    }
    return value;
  });
}