import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {Field} from "../../../../types/Field";
import CreateFieldPreview from "../CreateFieldModal/CreateFieldPreview";
import ModalComponent from "../ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import useTranslate from "../../../../hooks/useTranslate";
import {Button, CardBody} from "reactstrap";
import {EventProcess, ProcessModel} from "../../../../types/models/processModel/Process";
import {createOrUpdateProcessModelService} from "../../../../services/backend/ProcessService";
import {fields} from "./config";
import {RootState} from "../../../../store/Reducers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";

export interface IProps {
  open: boolean;
  data: IPropsUpsertEventModal;

}

export interface IPropsUpsertEventModal {
  type: ModalTypes.UPSERT_EVENT_MODAL;
  event?: EventProcess,
  currentProcessModel: ProcessModel;
  onFinish: (process: ProcessModel) => void;
  dataStore: any[];
}


const UpsertTaskModal = (props: IProps) => {

  const [fieldsInput, setFieldsInput] = useState<Field[]>([]);
  const [processModel] = useState(props.data.currentProcessModel);
  const {forms} = useSelector((state: RootState) => state.FormReducer);
  const [event, setEvent] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {t} = useTranslate("common");

  const options: any = {
    attachments: forms.map(form => ({label: `${form.id} ${form.name}`, value: form})),
    data_store_id: props.data.dataStore.map(st => ({
      label: st.name.split("_").map((str: string) => t(str)).join(" "),
      value: st.id,
      data: st
    })),
    event_type: [
      {value: 'END', label: 'END'},
      {value: 'START', label: 'START'},
      {label: 'INTERMEDIATE_WAIT', value: 'INTERMEDIATE_WAIT'},
      {value: 'INTERMEDIATE_DATA', label: 'INTERMEDIATE_DATA'}
    ]
  }


  const setData = useCallback((evt: any) => {
    setEvent((e: any) => ({...e, id: evt.id}));
    setFieldsInput(fields.map((field, i) => {
      const tt = evt as any;
      const val = tt[field.name] ?? field.default_value;
      const default_value = options[field.name]?.find((opt: any) => {
        if (field.name === "attachments") {
          return opt.value.id === (val && val.length ? val[0].attachment_id : null)
        }
        return opt.value === val
      }) ?? val
      setEvent((e: any) => ({...e, [field.name]: default_value}));

      return {
        ...field,
        id: i
      }
    }));
  }, [processModel])

  useEffect(() => {
    setData(props.data.event ?? {})
  }, [props.data.event]);

  function getEvent(evt: any) {
    let newEvent: any = {...evt};
    fields.forEach(field => {
      const value = event[field.name];
      const tt = typeof value;
      if (field.name === "attachments") {
        if (value?.value) {
          const attach = {
            attachment_id: value.value.id,
            attachment_type: 'FORM',
            domain_id: processModel.domain_id,
            target_type: "EVENT",
          };
          newEvent[field.name] = [attach];
        }
      } else {
        newEvent[field.name] = (tt === "object" && value ? value.value : value) ?? "";
      }
    });

    return newEvent;
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let newProcessModel = {...processModel};
    setLoading(true);
    if (event.id) {
      newProcessModel.events = newProcessModel.events.map(evt => {
        if (evt.id === event.id) {
          return getEvent(evt);
        }
        return evt;
      });
    } else {
      let eventT = new Array(...newProcessModel.events ?? []);
      const nv = getEvent({})
      eventT.push(nv);
      newProcessModel.events = eventT;
    }
    const res = await createOrUpdateProcessModelService(newProcessModel);
    if (res?.success) {
      props.data.onFinish(res.item);
      toggle();
    }
    setLoading(false);
  }

  const toggle = () => {
    dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_EVENT_MODAL}))
  }

  return (
    <ModalComponent
      isLoading={loading}
      size="md"
      title={
        (event.id ? t("update") : t("new")) +
        " " +
        t("event") +
        " " +
        (event.id ? `- ID (${event.id})` : "")
      }
      isOpen={props.open}
      noFooter
      toggle={toggle}>
      <form className="card" id="formUpsertModal" onSubmit={onSubmit}>
        <CardBody>
          {fieldsInput.map(field => {
            return (
              <CreateFieldPreview
                key={field.name}
                options={options[field.name]}
                onChange={e => setEvent({...event, [field.name]: e})}
                value={event[field.name]}
                field={field}/>
            )
          })}
          <div className="d-flex justify-content-end">
            {!!event.id &&
              <Button type="button" onClick={() => setData({})} disabled={loading} color="light" size="sm"
                      className="me-1">
                <FontAwesomeIcon icon={faTimes} className="me-1"/>
                {t("clear")}
              </Button>}
            <Button disabled={loading} color="primary" size="sm">
              <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : faSave} className="me-1"/>
              {t("save")}
            </Button>
          </div>
        </CardBody>
      </form>
    </ModalComponent>
  )
}

export default UpsertTaskModal;
